import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export function FocusHandler() {
  const location = useLocation();
  const [isInitialAppRender, setIsInitialAppRender] = useState(true);

  // after a navigation,
  // but not during the initial app render,
  // focus the first h1 so that screen reader users get some feedback
  useEffect(() => {
    if (!isInitialAppRender) {
      document.querySelector<HTMLHeadingElement>('h1[tabindex="-1"]')?.focus();
    }

    setIsInitialAppRender(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return <></>;
}
