import { Trans } from "@lingui/macro";

import { SurveyType } from "../graphql/__generated__/globalTypes";
import Button from "./Button";
import Icon from "./Icon";
import CaretRight from "./icons/20/caret-right.svg?react";

export type Props = {
  surveyType: SurveyType;
};

export default function SurveyPrompt(props: Props) {
  const { surveyType } = props;
  let to;

  if (surveyType === SurveyType.Monitoring) {
    to = "/monitoring/new";
  } else {
    to = "/post-operative/new";
  }

  return (
    <div className="card survey-prompt" data-theme="highlight">
      <h2>
        <Trans>Enter your symptoms for today</Trans>
      </h2>
      <Button to={to}>
        <Trans>Go to survey</Trans>
        <Icon svg={CaretRight} size="medium" />
      </Button>
    </div>
  );
}
