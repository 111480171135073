import type { ApolloError } from "@apollo/client";
import { Trans } from "@lingui/macro";
import React, { useState } from "react";

import Button from "../../../shared/Button";
import ButtonWithLoadingIndicator from "../../../shared/ButtonWithLoadingIndicator";
import FormError from "../../../shared/FormError";
import { defaultRedirectPath } from "../../../utils/useRedirectPathSearchParam";

export type OnSubmitData = { email: string };

export type Props = {
  loading: boolean;
  error?: ApolloError;
  expectedErrorCodes?: string[];
  onSubmit: (data: OnSubmitData) => void;
};

export default function PasswordResetNewForm(props: Props) {
  const { loading, expectedErrorCodes, error } = props;
  const [email, setEmail] = useState("");

  const onEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    props.onSubmit({ email });
  };

  return (
    <form className="auth-page__form page-with-actions" onSubmit={onSubmit}>
      <div className="form-item">
        <FormError error={error} expectedErrorCodes={expectedErrorCodes} />
        <div className="form-item form-item--inputs">
          <label htmlFor="email">
            <Trans>Email</Trans>
          </label>
          <input
            id="email"
            type="email"
            required
            value={email}
            onChange={onEmailChange}
            autoComplete="email"
          />
        </div>
      </div>

      <div className="page-with-actions__actions">
        <Button to={defaultRedirectPath} color="transparent">
          <Trans>Cancel</Trans>
        </Button>
        <ButtonWithLoadingIndicator
          type="submit"
          loading={loading}
          data-testid="request-password-reset-button"
        >
          <Trans>Request link</Trans>
        </ButtonWithLoadingIndicator>
      </div>
    </form>
  );
}
