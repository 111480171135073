import type { ApolloError } from "@apollo/client";
import React from "react";

import { findExpectedError } from "../graphql/expectedErrors";
import { hasValidationErrors } from "../graphql/validationErrors";
import ErrorMessage from "./ErrorMessage";

type Props = {
  error?: ApolloError;
  expectedErrorCodes?: string[];
  validationErrorIsUnexpected?: boolean;
};

export default function FormError(props: Props) {
  const { error, expectedErrorCodes, validationErrorIsUnexpected } = props;

  const validationErrors = error && hasValidationErrors(error);

  // validation errors will be shown below each respective input
  if (validationErrors && !validationErrorIsUnexpected) {
    return null;
  }

  const expectedError =
    expectedErrorCodes &&
    findExpectedError(error?.graphQLErrors, expectedErrorCodes);

  if (expectedError) {
    return (
      <ErrorMessage shouldAutofocus={true} message={expectedError.message} />
    );
  }
  if (error) {
    return <ErrorMessage shouldAutofocus={true} error={error} />;
  }

  return null;
}
