import { gql } from "@apollo/client";

const query = gql`
  query surveySubmissions($surveyType: SurveyType!) {
    surveySubmissions(surveyType: $surveyType) {
      id
      surveyType
      insertedAt
      alarming
    }
  }
`;
export default query;
