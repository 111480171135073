export interface IdaCustomWindow extends Window {
  _appsignal_key: string;
  _app_revision: string;
  _api_url: string;
}

declare let window: IdaCustomWindow;

const APP_CONFIG = {
  VITE_APPSIGNAL_KEY: window._appsignal_key,
  VITE_APP_REVISION: window._app_revision,
  VITE_API_URL: window._api_url,
};

export default APP_CONFIG;
