import { useLingui } from "@lingui/react";
import { formatInTimeZone } from "date-fns-tz";
import type { ReactNode } from "react";

import classNames from "../utils/classNames";
import {
  defaultTimezone,
  formatDateInDefaultTimezone,
  isDateThisYear,
  parseDateAndTimeInDefaultTimezone,
} from "../utils/date-time";
import type { TitleTag } from "../utils/propTypes";

type Item = { date: string; time?: string; content: ReactNode };

export type Props = React.HTMLAttributes<HTMLUListElement> & {
  items: Array<Item>;
  titleTag?: TitleTag;
  monthListProps?: React.HTMLAttributes<HTMLUListElement>;
};

type Group<T> = [string, Array<T>];
type ItemWithDateObject = Item & { dateObject: Date };

function groupItemsByMonth(
  items: Array<Item>
): Array<Group<ItemWithDateObject>> {
  const itemsWithLabels = items.map((item) => {
    const dateObject = parseDateAndTimeInDefaultTimezone(
      item.date,
      item.time || "00:00"
    );
    const yearAndMonthLabel = formatInTimeZone(
      dateObject,
      defaultTimezone,
      "yyyy-MM"
    );
    return { ...item, dateObject: dateObject, label: yearAndMonthLabel };
  });

  const sortedItems = itemsWithLabels.sort((a, b) =>
    // iso8601 date strings can be compared for chronological sorting
    a.dateObject.toISOString().localeCompare(b.dateObject.toISOString())
  );

  return sortedItems.reduce<Array<Group<ItemWithDateObject>>>((acc, item) => {
    const lastGroup = acc[acc.length - 1];
    if (lastGroup && lastGroup[0] === item.label) {
      return [...acc.slice(0, -1), [item.label, [...lastGroup[1], item]]];
    } else {
      return [...acc, [item.label, [item]]];
    }
  }, []);
}

function MonthSeparator(props: {
  locale: string;
  titleTag: TitleTag;
  label: string;
  dateObject: Date;
}) {
  const { locale, label, dateObject } = props;
  const TitleTag = props.titleTag;
  const dateFormat = isDateThisYear(dateObject) ? "MMMM" : "MMMM, yyyy";

  return (
    <TitleTag
      id={`month-separator-${label}`}
      className="group-by-month__month-separator a-content a-content--m a-content--tight"
    >
      {formatDateInDefaultTimezone(locale, dateObject, dateFormat)}
    </TitleTag>
  );
}

export default function GroupByMonth(props: Props) {
  const { i18n } = useLingui();

  const { items, titleTag, className, monthListProps, ...rest } = props;
  const groupedItems = groupItemsByMonth(items);

  if (groupedItems.length === 0) {
    return <></>;
  }

  return (
    <ul className={classNames(["group-by-month", className])} {...rest}>
      {groupedItems.map((group) => (
        <li key={group[0]} className="a-content a-content--m a-content--tight">
          <MonthSeparator
            titleTag={titleTag || "h2"}
            locale={i18n.locale}
            label={group[0]}
            dateObject={group[1][0].dateObject}
          />
          <ul
            {...monthListProps}
            aria-describedby={`month-separator-${group[0]}`}
          >
            {group[1].map((item, index) => (
              <li key={`${item.date}-${index}`}>{item.content}</li>
            ))}
          </ul>
        </li>
      ))}
    </ul>
  );
}
