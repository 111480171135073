import { msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import type { Phase } from "../../graphql/__generated__/globalTypes";
import ErrorMessage from "../../shared/ErrorMessage";
import GroupByMonth from "../../shared/GroupByMonth";
import Layout from "../../shared/Layout";
import LoadingIndicator from "../../shared/LoadingIndicator";
import PhaseDetails from "../../shared/PhaseDetails";
import Step from "../../shared/Step";
import useCarePath from "../../utils/useCarePath";

export default function PhaseDetailsPage() {
  const { _ } = useLingui();
  const { carepath, loading, error } = useCarePath();
  const { phaseId } = useParams();
  const phases: Phase[] = (carepath?.phases as Phase[]) || [];
  const finder = (p: Phase) => p.id === phaseId;
  const phase = phases.find(finder);
  const index = phases.findIndex(finder);
  const navigate = useNavigate();
  const backPath = "/care-path";

  useEffect(() => {
    if (!loading && !error && !phase) {
      navigate(backPath);
    }
  });

  return (
    <Layout
      title={_(msg`Phase details`)}
      backPath={backPath}
      backLabel={_(msg`Back to care path`)}
    >
      <div className="phase-details-page">
        {(loading || error) && (
          <div className="a-content a-content--m a-content--tight">
            {loading && (
              <span className="loading-indicator-wrapper">
                <LoadingIndicator />
              </span>
            )}
            {error && <ErrorMessage error={error} />}
          </div>
        )}
        {!loading && !error && phase && (
          <>
            <div className="phase-details-page__details" data-theme="default">
              <div className="a-content a-content--m a-content--tight">
                <PhaseDetails phase={phase} index={index} />
              </div>
            </div>
            <GroupByMonth
              className="phase-details-page__steps"
              monthListProps={{
                className: "phase-details-page__step-list",
              }}
              items={phase.steps.map((step) => ({
                date: step!.startDate,
                content: <Step step={step!} phase={phase} key={step!.id} />,
              }))}
            />
          </>
        )}
      </div>
    </Layout>
  );
}
