import { Trans } from "@lingui/macro";

import { type Phase, type Step } from "../graphql/__generated__/globalTypes";
import Button from "./Button";
import Icon from "./Icon";
import EyeSlash from "./icons/16/eye-slash.svg?react";
import NotePencil from "./icons/20/note-pencil.svg?react";

export type Props = {
  step: Step;
  phase: Phase;
};

export default function StepPrivateNotes(props: Props) {
  const { step, phase } = props;
  return (
    <div className="card step-private-notes">
      <div className="step-private-notes__text">
        <div className="step-private-notes__header">
          <h3 className="step-private-notes__header__heading u-h3">
            <Icon svg={EyeSlash} size="small" /> <Trans>Private notes</Trans>
          </h3>
          <p className="step-private-notes__header__paragraph u-h3">
            <Trans>These are your private notes. Only you can see them.</Trans>
          </p>
        </div>
        {step.privateNotes ? (
          <div className="user-content">{step.privateNotes}</div>
        ) : (
          <div className="step-private-notes__no-notes">
            <Trans>No notes yet.</Trans>
          </div>
        )}
      </div>
      <div className="step-private-notes__link">
        <Button
          to={`/care-path/${phase.id}/steps/${step.id}/private-notes`}
          color="secondary"
        >
          <Icon svg={NotePencil} size="medium" />
          <Trans>Edit notes</Trans>
        </Button>
      </div>
    </div>
  );
}
