import { Trans } from "@lingui/macro";
import { Helmet } from "react-helmet-async";

import useSetDocumentLocale from "../utils/useSetDocumentLocale";
import NavBar from "./Layout/NavBar";
import Notifications from "./Layout/Notifications";
import Sidebar from "./Layout/Sidebar";
import TopBar from "./Layout/TopBar";

export type Props = React.PropsWithChildren<{
  title: string;
  hideTitle?: boolean;
  hideNav?: boolean;
  backPath?: string;
  backLabel?: string;
}>;

function Layout(props: Props) {
  const { title, children, backPath, backLabel } = props;
  const hideTitle = props.hideTitle || false;
  const hideNav = props.hideNav || false;
  useSetDocumentLocale();
  return (
    <>
      <Helmet>
        <title>{title} | IDA</title>
      </Helmet>
      <a className="a-skip-link" href="#main-content">
        <Trans>Skip to content</Trans>
      </a>
      <div className="layout">
        {!hideNav && (
          <div className="layout__nav-bar">
            <NavBar />
          </div>
        )}
        {!hideNav && (
          <div className="layout__sidebar u-hidden u-flex@l">
            <Sidebar />
          </div>
        )}
        <div className="layout__top-bar">
          <TopBar
            backPath={backPath}
            backLabel={backLabel}
            title={hideTitle ? undefined : title}
            logoOnlyOnDesktop={hideNav}
          />
        </div>
        <main className="layout__main" id="main-content">
          <div className="layout__notifications">
            {!hideNav && <Notifications />}
          </div>
          <div className="layout__page-content">{children}</div>
        </main>
      </div>
    </>
  );
}

export default Layout;
