import { Trans } from "@lingui/macro";

import type { Phase } from "../graphql/__generated__/globalTypes";
import PhaseComponent from "./Phase";

export type Props = {
  phases: Phase[];
};

function CarePath({ phases }: Props) {
  return (
    <div>
      {phases && phases.length > 0 ? (
        <ul className="u-list-none care-path__content care-path__content--list">
          {phases.map((phase, index) => (
            <li key={phase.id}>
              <PhaseComponent phase={phase} index={index} />
            </li>
          ))}
        </ul>
      ) : (
        <div className="card care-path__content care-path__content--empty">
          <h2 className="u-h1">
            <Trans>A carepath does not exist yet</Trans>
          </h2>
          <p className="u-h3">
            <Trans>
              Your care team are responsible for creating a carepath for you.
            </Trans>
          </p>
        </div>
      )}
    </div>
  );
}

export default CarePath;
