import { msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { useParams } from "react-router-dom";

import {
  SurveyType,
  useSurveyQuestionsQuery,
  useSurveySubmissionQuery,
} from "../../graphql/__generated__/globalTypes";
import ErrorMessage from "../../shared/ErrorMessage";
import Layout from "../../shared/Layout";
import LoadingIndicator from "../../shared/LoadingIndicator";
import {
  formatDateInDefaultTimezone,
  parseDatetimeInDefaultTimezone,
} from "../../utils/date-time";
import SurveySubmissionView from "./SurveySubmissionPage/SurveySubmissionView";

export type Props = {
  surveyType: SurveyType;
};

export default function SurveySubmissionPage(props: Props) {
  const { i18n, _ } = useLingui();
  const { surveyType } = props;
  const { submissionId } = useParams();
  const {
    data: questionsData,
    error: questionsError,
    loading: questionsLoading,
  } = useSurveyQuestionsQuery({
    variables: { surveyType },
  });

  const { data, error, loading } = useSurveySubmissionQuery({
    variables: { submissionId: submissionId || "" },
  });

  let title = "";
  if (data?.surveySubmission.insertedAt) {
    const dateObject = parseDatetimeInDefaultTimezone(
      data?.surveySubmission.insertedAt || ""
    );
    const dateString = formatDateInDefaultTimezone(i18n.locale, dateObject);
    title =
      surveyType === SurveyType.Monitoring
        ? _(msg`Symptoms • ${dateString}`)
        : _(msg`Symptoms OP • ${dateString}`);
  } else {
    title =
      surveyType === SurveyType.Monitoring
        ? _(msg`Symptoms`)
        : _(msg`Symptoms OP`);
  }

  return (
    <Layout title={title}>
      <div className="new-survey-submission-page a-content a-content--m a-content--tight">
        {(loading || questionsLoading) && (
          <span className="loading-indicator-wrapper">
            <LoadingIndicator />
          </span>
        )}
        {(error || questionsError) && (
          <ErrorMessage error={error || questionsError} />
        )}
        {!loading && !error && !questionsLoading && !questionsError && (
          <SurveySubmissionView
            surveyQuestions={questionsData?.surveyQuestions || []}
            givenAnswers={data?.surveySubmission.answers || []}
          />
        )}
      </div>
    </Layout>
  );
}
