import { msg, Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Link } from "react-router-dom";

import {
  type Submission,
  SurveyType,
} from "../../graphql/__generated__/globalTypes";
import {
  formatDateInDefaultTimezone,
  formatTimeInDefaultTimezone,
} from "../../utils/date-time";
import Button from "../Button";
import Icon from "../Icon";
import Clock from "../icons/16/clock.svg?react";
import Bell from "../icons/20/bell.svg?react";
import CaretRight from "../icons/20/caret-right.svg?react";
import Close from "../icons/20/close.svg?react";

export type Props = {
  submission: Submission;
  onDismiss: (submission: Submission) => void;
};

export const stepAppointmentStateElementId = "attendance";

export function notificationId(submission: Submission) {
  // cut off milliseconds
  const timestamp = submission.insertedAt?.split(".")[0];
  return `submission-notification-${submission.surveyType}-${submission.id}-${timestamp}`;
}

export default function Banner(props: Props) {
  const { _, i18n } = useLingui();
  const { submission, onDismiss } = props;

  const submissionDate = submission.insertedAt
    ? new Date(submission.insertedAt)
    : null;

  let formattedDate = submissionDate
    ? formatDateInDefaultTimezone(i18n.locale, submissionDate)
    : "";
  if (formattedDate === formatDateInDefaultTimezone(i18n.locale, new Date())) {
    formattedDate = _(msg`Today`);
  }

  const heading = msg`According to your last symptom survey submission, you are suffering symptoms.`;
  const icon = Bell;
  let to;

  if (submission.surveyType === SurveyType.Monitoring) {
    to = `/monitoring/${submission.id}`;
  } else if (submission.surveyType === SurveyType.PostOperative) {
    to = `/post-operative/${submission.id}`;
  }
  return (
    <article
      aria-describedby={`heading-${notificationId(submission)}`}
      className="banner"
    >
      <div className="banner__highlight">
        <Icon svg={icon!} />
      </div>
      <div className="banner__text">
        <h2
          id={`heading-${notificationId(submission)}`}
          className="banner__highlight u-h2"
        >
          {_(heading!)}
        </h2>
        <Link to={to!} className="banner__link">
          <span className="banner__link-text">
            <Trans>What can you do? Click here.</Trans>
          </span>{" "}
          <Icon svg={CaretRight} size={"medium"} />
        </Link>
        <dl className="inline-dl inline-dl--with-icons u-fg-grayscale-base">
          {submissionDate && (
            <>
              <div className="inline-dl__item inline-dl__item--light-weight">
                <dt className="u-sr-only">
                  <Trans>Start date</Trans>
                </dt>
                <dd>{formattedDate}</dd>
              </div>
              <div className="inline-dl__item inline-dl__item--light-weight icon-label">
                <dt>
                  <Icon svg={Clock} size={"small"} />
                  <span className="u-sr-only">
                    <Trans>Start time</Trans>
                  </span>
                </dt>
                <dd>{formatTimeInDefaultTimezone(submissionDate)}</dd>
              </div>
            </>
          )}
        </dl>
      </div>
      <div className="banner__close">
        <Button onClick={() => onDismiss(submission)} color="transparent">
          <Icon svg={Close} size={"medium"}></Icon>
          <span className="u-sr-only">
            <Trans>Dismiss</Trans>
          </span>
        </Button>
      </div>
    </article>
  );
}
