import { Trans } from "@lingui/macro";
import { Link } from "react-router-dom";

import IdaChariteLogo from "../../assets/ida-charite-logo.svg?react";
import classNames from "../../utils/classNames";
import { defaultRedirectPath } from "../../utils/useRedirectPathSearchParam";
import Button from "../Button";
import Icon from "../Icon";
import CaretLeft from "../icons/20/caret-left.svg?react";

export type Props = {
  title?: string;
  backPath?: string;
  backLabel?: string;
  logoOnlyOnDesktop?: boolean;
};

const TopBar = ({ title, backPath, backLabel, logoOnlyOnDesktop }: Props) => {
  return (
    <header
      role="banner"
      className={classNames([
        "top-bar a-content a-content--full",
        // normally, the logo is hidden on desktop because it's visible in the nav bar
        // but some pages don't show the nav bar, and then they show the logo in the top bar
        logoOnlyOnDesktop && "top-bar--logo-only@l",
      ])}
    >
      {(backPath || title) && (
        <div className="top-bar__title-and-back">
          {backPath && (
            <div className="top-bar__back">
              <Button
                to={backPath}
                color="transparent"
                shape="small"
                data-testid="back-button"
              >
                <Icon svg={CaretLeft} size="medium"></Icon>
                <span className="u-sr-only">{backLabel}</span>
                <span aria-hidden="true" className="u-hidden u-block@l u-body">
                  <Trans>Back</Trans>
                </span>
              </Button>
            </div>
          )}
          {title && (
            <h1 tabIndex={-1} className="top-bar__title">
              {title}
            </h1>
          )}
        </div>
      )}
      <Link to={defaultRedirectPath} className="top-bar__logo">
        <IdaChariteLogo />
        <span className="u-sr-only">
          <Trans>Home</Trans>
        </span>
      </Link>
    </header>
  );
};

export default TopBar;
