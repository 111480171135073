import type { ApolloError } from "@apollo/client";
import { Trans } from "@lingui/macro";
import React, { useState } from "react";

import { getValidationErrorForField } from "../../../graphql/validationErrors";
import Button from "../../../shared/Button";
import ButtonWithLoadingIndicator from "../../../shared/ButtonWithLoadingIndicator";
import FormError from "../../../shared/FormError";
import Icon from "../../../shared/Icon";
import EyeSlash from "../../../shared/icons/16/eye-slash.svg?react";
import InputError from "../../../shared/InputError";

export type OnSubmitData = {
  privateNotes: string;
};

export type Props = {
  currentPrivateNotes?: string | null;
  loading: boolean;
  error?: ApolloError;
  expectedErrorCodes?: string[];
  onSubmit: (data: OnSubmitData) => void;
  cancelPath: string;
};

export default function StepNotesForm(props: Props): React.ReactElement {
  const {
    loading,
    currentPrivateNotes,
    expectedErrorCodes,
    error,
    cancelPath,
  } = props;
  const [privateNotes, setPrivateNotes] = useState(currentPrivateNotes || "");

  const privateNotesError = getValidationErrorForField(error, "private_notes");

  const onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setPrivateNotes(e.target.value);
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    props.onSubmit({ privateNotes });
  };

  return (
    <form action="#" className="page-with-actions" onSubmit={onSubmit}>
      <div className="form-item">
        <FormError error={error} expectedErrorCodes={expectedErrorCodes} />
        <div className="form-item">
          <div
            id="private-notes-heading"
            className="step-private-notes__header"
          >
            <h3 className="step-private-notes__header__heading u-h3">
              <Icon svg={EyeSlash} size="small" />
              <Trans>Private notes</Trans>
            </h3>
            <p className="step-private-notes__header__paragraph u-h3">
              <Trans>
                These are your private notes. Only you can see them.
              </Trans>
            </p>
          </div>
        </div>
        <div className="form-item form-item--inputs">
          <textarea
            id="notes"
            name="notes"
            rows={9}
            value={privateNotes}
            onChange={onChange}
            aria-labelledby="private-notes-heading"
            aria-invalid={!!privateNotesError}
            aria-errormessage={privateNotesError ? "notes-error" : undefined}
          ></textarea>

          {privateNotesError && (
            <InputError id="notes-error">{privateNotesError}</InputError>
          )}
        </div>
      </div>
      <div className="page-with-actions__actions page-with-actions__actions--inline@l">
        <Button to={cancelPath} color="secondary">
          <Trans>Cancel</Trans>
        </Button>
        <ButtonWithLoadingIndicator type="submit" loading={loading}>
          <Trans>Save</Trans>
        </ButtonWithLoadingIndicator>
      </div>
    </form>
  );
}
