import { i18n } from "@lingui/core";
import { I18nProvider } from "@lingui/react";
import React from "react";

export type Props = {
  children: React.ReactNode;
};

function Provider({ children }: Props): React.ReactElement {
  return <I18nProvider i18n={i18n}>{children}</I18nProvider>;
}

export default Provider;
