import { ApolloError } from "@apollo/client";
import type { GraphQLErrors } from "@apollo/client/errors";

import { ErrorCode } from "./__generated__/globalTypes";
import type { GraphQLIdaError } from "./IdaError";

export type GraphQLIdaValidationError = {
  code: ErrorCode.ValidationFailed;
  arg: string;
  validation: string;
} & GraphQLIdaError;

export const validationErrorCode = ErrorCode.ValidationFailed;

export function getValidationErrorForField(
  errors: GraphQLErrors | ApolloError | undefined,
  key: string
) {
  if (errors instanceof ApolloError && errors && errors.graphQLErrors) {
    return getValidationErrorForField(errors.graphQLErrors, key);
  }

  if (key.toLowerCase() !== key) {
    throw new Error(`Expected key to be snake_case, got ${key}`);
  }

  if (errors) {
    const graphqlErrors = errors as GraphQLErrors;
    const relevantErrors = graphqlErrors.filter(
      (error) =>
        "code" in error &&
        error.code === validationErrorCode &&
        "arg" in error &&
        error.arg === key
    );

    if (relevantErrors.length > 0) {
      return relevantErrors
        .map((error) => (error as GraphQLIdaValidationError).validation)
        .join(", ");
    }
  }

  return undefined;
}

export function hasValidationErrors(error: ApolloError): boolean {
  return (
    !!error &&
    !!error.graphQLErrors &&
    error.graphQLErrors.some(
      (error) => "code" in error && error.code === validationErrorCode
    )
  );
}
