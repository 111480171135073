import { Trans } from "@lingui/macro";

import type {
  Answer,
  PatientAnswer,
} from "../graphql/__generated__/globalTypes";
import { AnswerType } from "../graphql/__generated__/globalTypes";
import classNames from "../utils/classNames";
import Button from "./Button";
import Icon from "./Icon";
import CaretRight from "./icons/20/caret-right.svg?react";

type SurveyAnswerProps = {
  id: string;
  label: string;
  name: string;
  value: string;
  checked?: boolean;
  disabled?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

function SurveyAnswerLabels({
  id,
  name,
  label,
  value,
  onChange,
  disabled,
  checked,
}: SurveyAnswerProps) {
  return (
    <label htmlFor={id} className="survey-question__answer">
      <input
        type="radio"
        id={id}
        name={name}
        value={value}
        onChange={onChange}
        disabled={disabled}
        checked={checked}
      />
      {label}
    </label>
  );
}

function SurveyAnswerNumbers({
  id,
  name,
  label,
  value,
  onChange,
  disabled,
  checked,
}: SurveyAnswerProps) {
  return (
    <div className="survey-question__answer survey-question__answer--number">
      <label htmlFor={id} className="survey-question__number-wrapper">
        <input
          type="radio"
          id={id}
          name={name}
          value={value}
          className="survey-question__number-radio"
          onChange={onChange}
          disabled={disabled}
          checked={checked}
        />
        <span className="survey-question__number-label">{label}</span>
      </label>
    </div>
  );
}

export type Props = {
  prompt: string;
  externalId: string;
  answerType: AnswerType;
  answers: Answer[];
  answer?: PatientAnswer;
  infoUrl?: string;
  error?: boolean;
  alarming?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

function SurveyQuestion({
  prompt,
  externalId,
  answerType,
  answers,
  answer: givenAnswer,
  infoUrl,
  error,
  onChange,
}: Props) {
  const name = externalId;
  const AnswerComponent =
    answerType === AnswerType.Numbers
      ? SurveyAnswerNumbers
      : SurveyAnswerLabels;
  const disabled = !!givenAnswer && !!givenAnswer.value;
  return (
    <fieldset
      aria-required="true"
      aria-invalid={error}
      aria-describedby={error ? `${name}-error-message` : undefined}
      role="radiogroup"
      className={classNames([
        "survey-question",
        givenAnswer?.alarming && "survey-question--flagged",
      ])}
      data-theme="white"
      tabIndex={-1}
    >
      {error && (
        <p
          id={`${name}-error-message`}
          className="survey-question__error-message"
        >
          <Trans>This field is required</Trans>
        </p>
      )}
      <div>
        <legend className="u-h2 survey-question__prompt">{prompt}</legend>
        {givenAnswer?.alarming && (
          <p className="u-sr-only">
            <Trans>This question may require your attention</Trans>
          </p>
        )}
      </div>
      {answerType === AnswerType.Numbers && (
        <div className="survey-question__number-text-labels u-h3">
          <span>
            {answers[0].value} = {answers[0].label}
          </span>
          <span>
            {answers[answers.length - 1].value} ={" "}
            {answers[answers.length - 1].label}
          </span>
        </div>
      )}
      <div
        data-answers-length={answers.length}
        className={classNames([
          answerType === AnswerType.Numbers &&
            "survey-question__answer-list survey-question__answer-list--numbers",
          answerType === AnswerType.Labels &&
            "survey-question__answer-list survey-question__answer-list--wrap",
          // when editing max length here, also change it in CSS
          answers.length > 11 && " survey-question__answer-list--many-answers",
        ])}
      >
        {answers.map((answer) => {
          const id = `${externalId}-${answer.value}`;
          const answerValue = answer.value?.toString() || "";
          const answerLabel = answer.label || "";
          const checked = givenAnswer && answer.value === givenAnswer.value;
          return (
            <AnswerComponent
              id={id}
              key={id}
              name={name}
              label={
                answerType === AnswerType.Numbers ? answerValue : answerLabel
              }
              value={answerValue}
              onChange={onChange}
              disabled={disabled}
              checked={checked}
            />
          );
        })}
      </div>
      {infoUrl && disabled && (
        <div className="survey-question__info-link">
          <Button
            href={infoUrl}
            color="transparent"
            shape="small"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Trans>Support with symptoms</Trans>
            <Icon svg={CaretRight} size="medium" />
          </Button>
        </div>
      )}
    </fieldset>
  );
}

export default SurveyQuestion;
