import { msg, Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import {
  ErrorCode,
  useConfirmPatientMutation,
} from "../../graphql/__generated__/globalTypes";
import AuthSuccessMessage from "../../shared/AuthSuccessMessage";
import Layout from "../../shared/Layout";
import { defaultRedirectPath } from "../../utils/useRedirectPathSearchParam";
import type { OnSubmitData } from "./SetPasswordForm";
import SetPasswordForm from "./SetPasswordForm";

export default function ConfirmAccountPage() {
  const { _ } = useLingui();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const token = searchParams.get("token");

  useEffect(() => {
    if (!token) {
      navigate(defaultRedirectPath);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [successfullyConfirmed, setSuccessfullyConfirmed] =
    useState<boolean>(false);
  const [confirmPatient, { loading, error }] = useConfirmPatientMutation({
    onError: console.error,
  });

  const expectedErrorCodes: string[] = [
    ErrorCode.TokenInvalidOrExpired,
    ErrorCode.PatientAlreadyConfirmed,
  ];

  const onSubmit = async ({ password, passwordConfirmation }: OnSubmitData) => {
    const response = await confirmPatient({
      variables: { password, passwordConfirmation, token: token! },
    });

    if (response?.data?.confirmPatient?.id) {
      setSuccessfullyConfirmed(true);
    }
  };

  let pageContent;

  if (successfullyConfirmed) {
    pageContent = (
      <AuthSuccessMessage
        message={_(msg`Your account has been confirmed. You can now log in.`)}
      />
    );
  } else {
    pageContent = (
      <>
        <div className="auth-page__title">
          <h1>
            <Trans>Welcome to IDA</Trans>
          </h1>
          <p className="u-h3">
            <Trans>Confirm your account by setting a password.</Trans>
          </p>
        </div>

        <SetPasswordForm
          newAccount={true}
          error={error}
          expectedErrorCodes={expectedErrorCodes}
          loading={loading}
          onSubmit={onSubmit}
        />
      </>
    );
  }

  return (
    <Layout title={_(msg`Welcome to IDA`)} hideTitle={true} hideNav={true}>
      <div className="auth-page a-content a-content--s">{pageContent}</div>
    </Layout>
  );
}
