import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Date: { input: string; output: string; }
  DateTime: { input: string; output: string; }
};

/** A Survey answer option to a question */
export type Answer = {
  label?: Maybe<Scalars['String']['output']>;
  value: Scalars['Int']['output'];
};

export enum AnswerType {
  Labels = 'LABELS',
  Numbers = 'NUMBERS'
}

export enum AppointmentState {
  Attended = 'ATTENDED',
  Unattended = 'UNATTENDED'
}

/** A carepath. */
export type Carepath = {
  id: Scalars['ID']['output'];
  phases: Array<Phase>;
  startDate: Scalars['Date']['output'];
};

export type Error = {
  code: ErrorCode;
  message: Scalars['String']['output'];
};

/** Error code */
export enum ErrorCode {
  CarepathNotFound = 'CAREPATH_NOT_FOUND',
  Default = 'DEFAULT',
  FirstFactorTokenRequired = 'FIRST_FACTOR_TOKEN_REQUIRED',
  FullTokenRequired = 'FULL_TOKEN_REQUIRED',
  InvalidCredentials = 'INVALID_CREDENTIALS',
  InvalidSecondFactorToken = 'INVALID_SECOND_FACTOR_TOKEN',
  PatientAlreadyConfirmed = 'PATIENT_ALREADY_CONFIRMED',
  PatientHasNoSecondFactorSecret = 'PATIENT_HAS_NO_SECOND_FACTOR_SECRET',
  PatientNotFound = 'PATIENT_NOT_FOUND',
  StepNotFound = 'STEP_NOT_FOUND',
  StepNoAttendanceRequired = 'STEP_NO_ATTENDANCE_REQUIRED',
  SubmissionNotFound = 'SUBMISSION_NOT_FOUND',
  TokenInvalidOrExpired = 'TOKEN_INVALID_OR_EXPIRED',
  Unauthorized = 'UNAUTHORIZED',
  ValidationFailed = 'VALIDATION_FAILED'
}

/** A notification. */
export type Notification = {
  stepId: Scalars['ID']['output'];
  stepName: Scalars['String']['output'];
  type: NotificationType;
};

export enum NotificationType {
  Appointment = 'APPOINTMENT',
  Attendance = 'ATTENDANCE'
}

/** A patient. */
export type Patient = {
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
};

/** A Survey answer given by a patient */
export type PatientAnswer = {
  alarming: Scalars['Boolean']['output'];
  questionExternalId: Scalars['String']['output'];
  value: Scalars['Int']['output'];
};

/** A phase. */
export type Phase = {
  active: Scalars['Boolean']['output'];
  alternativePhases: Array<Scalars['String']['output']>;
  alternativeText?: Maybe<Scalars['String']['output']>;
  daysOffset: Scalars['Int']['output'];
  displayName: Scalars['String']['output'];
  /** In days */
  duration: Scalars['Int']['output'];
  hasParallelPhases: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  infoUrl: Scalars['String']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  optional: Scalars['Boolean']['output'];
  /** A berlin timezone date */
  startDate: Scalars['Date']['output'];
  steps: Array<Step>;
  type: PhaseType;
};

export enum PhaseType {
  AdjBoneDirectedTherapy = 'ADJ_BONE_DIRECTED_THERAPY',
  AdjChemoAntiHer2TherapyEcq2WPacliQwDakq3W = 'ADJ_CHEMO_ANTI_HER2_THERAPY_ECQ2W_PACLI_QW_DAKQ3W',
  AdjChemoAntiHer2TherapyEcq2WPacliQwTrastuzumabQ3W = 'ADJ_CHEMO_ANTI_HER2_THERAPY_ECQ2W_PACLI_QW_TRASTUZUMAB_Q3W',
  AdjChemoAntiHer2TherapyPacliQwDakq3WEcq2W = 'ADJ_CHEMO_ANTI_HER2_THERAPY_PACLI_QW_DAKQ3W_ECQ2W',
  AdjChemoAntiHer2TherapyPacliQwTrastuzumabQ3W = 'ADJ_CHEMO_ANTI_HER2_THERAPY_PACLI_QW_TRASTUZUMAB_Q3W',
  AdjChemoAntiHer2TherapyPacliQwTrastuzumabQ3WEcq2W = 'ADJ_CHEMO_ANTI_HER2_THERAPY_PACLI_QW_TRASTUZUMAB_Q3W_ECQ2W',
  AdjChemoEcq2WPacliQ2W = 'ADJ_CHEMO_ECQ2W_PACLI_Q2W',
  AdjChemoEcq2WPacliQwCbq3W = 'ADJ_CHEMO_ECQ2W_PACLI_QW_CBQ3W',
  AdjEndocrineBasedTherapyCdk4_6I = 'ADJ_ENDOCRINE_BASED_THERAPY_CDK4_6I',
  AdjEndocrineTherapy = 'ADJ_ENDOCRINE_THERAPY',
  AdjExtendedAntiHer2TherapyNeratinib = 'ADJ_EXTENDED_ANTI_HER2_THERAPY_NERATINIB',
  AdjTargetedTherapyOlaparib = 'ADJ_TARGETED_THERAPY_OLAPARIB',
  ClinicalTrials = 'CLINICAL_TRIALS',
  ContinuationOfAdjAntiHer2Therapy = 'CONTINUATION_OF_ADJ_ANTI_HER2_THERAPY',
  Diagnostics = 'DIAGNOSTICS',
  FertilityProtection = 'FERTILITY_PROTECTION',
  FollowUp = 'FOLLOW_UP',
  GeneticTesting = 'GENETIC_TESTING',
  GenomicTesting = 'GENOMIC_TESTING',
  NeoadjChemoAntiHer2TherapyEcq2WPacliQwDakq3W = 'NEOADJ_CHEMO_ANTI_HER2_THERAPY_ECQ2W_PACLI_QW_DAKQ3W',
  NeoadjChemoAntiHer2TherapyEcq2WPacliQwTrastuzumabQ3W = 'NEOADJ_CHEMO_ANTI_HER2_THERAPY_ECQ2W_PACLI_QW_TRASTUZUMAB_Q3W',
  NeoadjChemoAntiHer2TherapyPacliQwDakq3WEcq2W = 'NEOADJ_CHEMO_ANTI_HER2_THERAPY_PACLI_QW_DAKQ3W_ECQ2W',
  NeoadjChemoAntiHer2TherapyPacliQwTrastuzumabQ3WEcq2W = 'NEOADJ_CHEMO_ANTI_HER2_THERAPY_PACLI_QW_TRASTUZUMAB_Q3W_ECQ2W',
  NeoadjChemoEcq2WPacliQ2W = 'NEOADJ_CHEMO_ECQ2W_PACLI_Q2W',
  NeoadjChemoEcq2WPacliQwCbq3W = 'NEOADJ_CHEMO_ECQ2W_PACLI_QW_CBQ3W',
  NeoadjChemoImmunotherapyEcq3WPq3WPacliQwCbq3WPq3W = 'NEOADJ_CHEMO_IMMUNOTHERAPY_ECQ3W_PQ3W_PACLI_QW_CBQ3W_PQ3W',
  NeoadjChemoImmunotherapyPacliQwCbq3WPq3WEcq3WPq3W = 'NEOADJ_CHEMO_IMMUNOTHERAPY_PACLI_QW_CBQ3W_PQ3W_ECQ3W_PQ3W',
  PostNeoadjAntiHer2Therapy = 'POST_NEOADJ_ANTI_HER2_THERAPY',
  PostNeoadjChemoCapecitabine = 'POST_NEOADJ_CHEMO_CAPECITABINE',
  PostNeoadjImmunotherapy = 'POST_NEOADJ_IMMUNOTHERAPY',
  PreSurgicalEndocrineInductionTherapy = 'PRE_SURGICAL_ENDOCRINE_INDUCTION_THERAPY',
  PrimaryEndocrineTherapy = 'PRIMARY_ENDOCRINE_THERAPY',
  Radiotherapy = 'RADIOTHERAPY',
  ReconstructiveSurgery = 'RECONSTRUCTIVE_SURGERY',
  Surgery = 'SURGERY'
}

/** A Survey question */
export type Question = {
  answerType: AnswerType;
  answers: Array<Answer>;
  externalId: Scalars['String']['output'];
  infoUrl: Scalars['String']['output'];
  prompt: Scalars['String']['output'];
};

/**
 * ### Mutations
 *
 * #### API Errors
 *
 * Error responses of the API will have an `errors` key that contains
 * a list of error objects each carrying mandatory `code` and `message` keys.
 * Courtesy of the GraphQL executor, you will also get information about the
 * location of the offending query/mutation in your request (`locations`),
 * as well as the `path` of the error.
 *
 * ##### Example
 *
 *     {
 *       "errors": [
 *         {
 *           "code": "VALIDATION_FAILED",
 *           "locations": [{ "column": 0, "line": 2 }],
 *           "message": "validationFailed",
 *           "path": ["getFoo"]
 *         }
 *       ]
 *     }
 */
export type RootMutationType = {
  /** Confirm patient */
  confirmPatient?: Maybe<Patient>;
  /** Creates a survey submission for a patient */
  createSurveySubmission?: Maybe<Submission>;
  /** Edit a step */
  editStep?: Maybe<Step>;
  /** Edit a steps note */
  editStepNotes?: Maybe<Step>;
  /** Login */
  login?: Maybe<Token>;
  /** Logout */
  logout?: Maybe<SimpleResponse>;
  /** Resets a patient's password to the submitted password */
  resetPassword?: Maybe<SimpleResponse>;
  /** Sends an email with instructions to update the email address */
  sendConfirmUpdatedPatientEmailAddressEmail?: Maybe<SimpleResponse>;
  /** Sends an email with instructions to reset the password */
  sendResetPasswordEmail?: Maybe<SimpleResponse>;
  /** Update the email address of a patient */
  updatePatientEmail?: Maybe<SimpleResponse>;
  /** Update password of a patient */
  updatePatientPassword?: Maybe<Patient>;
  /** Updates a step's appointment state */
  updateStepAppointmentState?: Maybe<Step>;
};


/**
 * ### Mutations
 *
 * #### API Errors
 *
 * Error responses of the API will have an `errors` key that contains
 * a list of error objects each carrying mandatory `code` and `message` keys.
 * Courtesy of the GraphQL executor, you will also get information about the
 * location of the offending query/mutation in your request (`locations`),
 * as well as the `path` of the error.
 *
 * ##### Example
 *
 *     {
 *       "errors": [
 *         {
 *           "code": "VALIDATION_FAILED",
 *           "locations": [{ "column": 0, "line": 2 }],
 *           "message": "validationFailed",
 *           "path": ["getFoo"]
 *         }
 *       ]
 *     }
 */
export type RootMutationTypeConfirmPatientArgs = {
  password: Scalars['String']['input'];
  passwordConfirmation: Scalars['String']['input'];
  token: Scalars['String']['input'];
};


/**
 * ### Mutations
 *
 * #### API Errors
 *
 * Error responses of the API will have an `errors` key that contains
 * a list of error objects each carrying mandatory `code` and `message` keys.
 * Courtesy of the GraphQL executor, you will also get information about the
 * location of the offending query/mutation in your request (`locations`),
 * as well as the `path` of the error.
 *
 * ##### Example
 *
 *     {
 *       "errors": [
 *         {
 *           "code": "VALIDATION_FAILED",
 *           "locations": [{ "column": 0, "line": 2 }],
 *           "message": "validationFailed",
 *           "path": ["getFoo"]
 *         }
 *       ]
 *     }
 */
export type RootMutationTypeCreateSurveySubmissionArgs = {
  answers: Array<SubmissionAnswer>;
  surveyType: SurveyType;
};


/**
 * ### Mutations
 *
 * #### API Errors
 *
 * Error responses of the API will have an `errors` key that contains
 * a list of error objects each carrying mandatory `code` and `message` keys.
 * Courtesy of the GraphQL executor, you will also get information about the
 * location of the offending query/mutation in your request (`locations`),
 * as well as the `path` of the error.
 *
 * ##### Example
 *
 *     {
 *       "errors": [
 *         {
 *           "code": "VALIDATION_FAILED",
 *           "locations": [{ "column": 0, "line": 2 }],
 *           "message": "validationFailed",
 *           "path": ["getFoo"]
 *         }
 *       ]
 *     }
 */
export type RootMutationTypeEditStepArgs = {
  appointmentAt?: InputMaybe<Scalars['DateTime']['input']>;
  appointmentDetails?: InputMaybe<Scalars['String']['input']>;
  stepId: Scalars['String']['input'];
};


/**
 * ### Mutations
 *
 * #### API Errors
 *
 * Error responses of the API will have an `errors` key that contains
 * a list of error objects each carrying mandatory `code` and `message` keys.
 * Courtesy of the GraphQL executor, you will also get information about the
 * location of the offending query/mutation in your request (`locations`),
 * as well as the `path` of the error.
 *
 * ##### Example
 *
 *     {
 *       "errors": [
 *         {
 *           "code": "VALIDATION_FAILED",
 *           "locations": [{ "column": 0, "line": 2 }],
 *           "message": "validationFailed",
 *           "path": ["getFoo"]
 *         }
 *       ]
 *     }
 */
export type RootMutationTypeEditStepNotesArgs = {
  privateNotes?: InputMaybe<Scalars['String']['input']>;
  stepId: Scalars['String']['input'];
};


/**
 * ### Mutations
 *
 * #### API Errors
 *
 * Error responses of the API will have an `errors` key that contains
 * a list of error objects each carrying mandatory `code` and `message` keys.
 * Courtesy of the GraphQL executor, you will also get information about the
 * location of the offending query/mutation in your request (`locations`),
 * as well as the `path` of the error.
 *
 * ##### Example
 *
 *     {
 *       "errors": [
 *         {
 *           "code": "VALIDATION_FAILED",
 *           "locations": [{ "column": 0, "line": 2 }],
 *           "message": "validationFailed",
 *           "path": ["getFoo"]
 *         }
 *       ]
 *     }
 */
export type RootMutationTypeLoginArgs = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};


/**
 * ### Mutations
 *
 * #### API Errors
 *
 * Error responses of the API will have an `errors` key that contains
 * a list of error objects each carrying mandatory `code` and `message` keys.
 * Courtesy of the GraphQL executor, you will also get information about the
 * location of the offending query/mutation in your request (`locations`),
 * as well as the `path` of the error.
 *
 * ##### Example
 *
 *     {
 *       "errors": [
 *         {
 *           "code": "VALIDATION_FAILED",
 *           "locations": [{ "column": 0, "line": 2 }],
 *           "message": "validationFailed",
 *           "path": ["getFoo"]
 *         }
 *       ]
 *     }
 */
export type RootMutationTypeResetPasswordArgs = {
  password: Scalars['String']['input'];
  passwordConfirmation: Scalars['String']['input'];
  resetToken: Scalars['String']['input'];
};


/**
 * ### Mutations
 *
 * #### API Errors
 *
 * Error responses of the API will have an `errors` key that contains
 * a list of error objects each carrying mandatory `code` and `message` keys.
 * Courtesy of the GraphQL executor, you will also get information about the
 * location of the offending query/mutation in your request (`locations`),
 * as well as the `path` of the error.
 *
 * ##### Example
 *
 *     {
 *       "errors": [
 *         {
 *           "code": "VALIDATION_FAILED",
 *           "locations": [{ "column": 0, "line": 2 }],
 *           "message": "validationFailed",
 *           "path": ["getFoo"]
 *         }
 *       ]
 *     }
 */
export type RootMutationTypeSendConfirmUpdatedPatientEmailAddressEmailArgs = {
  currentPassword: Scalars['String']['input'];
  email: Scalars['String']['input'];
};


/**
 * ### Mutations
 *
 * #### API Errors
 *
 * Error responses of the API will have an `errors` key that contains
 * a list of error objects each carrying mandatory `code` and `message` keys.
 * Courtesy of the GraphQL executor, you will also get information about the
 * location of the offending query/mutation in your request (`locations`),
 * as well as the `path` of the error.
 *
 * ##### Example
 *
 *     {
 *       "errors": [
 *         {
 *           "code": "VALIDATION_FAILED",
 *           "locations": [{ "column": 0, "line": 2 }],
 *           "message": "validationFailed",
 *           "path": ["getFoo"]
 *         }
 *       ]
 *     }
 */
export type RootMutationTypeSendResetPasswordEmailArgs = {
  email: Scalars['String']['input'];
};


/**
 * ### Mutations
 *
 * #### API Errors
 *
 * Error responses of the API will have an `errors` key that contains
 * a list of error objects each carrying mandatory `code` and `message` keys.
 * Courtesy of the GraphQL executor, you will also get information about the
 * location of the offending query/mutation in your request (`locations`),
 * as well as the `path` of the error.
 *
 * ##### Example
 *
 *     {
 *       "errors": [
 *         {
 *           "code": "VALIDATION_FAILED",
 *           "locations": [{ "column": 0, "line": 2 }],
 *           "message": "validationFailed",
 *           "path": ["getFoo"]
 *         }
 *       ]
 *     }
 */
export type RootMutationTypeUpdatePatientEmailArgs = {
  token: Scalars['String']['input'];
};


/**
 * ### Mutations
 *
 * #### API Errors
 *
 * Error responses of the API will have an `errors` key that contains
 * a list of error objects each carrying mandatory `code` and `message` keys.
 * Courtesy of the GraphQL executor, you will also get information about the
 * location of the offending query/mutation in your request (`locations`),
 * as well as the `path` of the error.
 *
 * ##### Example
 *
 *     {
 *       "errors": [
 *         {
 *           "code": "VALIDATION_FAILED",
 *           "locations": [{ "column": 0, "line": 2 }],
 *           "message": "validationFailed",
 *           "path": ["getFoo"]
 *         }
 *       ]
 *     }
 */
export type RootMutationTypeUpdatePatientPasswordArgs = {
  currentPassword: Scalars['String']['input'];
  password: Scalars['String']['input'];
  passwordConfirmation: Scalars['String']['input'];
};


/**
 * ### Mutations
 *
 * #### API Errors
 *
 * Error responses of the API will have an `errors` key that contains
 * a list of error objects each carrying mandatory `code` and `message` keys.
 * Courtesy of the GraphQL executor, you will also get information about the
 * location of the offending query/mutation in your request (`locations`),
 * as well as the `path` of the error.
 *
 * ##### Example
 *
 *     {
 *       "errors": [
 *         {
 *           "code": "VALIDATION_FAILED",
 *           "locations": [{ "column": 0, "line": 2 }],
 *           "message": "validationFailed",
 *           "path": ["getFoo"]
 *         }
 *       ]
 *     }
 */
export type RootMutationTypeUpdateStepAppointmentStateArgs = {
  appointmentState?: InputMaybe<AppointmentState>;
  stepId: Scalars['String']['input'];
};

/**
 * ### Queries
 *
 * #### API Errors
 *
 * Error responses of the API will have an `errors` key that contains
 * a list of error objects each carrying mandatory `code` and `message` keys.
 * Courtesy of the GraphQL executor, you will also get information about the
 * location of the offending query/mutation in your request (`locations`),
 * as well as the `path` of the error.
 *
 * ##### Example
 *
 *     {
 *       "errors": [
 *         {
 *           "code": "VALIDATION_FAILED",
 *           "locations": [{ "column": 0, "line": 2 }],
 *           "message": "validationFailed",
 *           "path": ["getFoo"]
 *         }
 *       ]
 *     }
 */
export type RootQueryType = {
  /** Get the patient's care path */
  carepath?: Maybe<Carepath>;
  /** Get all possible error codes */
  errors: Array<Error>;
  /** Get the patient's own information */
  me?: Maybe<Patient>;
  /** Lists all notifications of a patient */
  notifications: Array<Notification>;
  /** Get a survey's questions and answers */
  surveyQuestions: Array<Question>;
  /** Get a survey submissions of a patient */
  surveySubmission: Submission;
  /** Lists all survey submissions of a patient */
  surveySubmissions: Array<Submission>;
};


/**
 * ### Queries
 *
 * #### API Errors
 *
 * Error responses of the API will have an `errors` key that contains
 * a list of error objects each carrying mandatory `code` and `message` keys.
 * Courtesy of the GraphQL executor, you will also get information about the
 * location of the offending query/mutation in your request (`locations`),
 * as well as the `path` of the error.
 *
 * ##### Example
 *
 *     {
 *       "errors": [
 *         {
 *           "code": "VALIDATION_FAILED",
 *           "locations": [{ "column": 0, "line": 2 }],
 *           "message": "validationFailed",
 *           "path": ["getFoo"]
 *         }
 *       ]
 *     }
 */
export type RootQueryTypeSurveyQuestionsArgs = {
  surveyType: SurveyType;
};


/**
 * ### Queries
 *
 * #### API Errors
 *
 * Error responses of the API will have an `errors` key that contains
 * a list of error objects each carrying mandatory `code` and `message` keys.
 * Courtesy of the GraphQL executor, you will also get information about the
 * location of the offending query/mutation in your request (`locations`),
 * as well as the `path` of the error.
 *
 * ##### Example
 *
 *     {
 *       "errors": [
 *         {
 *           "code": "VALIDATION_FAILED",
 *           "locations": [{ "column": 0, "line": 2 }],
 *           "message": "validationFailed",
 *           "path": ["getFoo"]
 *         }
 *       ]
 *     }
 */
export type RootQueryTypeSurveySubmissionArgs = {
  submissionId: Scalars['String']['input'];
};


/**
 * ### Queries
 *
 * #### API Errors
 *
 * Error responses of the API will have an `errors` key that contains
 * a list of error objects each carrying mandatory `code` and `message` keys.
 * Courtesy of the GraphQL executor, you will also get information about the
 * location of the offending query/mutation in your request (`locations`),
 * as well as the `path` of the error.
 *
 * ##### Example
 *
 *     {
 *       "errors": [
 *         {
 *           "code": "VALIDATION_FAILED",
 *           "locations": [{ "column": 0, "line": 2 }],
 *           "message": "validationFailed",
 *           "path": ["getFoo"]
 *         }
 *       ]
 *     }
 */
export type RootQueryTypeSurveySubmissionsArgs = {
  surveyType: SurveyType;
};

/** This is used by fire and forget operations that do not have a response. */
export type SimpleResponse = {
  /** A status field with the response status, so something in the response can be queried for. */
  status?: Maybe<Scalars['String']['output']>;
};

/** A step. */
export type Step = {
  active: Scalars['Boolean']['output'];
  alternativeSteps: Array<Scalars['String']['output']>;
  alternativeText?: Maybe<Scalars['String']['output']>;
  appointmentAt?: Maybe<Scalars['DateTime']['output']>;
  appointmentDetails?: Maybe<Scalars['String']['output']>;
  appointmentState?: Maybe<AppointmentState>;
  attendanceRequired: Scalars['Boolean']['output'];
  daysOffset: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  optional: Scalars['Boolean']['output'];
  privateNotes?: Maybe<Scalars['String']['output']>;
  /** A berlin timezone date */
  startDate: Scalars['Date']['output'];
};

/** A Survey submission */
export type Submission = {
  alarming: Scalars['Boolean']['output'];
  answers?: Maybe<Array<PatientAnswer>>;
  id: Scalars['ID']['output'];
  insertedAt: Scalars['DateTime']['output'];
  surveyType: SurveyType;
};

/** An answer of a survey submission */
export type SubmissionAnswer = {
  questionExternalId: Scalars['String']['input'];
  value: Scalars['Int']['input'];
};

export enum SurveyType {
  Monitoring = 'MONITORING',
  PostOperative = 'POST_OPERATIVE'
}

/** A patient auth token. */
export type Token = {
  token: Scalars['String']['output'];
};

export type ConfirmPatientMutationVariables = Exact<{
  password: Scalars['String']['input'];
  passwordConfirmation: Scalars['String']['input'];
  token: Scalars['String']['input'];
}>;


export type ConfirmPatientMutation = { confirmPatient?: { id: string } | null };

export type CreateSurveySubmissionMutationVariables = Exact<{
  surveyType: SurveyType;
  answers: Array<SubmissionAnswer> | SubmissionAnswer;
}>;


export type CreateSurveySubmissionMutation = { createSurveySubmission?: { insertedAt: string, surveyType: SurveyType, alarming: boolean } | null };

export type EditStepMutationVariables = Exact<{
  stepId: Scalars['String']['input'];
  appointmentAt?: InputMaybe<Scalars['DateTime']['input']>;
  appointmentDetails?: InputMaybe<Scalars['String']['input']>;
}>;


export type EditStepMutation = { editStep?: { id: string, appointmentAt?: string | null, appointmentDetails?: string | null } | null };

export type EditStepNotesMutationVariables = Exact<{
  stepId: Scalars['String']['input'];
  privateNotes?: InputMaybe<Scalars['String']['input']>;
}>;


export type EditStepNotesMutation = { editStepNotes?: { id: string, privateNotes?: string | null } | null };

export type LogInMutationVariables = Exact<{
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
}>;


export type LogInMutation = { login?: { token: string } | null };

export type LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutMutation = { logout?: { status?: string | null } | null };

export type ResetPasswordMutationVariables = Exact<{
  password: Scalars['String']['input'];
  passwordConfirmation: Scalars['String']['input'];
  resetToken: Scalars['String']['input'];
}>;


export type ResetPasswordMutation = { resetPassword?: { status?: string | null } | null };

export type SendConfirmUpdatedPatientEmailAddressEmailMutationVariables = Exact<{
  email: Scalars['String']['input'];
  currentPassword: Scalars['String']['input'];
}>;


export type SendConfirmUpdatedPatientEmailAddressEmailMutation = { sendConfirmUpdatedPatientEmailAddressEmail?: { status?: string | null } | null };

export type SendResetPasswordEmailMutationVariables = Exact<{
  email: Scalars['String']['input'];
}>;


export type SendResetPasswordEmailMutation = { sendResetPasswordEmail?: { status?: string | null } | null };

export type UpdatePatientEmailMutationVariables = Exact<{
  token: Scalars['String']['input'];
}>;


export type UpdatePatientEmailMutation = { updatePatientEmail?: { status?: string | null } | null };

export type UpdatePatientPasswordMutationVariables = Exact<{
  currentPassword: Scalars['String']['input'];
  password: Scalars['String']['input'];
  passwordConfirmation: Scalars['String']['input'];
}>;


export type UpdatePatientPasswordMutation = { updatePatientPassword?: { id: string } | null };

export type UpdateStepAppointmentStateMutationVariables = Exact<{
  stepId: Scalars['String']['input'];
  appointmentState?: InputMaybe<AppointmentState>;
}>;


export type UpdateStepAppointmentStateMutation = { updateStepAppointmentState?: { id: string, appointmentState?: AppointmentState | null } | null };

export type CarepathQueryVariables = Exact<{ [key: string]: never; }>;


export type CarepathQuery = { carepath?: { id: string, phases: Array<{ id: string, type: PhaseType, startDate: string, optional: boolean, displayName: string, duration: number, alternativePhases: Array<string>, alternativeText?: string | null, active: boolean, hasParallelPhases: boolean, daysOffset: number, infoUrl: string, steps: Array<{ id: string, startDate: string, optional: boolean, name: string, appointmentAt?: string | null, attendanceRequired: boolean, appointmentState?: AppointmentState | null, active: boolean, alternativeSteps: Array<string>, alternativeText?: string | null, daysOffset: number, appointmentDetails?: string | null, privateNotes?: string | null }> }> } | null };

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { me?: { email: string } | null };

export type NotificationsQueryVariables = Exact<{ [key: string]: never; }>;


export type NotificationsQuery = { notifications: Array<{ stepId: string, type: NotificationType }> };

export type SurveyQuestionsQueryVariables = Exact<{
  surveyType: SurveyType;
}>;


export type SurveyQuestionsQuery = { surveyQuestions: Array<{ externalId: string, prompt: string, infoUrl: string, answerType: AnswerType, answers: Array<{ value: number, label?: string | null }> }> };

export type SurveySubmissionQueryVariables = Exact<{
  submissionId: Scalars['String']['input'];
}>;


export type SurveySubmissionQuery = { surveySubmission: { id: string, surveyType: SurveyType, insertedAt: string, alarming: boolean, answers?: Array<{ questionExternalId: string, alarming: boolean, value: number }> | null } };

export type SurveySubmissionsQueryVariables = Exact<{
  surveyType: SurveyType;
}>;


export type SurveySubmissionsQuery = { surveySubmissions: Array<{ id: string, surveyType: SurveyType, insertedAt: string, alarming: boolean }> };


export const ConfirmPatientDocument = gql`
    mutation ConfirmPatient($password: String!, $passwordConfirmation: String!, $token: String!) {
  confirmPatient(
    password: $password
    passwordConfirmation: $passwordConfirmation
    token: $token
  ) {
    id
  }
}
    `;
export type ConfirmPatientMutationFn = Apollo.MutationFunction<ConfirmPatientMutation, ConfirmPatientMutationVariables>;

/**
 * __useConfirmPatientMutation__
 *
 * To run a mutation, you first call `useConfirmPatientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmPatientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmPatientMutation, { data, loading, error }] = useConfirmPatientMutation({
 *   variables: {
 *      password: // value for 'password'
 *      passwordConfirmation: // value for 'passwordConfirmation'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useConfirmPatientMutation(baseOptions?: Apollo.MutationHookOptions<ConfirmPatientMutation, ConfirmPatientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConfirmPatientMutation, ConfirmPatientMutationVariables>(ConfirmPatientDocument, options);
      }
export type ConfirmPatientMutationHookResult = ReturnType<typeof useConfirmPatientMutation>;
export type ConfirmPatientMutationResult = Apollo.MutationResult<ConfirmPatientMutation>;
export type ConfirmPatientMutationOptions = Apollo.BaseMutationOptions<ConfirmPatientMutation, ConfirmPatientMutationVariables>;
export const CreateSurveySubmissionDocument = gql`
    mutation createSurveySubmission($surveyType: SurveyType!, $answers: [SubmissionAnswer!]!) {
  createSurveySubmission(surveyType: $surveyType, answers: $answers) {
    insertedAt
    surveyType
    alarming
  }
}
    `;
export type CreateSurveySubmissionMutationFn = Apollo.MutationFunction<CreateSurveySubmissionMutation, CreateSurveySubmissionMutationVariables>;

/**
 * __useCreateSurveySubmissionMutation__
 *
 * To run a mutation, you first call `useCreateSurveySubmissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSurveySubmissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSurveySubmissionMutation, { data, loading, error }] = useCreateSurveySubmissionMutation({
 *   variables: {
 *      surveyType: // value for 'surveyType'
 *      answers: // value for 'answers'
 *   },
 * });
 */
export function useCreateSurveySubmissionMutation(baseOptions?: Apollo.MutationHookOptions<CreateSurveySubmissionMutation, CreateSurveySubmissionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSurveySubmissionMutation, CreateSurveySubmissionMutationVariables>(CreateSurveySubmissionDocument, options);
      }
export type CreateSurveySubmissionMutationHookResult = ReturnType<typeof useCreateSurveySubmissionMutation>;
export type CreateSurveySubmissionMutationResult = Apollo.MutationResult<CreateSurveySubmissionMutation>;
export type CreateSurveySubmissionMutationOptions = Apollo.BaseMutationOptions<CreateSurveySubmissionMutation, CreateSurveySubmissionMutationVariables>;
export const EditStepDocument = gql`
    mutation editStep($stepId: String!, $appointmentAt: DateTime, $appointmentDetails: String) {
  editStep(
    stepId: $stepId
    appointmentAt: $appointmentAt
    appointmentDetails: $appointmentDetails
  ) {
    id
    appointmentAt
    appointmentDetails
  }
}
    `;
export type EditStepMutationFn = Apollo.MutationFunction<EditStepMutation, EditStepMutationVariables>;

/**
 * __useEditStepMutation__
 *
 * To run a mutation, you first call `useEditStepMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditStepMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editStepMutation, { data, loading, error }] = useEditStepMutation({
 *   variables: {
 *      stepId: // value for 'stepId'
 *      appointmentAt: // value for 'appointmentAt'
 *      appointmentDetails: // value for 'appointmentDetails'
 *   },
 * });
 */
export function useEditStepMutation(baseOptions?: Apollo.MutationHookOptions<EditStepMutation, EditStepMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditStepMutation, EditStepMutationVariables>(EditStepDocument, options);
      }
export type EditStepMutationHookResult = ReturnType<typeof useEditStepMutation>;
export type EditStepMutationResult = Apollo.MutationResult<EditStepMutation>;
export type EditStepMutationOptions = Apollo.BaseMutationOptions<EditStepMutation, EditStepMutationVariables>;
export const EditStepNotesDocument = gql`
    mutation editStepNotes($stepId: String!, $privateNotes: String) {
  editStepNotes(stepId: $stepId, privateNotes: $privateNotes) {
    id
    privateNotes
  }
}
    `;
export type EditStepNotesMutationFn = Apollo.MutationFunction<EditStepNotesMutation, EditStepNotesMutationVariables>;

/**
 * __useEditStepNotesMutation__
 *
 * To run a mutation, you first call `useEditStepNotesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditStepNotesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editStepNotesMutation, { data, loading, error }] = useEditStepNotesMutation({
 *   variables: {
 *      stepId: // value for 'stepId'
 *      privateNotes: // value for 'privateNotes'
 *   },
 * });
 */
export function useEditStepNotesMutation(baseOptions?: Apollo.MutationHookOptions<EditStepNotesMutation, EditStepNotesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditStepNotesMutation, EditStepNotesMutationVariables>(EditStepNotesDocument, options);
      }
export type EditStepNotesMutationHookResult = ReturnType<typeof useEditStepNotesMutation>;
export type EditStepNotesMutationResult = Apollo.MutationResult<EditStepNotesMutation>;
export type EditStepNotesMutationOptions = Apollo.BaseMutationOptions<EditStepNotesMutation, EditStepNotesMutationVariables>;
export const LogInDocument = gql`
    mutation logIn($email: String!, $password: String!) {
  login(email: $email, password: $password) {
    token
  }
}
    `;
export type LogInMutationFn = Apollo.MutationFunction<LogInMutation, LogInMutationVariables>;

/**
 * __useLogInMutation__
 *
 * To run a mutation, you first call `useLogInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logInMutation, { data, loading, error }] = useLogInMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLogInMutation(baseOptions?: Apollo.MutationHookOptions<LogInMutation, LogInMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LogInMutation, LogInMutationVariables>(LogInDocument, options);
      }
export type LogInMutationHookResult = ReturnType<typeof useLogInMutation>;
export type LogInMutationResult = Apollo.MutationResult<LogInMutation>;
export type LogInMutationOptions = Apollo.BaseMutationOptions<LogInMutation, LogInMutationVariables>;
export const LogoutDocument = gql`
    mutation Logout {
  logout {
    status
  }
}
    `;
export type LogoutMutationFn = Apollo.MutationFunction<LogoutMutation, LogoutMutationVariables>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: Apollo.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, options);
      }
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = Apollo.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = Apollo.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation ResetPassword($password: String!, $passwordConfirmation: String!, $resetToken: String!) {
  resetPassword(
    password: $password
    passwordConfirmation: $passwordConfirmation
    resetToken: $resetToken
  ) {
    status
  }
}
    `;
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      password: // value for 'password'
 *      passwordConfirmation: // value for 'passwordConfirmation'
 *      resetToken: // value for 'resetToken'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, options);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const SendConfirmUpdatedPatientEmailAddressEmailDocument = gql`
    mutation sendConfirmUpdatedPatientEmailAddressEmail($email: String!, $currentPassword: String!) {
  sendConfirmUpdatedPatientEmailAddressEmail(
    email: $email
    currentPassword: $currentPassword
  ) {
    status
  }
}
    `;
export type SendConfirmUpdatedPatientEmailAddressEmailMutationFn = Apollo.MutationFunction<SendConfirmUpdatedPatientEmailAddressEmailMutation, SendConfirmUpdatedPatientEmailAddressEmailMutationVariables>;

/**
 * __useSendConfirmUpdatedPatientEmailAddressEmailMutation__
 *
 * To run a mutation, you first call `useSendConfirmUpdatedPatientEmailAddressEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendConfirmUpdatedPatientEmailAddressEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendConfirmUpdatedPatientEmailAddressEmailMutation, { data, loading, error }] = useSendConfirmUpdatedPatientEmailAddressEmailMutation({
 *   variables: {
 *      email: // value for 'email'
 *      currentPassword: // value for 'currentPassword'
 *   },
 * });
 */
export function useSendConfirmUpdatedPatientEmailAddressEmailMutation(baseOptions?: Apollo.MutationHookOptions<SendConfirmUpdatedPatientEmailAddressEmailMutation, SendConfirmUpdatedPatientEmailAddressEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendConfirmUpdatedPatientEmailAddressEmailMutation, SendConfirmUpdatedPatientEmailAddressEmailMutationVariables>(SendConfirmUpdatedPatientEmailAddressEmailDocument, options);
      }
export type SendConfirmUpdatedPatientEmailAddressEmailMutationHookResult = ReturnType<typeof useSendConfirmUpdatedPatientEmailAddressEmailMutation>;
export type SendConfirmUpdatedPatientEmailAddressEmailMutationResult = Apollo.MutationResult<SendConfirmUpdatedPatientEmailAddressEmailMutation>;
export type SendConfirmUpdatedPatientEmailAddressEmailMutationOptions = Apollo.BaseMutationOptions<SendConfirmUpdatedPatientEmailAddressEmailMutation, SendConfirmUpdatedPatientEmailAddressEmailMutationVariables>;
export const SendResetPasswordEmailDocument = gql`
    mutation SendResetPasswordEmail($email: String!) {
  sendResetPasswordEmail(email: $email) {
    status
  }
}
    `;
export type SendResetPasswordEmailMutationFn = Apollo.MutationFunction<SendResetPasswordEmailMutation, SendResetPasswordEmailMutationVariables>;

/**
 * __useSendResetPasswordEmailMutation__
 *
 * To run a mutation, you first call `useSendResetPasswordEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendResetPasswordEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendResetPasswordEmailMutation, { data, loading, error }] = useSendResetPasswordEmailMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useSendResetPasswordEmailMutation(baseOptions?: Apollo.MutationHookOptions<SendResetPasswordEmailMutation, SendResetPasswordEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendResetPasswordEmailMutation, SendResetPasswordEmailMutationVariables>(SendResetPasswordEmailDocument, options);
      }
export type SendResetPasswordEmailMutationHookResult = ReturnType<typeof useSendResetPasswordEmailMutation>;
export type SendResetPasswordEmailMutationResult = Apollo.MutationResult<SendResetPasswordEmailMutation>;
export type SendResetPasswordEmailMutationOptions = Apollo.BaseMutationOptions<SendResetPasswordEmailMutation, SendResetPasswordEmailMutationVariables>;
export const UpdatePatientEmailDocument = gql`
    mutation UpdatePatientEmail($token: String!) {
  updatePatientEmail(token: $token) {
    status
  }
}
    `;
export type UpdatePatientEmailMutationFn = Apollo.MutationFunction<UpdatePatientEmailMutation, UpdatePatientEmailMutationVariables>;

/**
 * __useUpdatePatientEmailMutation__
 *
 * To run a mutation, you first call `useUpdatePatientEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePatientEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePatientEmailMutation, { data, loading, error }] = useUpdatePatientEmailMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useUpdatePatientEmailMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePatientEmailMutation, UpdatePatientEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePatientEmailMutation, UpdatePatientEmailMutationVariables>(UpdatePatientEmailDocument, options);
      }
export type UpdatePatientEmailMutationHookResult = ReturnType<typeof useUpdatePatientEmailMutation>;
export type UpdatePatientEmailMutationResult = Apollo.MutationResult<UpdatePatientEmailMutation>;
export type UpdatePatientEmailMutationOptions = Apollo.BaseMutationOptions<UpdatePatientEmailMutation, UpdatePatientEmailMutationVariables>;
export const UpdatePatientPasswordDocument = gql`
    mutation updatePatientPassword($currentPassword: String!, $password: String!, $passwordConfirmation: String!) {
  updatePatientPassword(
    currentPassword: $currentPassword
    password: $password
    passwordConfirmation: $passwordConfirmation
  ) {
    id
  }
}
    `;
export type UpdatePatientPasswordMutationFn = Apollo.MutationFunction<UpdatePatientPasswordMutation, UpdatePatientPasswordMutationVariables>;

/**
 * __useUpdatePatientPasswordMutation__
 *
 * To run a mutation, you first call `useUpdatePatientPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePatientPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePatientPasswordMutation, { data, loading, error }] = useUpdatePatientPasswordMutation({
 *   variables: {
 *      currentPassword: // value for 'currentPassword'
 *      password: // value for 'password'
 *      passwordConfirmation: // value for 'passwordConfirmation'
 *   },
 * });
 */
export function useUpdatePatientPasswordMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePatientPasswordMutation, UpdatePatientPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePatientPasswordMutation, UpdatePatientPasswordMutationVariables>(UpdatePatientPasswordDocument, options);
      }
export type UpdatePatientPasswordMutationHookResult = ReturnType<typeof useUpdatePatientPasswordMutation>;
export type UpdatePatientPasswordMutationResult = Apollo.MutationResult<UpdatePatientPasswordMutation>;
export type UpdatePatientPasswordMutationOptions = Apollo.BaseMutationOptions<UpdatePatientPasswordMutation, UpdatePatientPasswordMutationVariables>;
export const UpdateStepAppointmentStateDocument = gql`
    mutation updateStepAppointmentState($stepId: String!, $appointmentState: AppointmentState) {
  updateStepAppointmentState(stepId: $stepId, appointmentState: $appointmentState) {
    id
    appointmentState
  }
}
    `;
export type UpdateStepAppointmentStateMutationFn = Apollo.MutationFunction<UpdateStepAppointmentStateMutation, UpdateStepAppointmentStateMutationVariables>;

/**
 * __useUpdateStepAppointmentStateMutation__
 *
 * To run a mutation, you first call `useUpdateStepAppointmentStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStepAppointmentStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStepAppointmentStateMutation, { data, loading, error }] = useUpdateStepAppointmentStateMutation({
 *   variables: {
 *      stepId: // value for 'stepId'
 *      appointmentState: // value for 'appointmentState'
 *   },
 * });
 */
export function useUpdateStepAppointmentStateMutation(baseOptions?: Apollo.MutationHookOptions<UpdateStepAppointmentStateMutation, UpdateStepAppointmentStateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateStepAppointmentStateMutation, UpdateStepAppointmentStateMutationVariables>(UpdateStepAppointmentStateDocument, options);
      }
export type UpdateStepAppointmentStateMutationHookResult = ReturnType<typeof useUpdateStepAppointmentStateMutation>;
export type UpdateStepAppointmentStateMutationResult = Apollo.MutationResult<UpdateStepAppointmentStateMutation>;
export type UpdateStepAppointmentStateMutationOptions = Apollo.BaseMutationOptions<UpdateStepAppointmentStateMutation, UpdateStepAppointmentStateMutationVariables>;
export const CarepathDocument = gql`
    query carepath {
  carepath {
    id
    phases {
      id
      type
      startDate
      optional
      displayName
      duration
      alternativePhases
      alternativeText
      active
      hasParallelPhases
      daysOffset
      infoUrl
      steps {
        id
        startDate
        optional
        name
        appointmentAt
        attendanceRequired
        appointmentState
        active
        alternativeSteps
        alternativeText
        daysOffset
        appointmentDetails
        privateNotes
      }
    }
  }
}
    `;

/**
 * __useCarepathQuery__
 *
 * To run a query within a React component, call `useCarepathQuery` and pass it any options that fit your needs.
 * When your component renders, `useCarepathQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCarepathQuery({
 *   variables: {
 *   },
 * });
 */
export function useCarepathQuery(baseOptions?: Apollo.QueryHookOptions<CarepathQuery, CarepathQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CarepathQuery, CarepathQueryVariables>(CarepathDocument, options);
      }
export function useCarepathLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CarepathQuery, CarepathQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CarepathQuery, CarepathQueryVariables>(CarepathDocument, options);
        }
export function useCarepathSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CarepathQuery, CarepathQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CarepathQuery, CarepathQueryVariables>(CarepathDocument, options);
        }
export type CarepathQueryHookResult = ReturnType<typeof useCarepathQuery>;
export type CarepathLazyQueryHookResult = ReturnType<typeof useCarepathLazyQuery>;
export type CarepathSuspenseQueryHookResult = ReturnType<typeof useCarepathSuspenseQuery>;
export type CarepathQueryResult = Apollo.QueryResult<CarepathQuery, CarepathQueryVariables>;
export const MeDocument = gql`
    query me {
  me {
    email
  }
}
    `;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export function useMeSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeSuspenseQueryHookResult = ReturnType<typeof useMeSuspenseQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const NotificationsDocument = gql`
    query notifications {
  notifications {
    stepId
    type
  }
}
    `;

/**
 * __useNotificationsQuery__
 *
 * To run a query within a React component, call `useNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useNotificationsQuery(baseOptions?: Apollo.QueryHookOptions<NotificationsQuery, NotificationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NotificationsQuery, NotificationsQueryVariables>(NotificationsDocument, options);
      }
export function useNotificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NotificationsQuery, NotificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NotificationsQuery, NotificationsQueryVariables>(NotificationsDocument, options);
        }
export function useNotificationsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<NotificationsQuery, NotificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<NotificationsQuery, NotificationsQueryVariables>(NotificationsDocument, options);
        }
export type NotificationsQueryHookResult = ReturnType<typeof useNotificationsQuery>;
export type NotificationsLazyQueryHookResult = ReturnType<typeof useNotificationsLazyQuery>;
export type NotificationsSuspenseQueryHookResult = ReturnType<typeof useNotificationsSuspenseQuery>;
export type NotificationsQueryResult = Apollo.QueryResult<NotificationsQuery, NotificationsQueryVariables>;
export const SurveyQuestionsDocument = gql`
    query surveyQuestions($surveyType: SurveyType!) {
  surveyQuestions(surveyType: $surveyType) {
    externalId
    prompt
    infoUrl
    answerType
    answers {
      value
      label
    }
  }
}
    `;

/**
 * __useSurveyQuestionsQuery__
 *
 * To run a query within a React component, call `useSurveyQuestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSurveyQuestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSurveyQuestionsQuery({
 *   variables: {
 *      surveyType: // value for 'surveyType'
 *   },
 * });
 */
export function useSurveyQuestionsQuery(baseOptions: Apollo.QueryHookOptions<SurveyQuestionsQuery, SurveyQuestionsQueryVariables> & ({ variables: SurveyQuestionsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SurveyQuestionsQuery, SurveyQuestionsQueryVariables>(SurveyQuestionsDocument, options);
      }
export function useSurveyQuestionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SurveyQuestionsQuery, SurveyQuestionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SurveyQuestionsQuery, SurveyQuestionsQueryVariables>(SurveyQuestionsDocument, options);
        }
export function useSurveyQuestionsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SurveyQuestionsQuery, SurveyQuestionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SurveyQuestionsQuery, SurveyQuestionsQueryVariables>(SurveyQuestionsDocument, options);
        }
export type SurveyQuestionsQueryHookResult = ReturnType<typeof useSurveyQuestionsQuery>;
export type SurveyQuestionsLazyQueryHookResult = ReturnType<typeof useSurveyQuestionsLazyQuery>;
export type SurveyQuestionsSuspenseQueryHookResult = ReturnType<typeof useSurveyQuestionsSuspenseQuery>;
export type SurveyQuestionsQueryResult = Apollo.QueryResult<SurveyQuestionsQuery, SurveyQuestionsQueryVariables>;
export const SurveySubmissionDocument = gql`
    query surveySubmission($submissionId: String!) {
  surveySubmission(submissionId: $submissionId) {
    id
    surveyType
    insertedAt
    alarming
    answers {
      questionExternalId
      alarming
      value
    }
  }
}
    `;

/**
 * __useSurveySubmissionQuery__
 *
 * To run a query within a React component, call `useSurveySubmissionQuery` and pass it any options that fit your needs.
 * When your component renders, `useSurveySubmissionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSurveySubmissionQuery({
 *   variables: {
 *      submissionId: // value for 'submissionId'
 *   },
 * });
 */
export function useSurveySubmissionQuery(baseOptions: Apollo.QueryHookOptions<SurveySubmissionQuery, SurveySubmissionQueryVariables> & ({ variables: SurveySubmissionQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SurveySubmissionQuery, SurveySubmissionQueryVariables>(SurveySubmissionDocument, options);
      }
export function useSurveySubmissionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SurveySubmissionQuery, SurveySubmissionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SurveySubmissionQuery, SurveySubmissionQueryVariables>(SurveySubmissionDocument, options);
        }
export function useSurveySubmissionSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SurveySubmissionQuery, SurveySubmissionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SurveySubmissionQuery, SurveySubmissionQueryVariables>(SurveySubmissionDocument, options);
        }
export type SurveySubmissionQueryHookResult = ReturnType<typeof useSurveySubmissionQuery>;
export type SurveySubmissionLazyQueryHookResult = ReturnType<typeof useSurveySubmissionLazyQuery>;
export type SurveySubmissionSuspenseQueryHookResult = ReturnType<typeof useSurveySubmissionSuspenseQuery>;
export type SurveySubmissionQueryResult = Apollo.QueryResult<SurveySubmissionQuery, SurveySubmissionQueryVariables>;
export const SurveySubmissionsDocument = gql`
    query surveySubmissions($surveyType: SurveyType!) {
  surveySubmissions(surveyType: $surveyType) {
    id
    surveyType
    insertedAt
    alarming
  }
}
    `;

/**
 * __useSurveySubmissionsQuery__
 *
 * To run a query within a React component, call `useSurveySubmissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSurveySubmissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSurveySubmissionsQuery({
 *   variables: {
 *      surveyType: // value for 'surveyType'
 *   },
 * });
 */
export function useSurveySubmissionsQuery(baseOptions: Apollo.QueryHookOptions<SurveySubmissionsQuery, SurveySubmissionsQueryVariables> & ({ variables: SurveySubmissionsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SurveySubmissionsQuery, SurveySubmissionsQueryVariables>(SurveySubmissionsDocument, options);
      }
export function useSurveySubmissionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SurveySubmissionsQuery, SurveySubmissionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SurveySubmissionsQuery, SurveySubmissionsQueryVariables>(SurveySubmissionsDocument, options);
        }
export function useSurveySubmissionsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SurveySubmissionsQuery, SurveySubmissionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SurveySubmissionsQuery, SurveySubmissionsQueryVariables>(SurveySubmissionsDocument, options);
        }
export type SurveySubmissionsQueryHookResult = ReturnType<typeof useSurveySubmissionsQuery>;
export type SurveySubmissionsLazyQueryHookResult = ReturnType<typeof useSurveySubmissionsLazyQuery>;
export type SurveySubmissionsSuspenseQueryHookResult = ReturnType<typeof useSurveySubmissionsSuspenseQuery>;
export type SurveySubmissionsQueryResult = Apollo.QueryResult<SurveySubmissionsQuery, SurveySubmissionsQueryVariables>;