import React from "react";

import Icon from "./Icon";
import Warning from "./icons/16/warning.svg?react";

export type Props = React.PropsWithChildren &
  React.HTMLAttributes<HTMLParagraphElement>;

export default function InputError(props: Props) {
  const { children, ...rest } = props;
  return (
    <p {...rest} className="form-item__error">
      <Icon svg={Warning} className={"form-item__error__icon"}></Icon>{" "}
      {children}
    </p>
  );
}
