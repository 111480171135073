import { plural } from "@lingui/macro";

export function formatDuration(duration: number): string {
  let translatedDuration;
  let translatedDurationString;

  if (duration >= 365) {
    translatedDuration = Math.floor(duration / 365);
    translatedDurationString = plural(translatedDuration, {
      one: `${translatedDuration} year`,
      other: `${translatedDuration} years`,
    });
  } else if (duration >= 60) {
    translatedDuration = Math.floor(duration / 30);
    translatedDurationString = plural(translatedDuration, {
      one: `${translatedDuration} month`,
      other: `${translatedDuration} months`,
    });
  } else if (duration >= 14) {
    translatedDuration = Math.floor(duration / 7);
    translatedDurationString = plural(translatedDuration, {
      one: `${translatedDuration} week`,
      other: `${translatedDuration} weeks`,
    });
  } else {
    translatedDuration = duration;
    translatedDurationString = plural(translatedDuration, {
      one: `${translatedDuration} day`,
      other: `${translatedDuration} days`,
    });
  }

  return translatedDurationString;
}
