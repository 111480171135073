import type {
  PatientAnswer,
  Question,
} from "../../../graphql/__generated__/globalTypes";
import SurveyQuestion from "../../../shared/SurveyQuestion";

export type Props = {
  surveyQuestions: Question[];
  givenAnswers: PatientAnswer[];
};

export default function SurveySubmissionView(props: Props) {
  const { givenAnswers, surveyQuestions } = props;

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
      }}
      noValidate
    >
      <div className="new-survey-submission-form__questions">
        {surveyQuestions.map((question) => {
          const givenAnswer = givenAnswers.find(
            (answer) => answer.questionExternalId === question.externalId
          );
          return (
            <SurveyQuestion
              key={question.externalId}
              prompt={question.prompt}
              externalId={question.externalId}
              answerType={question.answerType}
              answers={question.answers}
              infoUrl={question.infoUrl}
              answer={givenAnswer}
            />
          );
        })}
      </div>
    </form>
  );
}
