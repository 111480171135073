import { Trans } from "@lingui/macro";

import type { Phase } from "../graphql/__generated__/globalTypes";
import classNames from "../utils/classNames";
import {
  formatEstimatedDateInDefaultTimezone,
  parseDateAndTimeInDefaultTimezone,
} from "../utils/date-time";
import { formatDuration } from "../utils/duration";
import { toLowerKebabCase } from "../utils/string";
import Button from "./Button";
import Icon from "./Icon";
import CaretRight from "./icons/20/caret-right.svg?react";
import PhasePath from "./PhasePath";

export type Props = {
  index: number;
  phase: Phase;
};

export default function PhaseDetails({ index, phase }: Props) {
  const hasAlternatives =
    phase.alternativePhases && phase.alternativePhases.length > 0;
  const estimatedDate = formatEstimatedDateInDefaultTimezone(
    "de",
    parseDateAndTimeInDefaultTimezone(phase.startDate, "00:00")
  );
  return (
    <article
      data-theme={toLowerKebabCase(phase.type)}
      aria-describedby={`phase-name-${phase.id}`}
      className={classNames([
        "care-path-item care-path-item--phase",
        index === 0 && "care-path-item--start",
      ])}
    >
      <div className="care-path-item__indicator" aria-hidden="true">
        <PhasePath confirmed={!phase.optional} />
      </div>
      <div className="care-path-item__body u-h3">
        <h2 id={`phase-name-${phase.id}`} className="u-h2 care-path-item__name">
          {phase.displayName}
        </h2>
        {phase.hasParallelPhases && (
          <p className="care-path-item__parallel">
            <Trans>Parallel with other treatments</Trans>
          </p>
        )}
        <dl className="inline-dl u-fg-grayscale-base">
          {phase.duration > 0 && (
            <div className="inline-dl__item">
              <dt className="u-sr-only">
                <Trans>Duration</Trans>
              </dt>
              <dd>{formatDuration(phase.duration)}</dd>
            </div>
          )}
          {phase.startDate && (
            <div className="inline-dl__item">
              <dt className="u-sr-only">
                <Trans>Start date</Trans>
              </dt>
              <dd>{estimatedDate}</dd>
            </div>
          )}
        </dl>
        {phase.optional && (
          <p className="u-fg-grayscale-base">
            <Trans>Optional</Trans>
          </p>
        )}
        {hasAlternatives && phase.alternativeText && (
          <p className="u-fg-grayscale-base u-font-medium">
            {phase.alternativeText}
          </p>
        )}
        {hasAlternatives && (
          <p className="u-fg-grayscale-base">
            <Trans>Alternatives:</Trans>{" "}
            {phase.alternativePhases
              .map((alternativePhase) => alternativePhase)
              .join(", ")}
          </p>
        )}
      </div>
      <div className="care-path-item__link">
        <Button
          href={phase.infoUrl}
          color="transparent"
          shape="small"
          target="_blank"
          rel="noopener noreferrer"
        >
          <span aria-hidden="true">
            <Trans>Read about this phase</Trans>
          </span>
          <span className="u-sr-only">
            <Trans>Read about the {phase.displayName} phase</Trans>
          </span>
          <Icon svg={CaretRight} size="medium" />
        </Button>
      </div>
    </article>
  );
}
