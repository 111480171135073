import { useEffect, useRef } from "react";

import Icon from "./Icon";
import Check from "./icons/illustrative/check.svg?react";

export type Props = {
  message: string;
  // when this component is rendered in a response to an user-initiated action,
  // autofocus on the error can be used as a way to provide feedback for SR users
  shouldAutofocus?: boolean;
};

function SuccessMessage({ message, shouldAutofocus }: Props) {
  const selfRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    shouldAutofocus && selfRef.current?.focus();
  }, [shouldAutofocus]);

  return (
    <div className="a-content big-message">
      <Icon svg={Check} size="illustrative" />
      <h1 tabIndex={-1} ref={selfRef} className="u-h2">
        {message}
      </h1>
    </div>
  );
}

export default SuccessMessage;
