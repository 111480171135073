import { gql } from "@apollo/client";

const query = gql`
  query me {
    me {
      email
    }
  }
`;
export default query;
