import { i18n } from "@lingui/core";
import { msg } from "@lingui/macro";
import { de, enGB } from "date-fns/locale";
import { formatInTimeZone, toDate } from "date-fns-tz";

export const defaultTimezone = "Europe/Berlin";

export function parseDateAndTimeInDefaultTimezone(
  dateString: string,
  timeString: string
) {
  const dateTimeString = `${dateString}T${timeString}`;
  return parseDatetimeInDefaultTimezone(dateTimeString);
}

export function parseDatetimeInDefaultTimezone(dateTimeString: string) {
  return toDate(dateTimeString, { timeZone: defaultTimezone });
}

export function formatTimeInDefaultTimezone(date: Date) {
  return formatInTimeZone(date, defaultTimezone, "HH:mm");
}

export function formatDateInDefaultTimezone(
  locale: string,
  date: Date,
  formatString?: string
) {
  const dateFnsLocale = locale === "en" ? enGB : de;
  const defaultFormatString = isDateThisYear(date) ? "do MMMM" : "do MMMM yyyy";

  return formatInTimeZone(
    date,
    defaultTimezone,
    formatString || defaultFormatString,
    {
      locale: dateFnsLocale,
    }
  );
}

export function formatEstimatedDateInDefaultTimezone(
  locale: string,
  date: Date
) {
  const day = Number(formatDateInDefaultTimezone(locale, date, "d"));
  const month = formatDateInDefaultTimezone(locale, date, "MMMM");

  const getEstimation = () => {
    if (day < 10) {
      return i18n._(msg`Early`);
    } else if (day >= 10 && day < 20) {
      return i18n._(msg`Mid`);
    }
    return i18n._(msg`Late`);
  };

  const estimatedDate = `${getEstimation()} ${month}`;

  const year = formatDateInDefaultTimezone(locale, date, "yyyy");

  if (isDateThisYear(date)) {
    return estimatedDate;
  } else {
    return `${estimatedDate}, ${year}`;
  }
}

export function isDateThisYear(date: Date) {
  const year = formatInTimeZone(date, defaultTimezone, "yyyy");
  const currentYear = formatInTimeZone(new Date(), defaultTimezone, "yyyy");
  return year === currentYear;
}
