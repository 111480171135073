import { Trans } from "@lingui/macro";
import { useState } from "react";

import { AppointmentState } from "../graphql/__generated__/globalTypes";
import { useUpdateStepAppointmentStateMutation } from "../graphql/__generated__/globalTypes";
import carepath from "../graphql/queries/carepath";
import ButtonWithLoadingIndicator from "./ButtonWithLoadingIndicator";
import Icon from "./Icon";
import WarningIcon from "./icons/16/warning.svg?react";
import CheckCircleFillIcon from "./icons/20/check-circle-fill.svg?react";
import CrossCircleFillIcon from "./icons/20/cross-circle-fill.svg?react";

export type Props = React.HTMLAttributes<HTMLDivElement> & {
  stepId: string;
  appointmentState?: AppointmentState | null;
  disabled?: boolean;
};

const StepAppointmentState = ({
  stepId,
  disabled,
  appointmentState,
  ...rest
}: Props) => {
  const [isAttended, setIsAttended] = useState<boolean>(
    appointmentState === AppointmentState.Attended
  );
  const [isUnattended, setIsUnattended] = useState<boolean>(
    appointmentState === AppointmentState.Unattended
  );
  const [isAttendedLoading, setIsAttendedLoading] = useState<boolean>(false);
  const [isUnattendedLoading, setIsUnattendedLoading] =
    useState<boolean>(false);
  const [updateStepAppointmentState] = useUpdateStepAppointmentStateMutation({
    refetchQueries: [{ query: carepath }],
    onError: console.error,
  });

  const onUnattendedClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    setIsUnattendedLoading(true);
    handleUpdate({
      appointmentState: isUnattended ? undefined : AppointmentState.Unattended,
    });
  };

  const onAttendedClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    setIsAttendedLoading(true);
    handleUpdate({
      appointmentState: isAttended ? undefined : AppointmentState.Attended,
    });
  };

  const handleUpdate = async ({ appointmentState }: Partial<Props>) => {
    const response = await updateStepAppointmentState({
      variables: { stepId, appointmentState },
    });
    const data = response.data;
    if (data) {
      data.updateStepAppointmentState?.appointmentState ===
      AppointmentState.Attended
        ? setIsAttended(true)
        : setIsAttended(false);
      data.updateStepAppointmentState?.appointmentState ===
      AppointmentState.Unattended
        ? setIsUnattended(true)
        : setIsUnattended(false);
    }
    setIsAttendedLoading(false);
    setIsUnattendedLoading(false);
  };

  return (
    <div
      className="step-appointment-state card"
      data-theme={!isAttended ? "highlight" : "white"}
      {...rest}
    >
      <h2>
        <Trans>Did you attend the appointment?</Trans>
      </h2>
      <div>
        <div className="step-appointment-state__buttons o-joined-ui">
          <ButtonWithLoadingIndicator
            color="secondary"
            aria-pressed={isUnattended}
            loading={isUnattendedLoading}
            onClick={onUnattendedClick}
            disabled={disabled}
          >
            {isUnattended && <Icon svg={CrossCircleFillIcon} />}
            <Trans>No</Trans>
          </ButtonWithLoadingIndicator>
          <ButtonWithLoadingIndicator
            color="secondary"
            aria-pressed={isAttended}
            loading={isAttendedLoading}
            onClick={onAttendedClick}
            disabled={disabled}
          >
            {isAttended && <Icon svg={CheckCircleFillIcon} />}
            <Trans>Yes</Trans>
          </ButtonWithLoadingIndicator>
        </div>
        {disabled && (
          <p className="error-message step-appointment-state__notice u-h3">
            <span className="error-message__icon">
              <Icon svg={WarningIcon} size="small" />
            </span>
            <Trans>
              You can only mark appointments as attended or not when they have
              passed.
            </Trans>
          </p>
        )}
        {isUnattended && (
          <p className="error-message step-appointment-state__notice u-h3">
            <span className="error-message__icon">
              <Icon svg={WarningIcon} size="small" />
            </span>
            <Trans>
              When you have agreed a replacement, please edit the appointment
              above.
            </Trans>
          </p>
        )}
      </div>
    </div>
  );
};

export default StepAppointmentState;
