import { msg, Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Link } from "react-router-dom";

import type { Phase, Step } from "../../graphql/__generated__/globalTypes";
import { NotificationType } from "../../graphql/__generated__/globalTypes";
import {
  formatDateInDefaultTimezone,
  formatTimeInDefaultTimezone,
} from "../../utils/date-time";
import Button from "../Button";
import Icon from "../Icon";
import Clock from "../icons/16/clock.svg?react";
import Bell from "../icons/20/bell.svg?react";
import CaretRight from "../icons/20/caret-right.svg?react";
import Close from "../icons/20/close.svg?react";
import Info from "../icons/20/info.svg?react";

export type Props = {
  phase: Phase;
  step: Step;
  type: NotificationType;
  onDismiss: (step: Step, type: NotificationType) => void;
};

export const stepAppointmentStateElementId = "attendance";

export function notificationId(step: Step, type: NotificationType) {
  // cut off milliseconds
  const timestamp = step.appointmentAt?.split(".")[0];
  return `step-notification-${type}-${step.id}-${timestamp}`;
}

export default function Banner(props: Props) {
  const { _, i18n } = useLingui();
  const { type, step, phase, onDismiss } = props;

  const appointmentAtDate = step.appointmentAt
    ? new Date(step.appointmentAt)
    : null;

  let formattedDate = appointmentAtDate
    ? formatDateInDefaultTimezone(i18n.locale, appointmentAtDate)
    : "";
  if (formattedDate === formatDateInDefaultTimezone(i18n.locale, new Date())) {
    formattedDate = _(msg`Today`);
  }

  let heading;
  let icon;
  let to;

  if (type === NotificationType.Appointment) {
    heading = msg`Upcoming appointment`;
    icon = Bell;
    to = `/care-path/${phase.id}/steps/${step.id}`;
  } else if (type === NotificationType.Attendance) {
    heading = msg`Please mark your attendance for this appointment`;
    icon = Info;
    to = `/care-path/${phase.id}/steps/${step.id}#${stepAppointmentStateElementId}`;
  }
  return (
    <article
      aria-describedby={`heading-${notificationId(step, type)}`}
      className="banner"
    >
      <div className="banner__highlight">
        <Icon svg={icon!} />
      </div>
      <div className="banner__text">
        <h2
          id={`heading-${notificationId(step, type)}`}
          className="banner__highlight u-h2"
        >
          {_(heading!)}
        </h2>
        <Link to={to!} className="banner__link">
          <span className="banner__link-text">{step.name}</span>{" "}
          <Icon svg={CaretRight} size={"medium"} />
        </Link>
        <dl className="inline-dl inline-dl--with-icons u-fg-grayscale-base">
          {appointmentAtDate && (
            <>
              <div className="inline-dl__item inline-dl__item--light-weight">
                <dt className="u-sr-only">
                  <Trans>Start date</Trans>
                </dt>
                <dd>{formattedDate}</dd>
              </div>
              <div className="inline-dl__item inline-dl__item--light-weight icon-label">
                <dt>
                  <Icon svg={Clock} size={"small"} />
                  <span className="u-sr-only">
                    <Trans>Start time</Trans>
                  </span>
                </dt>
                <dd>{formatTimeInDefaultTimezone(appointmentAtDate)}</dd>
              </div>
            </>
          )}
        </dl>
      </div>
      <div className="banner__close">
        <Button onClick={() => onDismiss(step, type)} color="transparent">
          <Icon svg={Close} size={"medium"}></Icon>
          <span className="u-sr-only">
            <Trans>Dismiss</Trans>
          </span>
        </Button>
      </div>
    </article>
  );
}
