import { Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";

import type { Phase, Step } from "../graphql/__generated__/globalTypes";
import { AppointmentState } from "../graphql/__generated__/globalTypes";
import classNames from "../utils/classNames";
import {
  formatDateInDefaultTimezone,
  formatEstimatedDateInDefaultTimezone,
  formatTimeInDefaultTimezone,
  parseDateAndTimeInDefaultTimezone,
} from "../utils/date-time";
import { toLowerKebabCase } from "../utils/string";
import Button from "./Button";
import Icon from "./Icon";
import Check from "./icons/16/check.svg?react";
import Clock from "./icons/16/clock.svg?react";
import CaretRight from "./icons/20/caret-right.svg?react";
import Prohibit from "./icons/20/prohibit.svg?react";
import StepPath from "./StepPath";

export type Props = {
  step: Step;
  phase: Phase;
};

export default function Step({ step, phase }: Props) {
  const { i18n } = useLingui();
  const hasAlternatives =
    step.alternativeSteps && step.alternativeSteps.length > 0;
  const appointmentAtDate = step.appointmentAt
    ? new Date(step.appointmentAt)
    : null;
  const estimatedDate = formatEstimatedDateInDefaultTimezone(
    "de",
    parseDateAndTimeInDefaultTimezone(step.startDate, "00:00")
  );

  return (
    <article
      data-theme={toLowerKebabCase(phase.type)}
      aria-describedby={`step-name-${step.id}`}
      className={classNames([
        "care-path-item care-path-item--step",
        step.active && "care-path-item--active",
        !!step.appointmentState && "care-path-item--completed",
      ])}
    >
      <div className="care-path-item__indicator" aria-hidden="true">
        <StepPath
          confirmed={!step.optional}
          attendanceRequired={step.attendanceRequired}
        />
      </div>
      <div className="care-path-item__body">
        <div className="care-path-item__body__title">
          <p className="u-h4 u-fg-grayscale-base">{phase.displayName}</p>
          <h2 id={`step-name-${step.id}`} className="u-h2 care-path-item__name">
            {step.name}
          </h2>
        </div>
        <dl className="u-h3 inline-dl inline-dl--with-icons u-fg-grayscale-base care-path-item__emphasize-when-active">
          {appointmentAtDate && (
            <>
              <div className="inline-dl__item inline-dl__item--light-weight">
                <dt className="u-sr-only">
                  <Trans>Start date</Trans>
                </dt>
                <dd>
                  {formatDateInDefaultTimezone(i18n.locale, appointmentAtDate)}
                </dd>
              </div>
              <div className="inline-dl__item inline-dl__item--light-weight icon-label">
                <dt>
                  <Icon svg={Clock} size={step.active ? "medium" : "small"} />
                  <span className="u-sr-only">
                    <Trans>Start time</Trans>
                  </span>
                </dt>
                <dd>{formatTimeInDefaultTimezone(appointmentAtDate)}</dd>
              </div>
            </>
          )}
          {!step.appointmentAt && step.startDate && (
            <div className="inline-dl__item inline-dl__item--light-weight">
              <dt className="u-sr-only">
                <Trans>Start date</Trans>
              </dt>
              <dd>{estimatedDate}</dd>
            </div>
          )}
          {step.appointmentState === AppointmentState.Attended && (
            <div className="inline-dl__item inline-dl__item--light-weight u-fg-grayscale-light icon-label">
              <dt>
                <Icon svg={Check} />
                <span className="u-sr-only">
                  <Trans>Attendance status</Trans>
                </span>
              </dt>
              <dd className="icon-label">
                <Trans>Attended</Trans>
              </dd>
            </div>
          )}
          {step.appointmentState === AppointmentState.Unattended && (
            <div className="inline-dl__item inline-dl__item--light-weight u-fg-grayscale-light icon-label">
              <dt className="u-sr-only">
                <Trans>Attendance status</Trans>
              </dt>
              <dd className="a-badge" data-theme="inverted">
                <Trans>Did not attend</Trans>
              </dd>
            </div>
          )}
        </dl>
        {step.optional && (
          <p className="u-h3 u-fg-grayscale-base">
            <Trans>Optional</Trans>
          </p>
        )}
        {step.alternativeText && (
          <p className="u-h3 u-fg-grayscale-base u-font-medium">
            {step.alternativeText}
          </p>
        )}
        {hasAlternatives && (
          <p className="care-path-item--step__parallel">
            <Trans>Alternatives:</Trans> {step.alternativeSteps.join(", ")}
          </p>
        )}
        {step.active && step.attendanceRequired && (
          <div className="care-path-item__active-label u-h4">
            <Trans>Next appointment</Trans>
          </div>
        )}
        {step.active && !step.attendanceRequired && (
          <div className="care-path-item__active-label u-h4 icon-label">
            <Icon svg={Prohibit} />
            <div>
              <Trans>Next appointment — attendance not needed</Trans>
            </div>
          </div>
        )}
        {!step.active && !step.attendanceRequired && (
          <div className="icon-label care-path-item--step__parallel">
            <Icon svg={Prohibit} />
            <div>
              <Trans>Attendance not needed</Trans>
            </div>
          </div>
        )}
      </div>
      <div className="care-path-item__link">
        <Button
          to={`/care-path/${phase.id}/steps/${step.id}`}
          color="transparent"
        >
          <span className="u-sr-only">
            <Trans>See details on {step.name}</Trans>
          </span>
          <Icon svg={CaretRight} size="medium" />
        </Button>
      </div>
    </article>
  );
}
