import { Trans } from "@lingui/macro";

import { useLogoutMutation } from "../../graphql/__generated__/globalTypes";
import { getFirstFactorAuthToken } from "../../utils/auth";
import useAfterLogOut from "../../utils/useAfterLogOut";
import type { Props } from "../Button";
import ButtonWithLoadingIndicator from "../ButtonWithLoadingIndicator";
import Icon from "../Icon";
import SignOut from "../icons/20/sign-out.svg?react";

export default function LogOutButton(props: Partial<Props>) {
  const afterLogOut = useAfterLogOut();
  const [logOut, { loading }] = useLogoutMutation({
    onError: console.error,
  });

  const onClick = async () => {
    const result = await logOut();
    const status = result.data?.logout?.status;

    if (status === "ok") {
      await afterLogOut();
    }
  };

  if (getFirstFactorAuthToken()) {
    return (
      <ButtonWithLoadingIndicator
        onClick={onClick}
        loading={loading}
        {...props}
      >
        <Icon svg={SignOut}></Icon>
        <Trans>Log out</Trans>
      </ButtonWithLoadingIndicator>
    );
  } else {
    return null;
  }
}
