import { msg, Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Link } from "react-router-dom";

import IdaChariteLogo from "../../assets/ida-charite-logo.svg?react";
import { defaultRedirectPath } from "../../utils/useRedirectPathSearchParam";
import Button from "../Button";
import Icon from "../Icon";
import Envelope from "../icons/20/envelope.svg?react";
import Key from "../icons/20/key.svg?react";
import LogOutButton from "./LogOutButton";
import Tabs from "./Tabs";

export default function Sidebar() {
  const { _ } = useLingui();
  return (
    <nav className="sidebar">
      <Link to={defaultRedirectPath} className="sidebar__logo">
        <IdaChariteLogo />
        <span className="u-sr-only">
          <Trans>Home</Trans>
        </span>
      </Link>
      <div className="sidebar__tabs">
        <ul className="sidebar__list" role="list">
          <Tabs buttonColor={"verticalTab"} />
        </ul>
      </div>
      <div className="sidebar__links">
        <ul className="sidebar__list" role="list">
          <li role="listitem">
            {/* Fix in IDA-171 */}
            <Button
              color="nav"
              shape="nav"
              href={_(msg`https://dha.bihealth.org/project/ida/`)}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Trans>Clinic information</Trans>
            </Button>
          </li>
          <li role="listitem">
            {/* Fix in IDA-171 */}
            <Button
              color="nav"
              shape="nav"
              href={_(msg`https://dha.bihealth.org/project/ida/`)}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Trans>Privacy policy</Trans>
            </Button>
          </li>
          <li role="listitem">
            {/* Fix in IDA-171 */}
            <Button
              color="nav"
              shape="nav"
              href={_(msg`https://dha.bihealth.org/project/ida/`)}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Trans>Imprint</Trans>
            </Button>
          </li>
        </ul>
        <ul className="sidebar__list" role="list">
          <li role="listitem">
            <Button color="sidebarNav" shape="sidebarNav" to="/email/edit">
              <Icon svg={Envelope}></Icon>
              <Trans>Change email</Trans>
            </Button>
          </li>
          <li role="listitem">
            <Button color="sidebarNav" shape="sidebarNav" to="/password/edit">
              <Icon svg={Key}></Icon>
              <Trans>Change password</Trans>
            </Button>
          </li>
        </ul>
        <ul className="sidebar__list" role="list">
          <li role="listitem">
            {/* there are no public pages with the full layout, so a log in link is not necessary */}
            <LogOutButton color="sidebarNav" shape="sidebarNav" />
          </li>
        </ul>
      </div>
    </nav>
  );
}
