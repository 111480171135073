const firstFactorTokenKey = "ida-webapp-patient-first-factor-auth-token";
export const firstFactorTokenExpiredEvent =
  "ida-webapp-patient-first-factor-auth-token-expired";

export function getFirstFactorAuthToken() {
  return localStorage.getItem(firstFactorTokenKey);
}

export function setFirstFactorAuthToken(token: string) {
  localStorage.setItem(firstFactorTokenKey, token);
}

export function removeFirstFactorAuthToken() {
  localStorage.removeItem(firstFactorTokenKey);
}
