import { msg, Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import {
  ErrorCode,
  useLogInMutation,
} from "../graphql/__generated__/globalTypes";
import Layout from "../shared/Layout";
import {
  getFirstFactorAuthToken,
  setFirstFactorAuthToken,
} from "../utils/auth";
import useAfterLogIn from "../utils/useAfterLogIn";
import useRedirectPathSearchParam from "../utils/useRedirectPathSearchParam";
import type { OnSubmitData } from "./LogInPage/LogInForm";
import LogInForm from "./LogInPage/LogInForm";

export default function LogInPage() {
  const { _ } = useLingui();
  const redirectPath = useRedirectPathSearchParam();
  const onAfterLogIn = useAfterLogIn();
  const navigate = useNavigate();
  const [logIn, { loading, error }] = useLogInMutation({
    onError: console.error,
  });
  const expectedErrorCodes = [ErrorCode.InvalidCredentials];

  const onSubmit = async ({ email, password }: OnSubmitData) => {
    const response = await logIn({ variables: { email, password } });
    const token = response.data?.login?.token;
    if (token) {
      setFirstFactorAuthToken(token);
      await onAfterLogIn();
    }
  };

  useEffect(() => {
    if (getFirstFactorAuthToken()) {
      navigate(redirectPath);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout title={_(msg`Log in to IDA`)} hideTitle={true} hideNav={true}>
      <div className="auth-page a-content a-content--s">
        <h1 tabIndex={-1} className="auth-page__title">
          <Trans>Log in to IDA</Trans>
        </h1>

        <LogInForm
          error={error}
          expectedErrorCodes={expectedErrorCodes}
          loading={loading}
          onSubmit={onSubmit}
        />
      </div>
    </Layout>
  );
}
