import { msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import type { Phase, Step } from "../../graphql/__generated__/globalTypes";
import {
  useEditStepMutation,
  useUpdateStepAppointmentStateMutation,
} from "../../graphql/__generated__/globalTypes";
import carepathQuery from "../../graphql/queries/carepath";
import ErrorMessage from "../../shared/ErrorMessage";
import Layout from "../../shared/Layout";
import LoadingIndicator from "../../shared/LoadingIndicator";
import StepDetailsHeader from "../../shared/StepDetailsHeader";
import { parseDateAndTimeInDefaultTimezone } from "../../utils/date-time";
import useCarePath from "../../utils/useCarePath";
import type { OnSubmitData } from "./AppointmentDetailsEditPage/AppointmentDetailsEditForm";
import AppointmentDetailsEditForm from "./AppointmentDetailsEditPage/AppointmentDetailsEditForm";

export default function AppointmentDetailsEditPage() {
  const { _ } = useLingui();
  const {
    carepath,
    loading: carePathLoading,
    error: carePathError,
  } = useCarePath();
  const [editStep, { loading: editStepLoading, error: editStepError }] =
    useEditStepMutation({
      onError: console.error,
      refetchQueries: [{ query: carepathQuery }],
    });
  const [updateStepAppointmentState] = useUpdateStepAppointmentStateMutation({
    refetchQueries: [{ query: carepathQuery }],
    onError: console.error,
  });

  const { phaseId, stepId } = useParams();
  const navigate = useNavigate();
  const backPath = `/care-path/${phaseId}/steps/${stepId}`;

  const phases: Phase[] = (carepath?.phases as Phase[]) || [];
  const phaseFinder = (p: Phase) => p.id === phaseId;
  const phase = phases.find(phaseFinder);

  const steps: Step[] = (phase?.steps as Step[]) || [];
  const stepFinder = (s: Step) => s.id === stepId;
  const step = steps.find(stepFinder);

  useEffect(() => {
    if (!carePathLoading && !carePathError && (!phase || !step)) {
      navigate(backPath);
    }
  }, [
    carepath,
    phase,
    step,
    backPath,
    navigate,
    carePathError,
    carePathLoading,
  ]);

  const onSubmit = async ({ date, time, appointmentDetails }: OnSubmitData) => {
    let appointmentAt = null;
    if (date && time) {
      appointmentAt = parseDateAndTimeInDefaultTimezone(
        date,
        time
      ).toISOString();
    }
    const editStepResponse = await editStep({
      variables: {
        stepId: stepId!,
        appointmentAt,
        appointmentDetails: appointmentDetails || null,
      },
    });
    const appointmentStateResponse = await updateStepAppointmentState({
      variables: { stepId: stepId!, appointmentState: undefined },
    });

    if (editStepResponse?.data?.editStep && appointmentStateResponse?.data) {
      navigate(backPath);
    }
  };

  return (
    <Layout
      title={_(msg`Step details`)}
      backPath={backPath}
      backLabel={_(msg`Back to step`)}
    >
      <div className="a-content a-content--m step-appointment-details-edit-page">
        {carePathLoading && (
          <span className="loading-indicator-wrapper">
            <LoadingIndicator />
          </span>
        )}
        {carePathError && <ErrorMessage error={carePathError} />}
        {!carePathLoading && !carePathError && phase && step && (
          <>
            <StepDetailsHeader step={step} phase={phase} />
            <AppointmentDetailsEditForm
              step={step}
              cancelPath={backPath}
              onSubmit={onSubmit}
              loading={editStepLoading}
              error={editStepError}
            />
          </>
        )}
      </div>
    </Layout>
  );
}
