import { msg, Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import React, { useState } from "react";

import { useUpdatePatientPasswordMutation } from "../../graphql/__generated__/globalTypes";
import AuthSuccessMessage from "../../shared/AuthSuccessMessage";
import Layout from "../../shared/Layout";
import useAfterLogOut from "../../utils/useAfterLogOut";
import { defaultRedirectPath } from "../../utils/useRedirectPathSearchParam";
import type { OnSubmitData } from "./PasswordEditPage/EditPasswordForm";
import EditPasswordForm from "./PasswordEditPage/EditPasswordForm";

const expectedErrorCodes: string[] = [];

export default function EditPasswordPage(): React.ReactElement {
  const { _ } = useLingui();
  const [updated, setUpdated] = useState<boolean>(false);
  const backPath = updated ? undefined : defaultRedirectPath;
  const afterLogOut = useAfterLogOut({ redirect: false });

  const [updatePatientPassword, { loading, error }] =
    useUpdatePatientPasswordMutation({
      onError: console.error,
    });

  const onSubmit = async ({
    currentPassword,
    password,
    passwordConfirmation,
  }: OnSubmitData) => {
    const response = await updatePatientPassword({
      variables: { currentPassword, password, passwordConfirmation },
    });
    if (response?.data?.updatePatientPassword?.id) {
      setUpdated(true);
      afterLogOut();
    }
  };

  return (
    <Layout
      title={_(msg`Change password`)}
      hideTitle={true}
      hideNav={true}
      backPath={backPath}
    >
      <div className="auth-page a-content a-content--s">
        {updated ? (
          <AuthSuccessMessage
            message={_(
              msg`Your password has been changed successfully. Please log in again with your new password.`
            )}
          />
        ) : (
          <>
            <h1 tabIndex={-1} className="auth-page__title">
              <Trans>Change password</Trans>
            </h1>
            <EditPasswordForm
              error={error}
              expectedErrorCodes={expectedErrorCodes}
              loading={loading}
              onSubmit={onSubmit}
            />
          </>
        )}
      </div>
    </Layout>
  );
}
