import { Trans } from "@lingui/macro";

import type { Phase } from "../graphql/__generated__/globalTypes";
import classNames from "../utils/classNames";
import { toLowerKebabCase } from "../utils/string";
import Button from "./Button";
import Icon from "./Icon";
import CaretRight from "./icons/20/caret-right.svg?react";
import PhasePath from "./PhasePath";

export type Props = {
  index: number;
  phase: Phase;
};

export default function Phase({ index, phase }: Props) {
  return (
    <article
      data-theme={toLowerKebabCase(phase.type)}
      aria-describedby={`phase-name-${phase.id}`}
      className={classNames([
        "care-path-item care-path-item--phase",
        index === 0 && "care-path-item--start",
        phase.active && "care-path-item--active",
      ])}
    >
      <div className="care-path-item__indicator" aria-hidden="true">
        <PhasePath confirmed={!phase.optional} />
      </div>
      <div className="care-path-item__body">
        <h2 id={`phase-name-${phase.id}`} className="care-path-item__name">
          {phase.displayName}
        </h2>
        {phase.active && (
          <p className="u-sr-only">
            <Trans>Active phase</Trans>
          </p>
        )}
        {phase.optional && (
          <p className="u-h3 u-fg-grayscale-base">
            <Trans>Optional</Trans>
          </p>
        )}
        {phase.hasParallelPhases && (
          <p className="care-path-item__parallel">
            <Trans>Parallel with other treatments</Trans>
          </p>
        )}
      </div>
      <div className="care-path-item__link">
        <Button to={`/care-path/${phase.id}`} color="transparent" shape="small">
          <span aria-hidden="true">
            <Trans>Details</Trans>
          </span>
          <span className="u-sr-only">
            <Trans>Details of the {phase.displayName} phase</Trans>
          </span>
          <Icon svg={CaretRight} size="medium" />
        </Button>
      </div>
    </article>
  );
}
