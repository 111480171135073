import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import ErrorBoundary from "./appsignal/ErrorBoundary";
import { SurveyType } from "./graphql/__generated__/globalTypes";
import type Client from "./graphql/Client";
import GraphQLProvider from "./graphql/Provider";
import I18nProvider from "./i18n/Provider";
import ConfirmAccountPage from "./pages/Account/ConfirmAccountPage";
import EmailConfirmPage from "./pages/Account/EmailConfirmPage";
import EmailEditPage from "./pages/Account/EmailEditPage";
import PasswordEditPage from "./pages/Account/PasswordEditPage";
import PasswordResetEditPage from "./pages/Account/PasswordResetEditPage";
import PasswordResetNewPage from "./pages/Account/PasswordResetNewPage";
import AppointmentDetailsEditPage from "./pages/CarePath/AppointmentDetailsEditPage";
import CarePathPage from "./pages/CarePath/CarePathPage";
import PhaseDetailsPage from "./pages/CarePath/PhaseDetailsPage";
import StepDetailsPage from "./pages/CarePath/StepDetailsPage";
import StepNotesEditPage from "./pages/CarePath/StepNotesEditPage";
import ErrorPage from "./pages/ErrorPage";
import LogInPage from "./pages/LogInPage";
import NewSurveySubmissionPage from "./pages/Survey/NewSurveySubmissionPage";
import SurveyPage from "./pages/Survey/SurveyPage";
import SurveySubmissionPage from "./pages/Survey/SurveySubmissionPage";
import { AuthEventHandler } from "./shared/AuthEventHandler";
import { FocusHandler } from "./shared/FocusHandler";
import ProtectedRoute from "./shared/ProtectedRoute";

export type Props = { client: Client };

function App({ client }: Props) {
  return (
    <HelmetProvider>
      <GraphQLProvider client={client}>
        <I18nProvider>
          <BrowserRouter basename={process.env.VITE_PUBLIC_URL}>
            <ErrorBoundary fallback={ErrorPage}>
              <AuthEventHandler />
              <FocusHandler />
              <Routes>
                <Route path="/" element={<Navigate to="/care-path" />} />
                <Route path="/log-in" element={<LogInPage />} />
                <Route
                  path="/account/confirm"
                  element={<ConfirmAccountPage />}
                />
                <Route
                  path="/password-reset/new"
                  element={<PasswordResetNewPage />}
                />
                <Route
                  path="/password-reset/edit"
                  element={<PasswordResetEditPage />}
                />
                <Route element={<ProtectedRoute />}>
                  <Route path="/email/edit" element={<EmailEditPage />} />
                  <Route path="/email/confirm" element={<EmailConfirmPage />} />
                  <Route path="/password/edit" element={<PasswordEditPage />} />
                  <Route path="/care-path" element={<CarePathPage />} />
                  <Route
                    path="/care-path/:phaseId"
                    element={<PhaseDetailsPage />}
                  />
                  <Route
                    path="/care-path/:phaseId/steps/:stepId"
                    element={<StepDetailsPage />}
                  />
                  <Route
                    path="/care-path/:phaseId/steps/:stepId/appointment"
                    element={<AppointmentDetailsEditPage />}
                  />
                  <Route
                    path="/care-path/:phaseId/steps/:stepId/private-notes"
                    element={<StepNotesEditPage />}
                  />
                  <Route
                    path="/monitoring"
                    element={<SurveyPage surveyType={SurveyType.Monitoring} />}
                  />
                  <Route
                    path="/monitoring/new"
                    element={
                      <NewSurveySubmissionPage
                        surveyType={SurveyType.Monitoring}
                      />
                    }
                  />
                  <Route
                    path="/monitoring/:submissionId"
                    element={
                      <SurveySubmissionPage
                        surveyType={SurveyType.Monitoring}
                      />
                    }
                  />
                  <Route
                    path="/post-operative"
                    element={
                      <SurveyPage surveyType={SurveyType.PostOperative} />
                    }
                  />
                  <Route
                    path="/post-operative/new"
                    element={
                      <NewSurveySubmissionPage
                        surveyType={SurveyType.PostOperative}
                      />
                    }
                  />
                  <Route
                    path="/post-operative/:submissionId"
                    element={
                      <SurveySubmissionPage
                        surveyType={SurveyType.PostOperative}
                      />
                    }
                  />
                </Route>
              </Routes>
            </ErrorBoundary>
          </BrowserRouter>
        </I18nProvider>
      </GraphQLProvider>
    </HelmetProvider>
  );
}
export default App;
