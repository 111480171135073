import { msg, Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import {
  ErrorCode,
  useResetPasswordMutation,
} from "../../graphql/__generated__/globalTypes";
import AuthSuccessMessage from "../../shared/AuthSuccessMessage";
import Layout from "../../shared/Layout";
import { defaultRedirectPath } from "../../utils/useRedirectPathSearchParam";
import type { OnSubmitData } from "./SetPasswordForm";
import SetPasswordForm from "./SetPasswordForm";

export default function PasswordResetEditPage() {
  const { _ } = useLingui();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const token = searchParams.get("token");

  useEffect(() => {
    if (!token) {
      navigate(defaultRedirectPath);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [successfullyChanged, setSuccessfullyChanged] =
    useState<boolean>(false);
  const [resetPassword, { loading, error }] = useResetPasswordMutation({
    onError: console.error,
  });

  const expectedErrorCodes: string[] = [ErrorCode.TokenInvalidOrExpired];

  const onSubmit = async ({ password, passwordConfirmation }: OnSubmitData) => {
    const response = await resetPassword({
      variables: { password, passwordConfirmation, resetToken: token! },
    });

    if (response?.data?.resetPassword?.status === "ok") {
      setSuccessfullyChanged(true);
    }
  };

  let pageContent;

  if (successfullyChanged) {
    pageContent = (
      <AuthSuccessMessage
        message={_(
          msg`Your password has been changed. You must now log in again.`
        )}
      />
    );
  } else {
    pageContent = (
      <>
        <div className="auth-page__title">
          <h1>
            <Trans>Choose a new password</Trans>
          </h1>
        </div>

        <SetPasswordForm
          error={error}
          expectedErrorCodes={expectedErrorCodes}
          loading={loading}
          onSubmit={onSubmit}
        />
      </>
    );
  }

  return (
    <Layout
      title={_(msg`Choose a new password`)}
      hideTitle={true}
      hideNav={true}
    >
      <div className="auth-page a-content a-content--s">{pageContent}</div>
    </Layout>
  );
}
