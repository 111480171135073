import Appsignal from "@appsignal/javascript";
import { plugin as windowEventsPlugin } from "@appsignal/plugin-window-events";

import APP_CONFIG from "../app.config";

// TODO: figure out how deployments will work and publish source maps to appsignal then
const appsignal = new Appsignal({
  key: APP_CONFIG.VITE_APPSIGNAL_KEY,
  revision: APP_CONFIG.VITE_APP_REVISION,
  ignoreErrors: [
    /Can't find variable: __gCrWeb/, // ignores error introduced by a chrome extension on ios
  ],
});

appsignal.use(windowEventsPlugin({}));

export default appsignal;
