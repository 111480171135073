import { msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import React from "react";
import { useNavigate } from "react-router-dom";

import {
  SurveyType,
  useCreateSurveySubmissionMutation,
  useSurveyQuestionsQuery,
} from "../../graphql/__generated__/globalTypes";
import surveySubmissions from "../../graphql/queries/surveySubmissions";
import ErrorMessage from "../../shared/ErrorMessage";
import Layout from "../../shared/Layout";
import LoadingIndicator from "../../shared/LoadingIndicator";
import type { OnSubmitData } from "./NewSurveySubmissionPage/NewSurveySubmissionForm";
import NewSurveySubmissionForm from "./NewSurveySubmissionPage/NewSurveySubmissionForm";

export type Props = {
  surveyType: SurveyType;
};

export default function NewSurveySubmissionPage(props: Props) {
  const { surveyType } = props;
  const { _ } = useLingui();
  const navigate = useNavigate();
  const { data, error, loading } = useSurveyQuestionsQuery({
    variables: { surveyType },
  });
  const [
    createSurveySubmission,
    { error: createError, loading: createLoading },
  ] = useCreateSurveySubmissionMutation({
    onError: console.error,
    refetchQueries: [{ query: surveySubmissions, variables: { surveyType } }],
  });

  const backPath =
    surveyType === SurveyType.Monitoring ? "/monitoring" : "/post-operative";

  const onSubmit = async ({ answers }: OnSubmitData) => {
    const response = await createSurveySubmission({
      variables: { answers, surveyType },
    });

    if (response?.data?.createSurveySubmission?.insertedAt) {
      navigate(backPath);
    }
  };

  const title =
    surveyType === SurveyType.Monitoring
      ? _(msg`Symptoms`)
      : _(msg`Symptoms OP`);

  const pageContent = (
    <NewSurveySubmissionForm
      surveyQuestions={data?.surveyQuestions || []}
      loading={createLoading}
      error={createError}
      onSubmit={onSubmit}
    />
  );

  return (
    <Layout title={title}>
      <div className="new-survey-submission-page a-content a-content--m a-content--tight">
        {loading && (
          <span className="loading-indicator-wrapper">
            <LoadingIndicator />
          </span>
        )}
        {error && <ErrorMessage error={error} />}
        {!loading && !error && pageContent}
      </div>
    </Layout>
  );
}
