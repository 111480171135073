import React from "react";
import { Navigate, Outlet } from "react-router-dom";

import { getFirstFactorAuthToken } from "../utils/auth";
import useCurrentLocation from "../utils/useCurrentLocation";

function ProtectedRoute(): React.ReactElement {
  const currentLocation = useCurrentLocation();

  // Checking that an auth token was set doesn't really verify that a user is logged in,
  // anyone can set the token to anything.
  // If the token is invalid, the user will access the page
  // and then hit an error on the first API request that requires a valid token.
  // <AuthEventHandler/> will then redirect the user to the log in page.
  //
  // This component exists not for security reasons,
  // but to improve the UX of first time visitors shortening the time
  // and number of re-renders they will experience.
  if (getFirstFactorAuthToken()) return <Outlet />;

  const currentPath = encodeURIComponent(currentLocation);
  const redirectPath = `/log-in?redirectPath=${currentPath}`;

  return <Navigate to={redirectPath} replace />;
}

export default ProtectedRoute;
