import { msg, plural } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { useRef, useState } from "react";

import type {
  NotificationType,
  Step,
  Submission,
} from "../../graphql/__generated__/globalTypes";
import {
  SurveyType,
  useNotificationsQuery,
  useSurveySubmissionsQuery,
} from "../../graphql/__generated__/globalTypes";
import useCarePath from "../../utils/useCarePath";
import ErrorMessage from "../ErrorMessage";
import type { Props as BannerProps } from "./Banner";
import Banner, { notificationId } from "./Banner";
import type { Props as BannerSubmissionProps } from "./BannerSubmission";
import BannerSubmission, {
  notificationId as notificationIdSubmission,
} from "./BannerSubmission";

const keyPrefix = "ida-webapp-notification-dismissed";

function wasNotificationDismissed(step: Step, type: NotificationType) {
  return (
    localStorage.getItem(`${keyPrefix}-${notificationId(step, type)}`) ===
    "true"
  );
}

function dismissNotification(step: Step, type: NotificationType) {
  localStorage.setItem(`${keyPrefix}-${notificationId(step, type)}`, "true");
}

function wasNotificationDismissedSubmission(submission: Submission) {
  return (
    localStorage.getItem(
      `${keyPrefix}-${notificationIdSubmission(submission)}`
    ) === "true"
  );
}

function dismissNotificationSubmission(submission: Submission) {
  localStorage.setItem(
    `${keyPrefix}-${notificationIdSubmission(submission)}`,
    "true"
  );
}

export default function Notifications() {
  const { _ } = useLingui();
  const { data, error } = useNotificationsQuery({
    fetchPolicy: "cache-and-network",
  });
  const { carepath } = useCarePath();
  const { data: monitoringSurveySubmissionsData } = useSurveySubmissionsQuery({
    variables: { surveyType: SurveyType.Monitoring },
  });
  const { data: postOperativeSurveySubmissionsData } =
    useSurveySubmissionsQuery({
      variables: { surveyType: SurveyType.PostOperative },
    });
  const selfRef = useRef<HTMLUListElement>(null);

  // we need to keep state because setting local storage doesn't rerender react components
  const [newlyDismissed, setNewlyDismissed] = useState<string[]>([]);
  const [dismissedCount, setDismissedCount] = useState<number>(0);
  const dismissedCountTimeout = useRef<NodeJS.Timeout | null>(null);

  if (!carepath) {
    return null;
  }

  const notifications = data?.notifications || [];
  let alarmingSubmissions: Submission[] = [];

  if (monitoringSurveySubmissionsData?.surveySubmissions) {
    const mergedSubmissions: Submission[] = (
      monitoringSurveySubmissionsData?.surveySubmissions || []
    ).concat(postOperativeSurveySubmissionsData?.surveySubmissions || []);

    alarmingSubmissions = mergedSubmissions
      .filter((surveySubmission: Submission) => {
        if (surveySubmission.alarming) return surveySubmission;
      })
      .sort(
        (a, b) =>
          new Date(b.insertedAt).getTime() - new Date(a.insertedAt).getTime()
      );
  }

  const onDismiss: BannerProps["onDismiss"] = (step, type) => {
    dismissNotification(step, type);
    setNewlyDismissed([...newlyDismissed, notificationId(step, type)]);
    if (dismissedCountTimeout.current) {
      clearTimeout(dismissedCountTimeout.current!);
    }
    setDismissedCount(dismissedCount + 1);
    selfRef.current?.focus();
    dismissedCountTimeout.current = setTimeout(
      () => setDismissedCount(0),
      5000
    );
  };

  const ariaLiveRegion = (
    <div className="u-sr-only" aria-live="polite" aria-atomic="true">
      {dismissedCount > 0
        ? plural(dismissedCount, {
            one: `${dismissedCount} notification dismissed`,
            other: `${dismissedCount} notifications dismissed`,
          })
        : null}
    </div>
  );

  const banners =
    notifications
      ?.map((notification) => {
        const step =
          carepath &&
          carepath.phases
            .flatMap((phase) => phase.steps)
            .find((step) => step.id === notification.stepId);
        const phase = carepath.phases.find((phase) =>
          phase.steps.find((step) => step.id === notification.stepId)
        );
        return (
          step &&
          phase &&
          !newlyDismissed.includes(notificationId(step, notification.type)) &&
          !wasNotificationDismissed(step, notification.type) && (
            <li key={notificationId(step, notification.type)} role="listitem">
              <Banner
                type={notification.type}
                phase={phase}
                step={step}
                onDismiss={onDismiss}
              />
            </li>
          )
        );
      })
      .filter((b) => !!b) || [];

  const onDismissSubmission: BannerSubmissionProps["onDismiss"] = (
    submission
  ) => {
    dismissNotificationSubmission(submission);
    setNewlyDismissed([
      ...newlyDismissed,
      notificationIdSubmission(submission),
    ]);
    if (dismissedCountTimeout.current) {
      clearTimeout(dismissedCountTimeout.current!);
    }
    setDismissedCount(dismissedCount + 1);
    selfRef.current?.focus();
    dismissedCountTimeout.current = setTimeout(
      () => setDismissedCount(0),
      5000
    );
  };
  alarmingSubmissions.every((submission) => {
    if (
      !newlyDismissed.includes(notificationIdSubmission(submission)) &&
      !wasNotificationDismissedSubmission(submission)
    ) {
      banners.push(
        <li key={submission.id} role="listitem">
          <BannerSubmission
            submission={submission}
            onDismiss={onDismissSubmission}
          />
        </li>
      );
      return false;
    }

    return true;
  });

  if (!error && banners.length === 0 && dismissedCount === 0) {
    return null;
  }

  if (error) {
    return (
      <div
        role="region"
        aria-label={_(msg`Notifications`)}
        className="notifications"
      >
        <ErrorMessage message={_(msg`Notifications could not be loaded.`)} />
      </div>
    );
  }

  if (banners.length > 0) {
    return (
      <>
        <ul
          role="list"
          aria-label={_(msg`Notifications`)}
          className="u-list-none notifications"
          ref={selfRef}
          tabIndex={-1}
        >
          {banners}
        </ul>
        {ariaLiveRegion}
      </>
    );
  } else {
    // leave live region around so that dismissing the last notification will still get announced
    return ariaLiveRegion;
  }
}
