import { msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import useCarePath from "../..//utils/useCarePath";
import type { Phase } from "../../graphql/__generated__/globalTypes";
import { useEditStepNotesMutation } from "../../graphql/__generated__/globalTypes";
import carepathQuery from "../../graphql/queries/carepath";
import ErrorMessage from "../../shared/ErrorMessage";
import Layout from "../../shared/Layout";
import LoadingIndicator from "../../shared/LoadingIndicator";
import StepDetailsHeader from "../../shared/StepDetailsHeader";
import type { OnSubmitData } from "./StepNotesEditPage/StepNotesForm";
import StepNotesForm from "./StepNotesEditPage/StepNotesForm";

export default function StepNotesEditPage(): React.ReactElement {
  const { _ } = useLingui();
  const { carepath, loading, error } = useCarePath();
  const { phaseId, stepId } = useParams();
  const phases: Phase[] = (carepath?.phases as Phase[]) || [];
  const finder = (p: Phase) => p.id === phaseId;
  const phase = phases.find(finder);
  const step = phase?.steps?.find((step) => step.id === stepId);
  const navigate = useNavigate();
  const backPath = `/care-path/${phaseId}/steps/${stepId}`;

  const [editStepNotes, { loading: notesLoading, error: notesError }] =
    useEditStepNotesMutation({
      onError: console.error,
    });

  useEffect(() => {
    if (!loading && !error && !step) {
      navigate(backPath);
    }
  });

  const onSubmit = async ({ privateNotes }: OnSubmitData) => {
    const response = await editStepNotes({
      variables: { privateNotes, stepId: step!.id },
      refetchQueries: [carepathQuery],
    });

    if (response?.data?.editStepNotes) {
      navigate(backPath);
    }
  };

  return (
    <Layout
      title={_(msg`Step details`)}
      backPath={backPath}
      backLabel={_(msg`Back to step`)}
    >
      <div className="a-content a-content--m step-appointment-details-edit-page">
        {loading && (
          <span className="loading-indicator-wrapper">
            <LoadingIndicator />
          </span>
        )}
        {error && <ErrorMessage error={error} />}
        {!loading && !error && step && (
          <>
            <StepDetailsHeader step={step} phase={phase!} />
            <StepNotesForm
              loading={notesLoading}
              error={notesError}
              currentPrivateNotes={step.privateNotes}
              expectedErrorCodes={[]}
              cancelPath={backPath}
              onSubmit={onSubmit}
            />
          </>
        )}
      </div>
    </Layout>
  );
}
