import type { ApolloError } from "@apollo/client";
import { Trans } from "@lingui/macro";
import React, { useEffect, useRef, useState } from "react";

import type {
  Question,
  SubmissionAnswer,
} from "../../../graphql/__generated__/globalTypes";
import ButtonWithLoadingIndicator from "../../../shared/ButtonWithLoadingIndicator";
import FormError from "../../../shared/FormError";
import Icon from "../../../shared/Icon";
import PaperPlaneRight from "../../../shared/icons/20/paper-plane-right.svg?react";
import SurveyQuestion from "../../../shared/SurveyQuestion";
export type OnSubmitData = { answers: SubmissionAnswer[] };

export type Props = {
  surveyQuestions: Question[];
  loading: boolean;
  error?: ApolloError;
  expectedErrorCodes?: string[];
  onSubmit: (data: OnSubmitData) => void;
};

type answerValidation = {
  questionExternalId: string;
  invalid: boolean;
};

function isValidAnswer(answer: number | undefined) {
  if (typeof answer !== "number" || isNaN(answer)) return false;
  return true;
}

export default function NewSurveySubmissionForm(props: Props) {
  const { error, loading, expectedErrorCodes, surveyQuestions } = props;
  const form = useRef<HTMLFormElement>(null);
  const initialAnswers: answerValidation[] = surveyQuestions.map(
    (question) => ({
      questionExternalId: question.externalId,
      invalid: false,
    })
  );
  const [answers, setAnswers] = useState<answerValidation[]>(initialAnswers);
  const [hasSubmitted, setHasSubmitted] = useState<boolean>(false);

  useEffect(() => {
    if (hasSubmitted) {
      const firstInvalid = form.current?.querySelector<HTMLInputElement>(
        "[aria-invalid='true']"
      );
      if (firstInvalid) firstInvalid.focus();
      setHasSubmitted(false);
    }
  }, [hasSubmitted]);

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const data = new FormData(form.current!);
    const newAnswers = Array.from(data.entries()).map((tuple) => ({
      questionExternalId: tuple[0],
      value: parseInt(tuple[1] as string, 10),
    }));

    // We’re assuming all questions are required
    if (newAnswers.length === surveyQuestions.length) {
      props.onSubmit({ answers: newAnswers });
    } else {
      setHasSubmitted(true);
      setAnswers(
        newAnswers.map((answer) => ({
          questionExternalId: answer.questionExternalId,
          invalid: !isValidAnswer(answer.value),
        }))
      );
    }
  };

  const onAnswerChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let answerExists = false;
    const newAnswers = answers.map((answer) => {
      if (answer.questionExternalId === e.target.name && e.target.checked) {
        answerExists = true;
        return {
          questionExternalId: answer.questionExternalId,
          invalid: false,
        };
      }
      return answer;
    });

    if (answerExists) {
      setAnswers(newAnswers);
    } else {
      setAnswers([
        ...newAnswers,
        {
          questionExternalId: e.target.name,
          invalid: false,
        },
      ]);
    }
  };

  return (
    <form
      onSubmit={onSubmit}
      className="new-survey-submission-form page-with-actions"
      ref={form}
      noValidate
    >
      <div className="new-survey-submission-form__questions">
        <FormError
          error={error}
          expectedErrorCodes={expectedErrorCodes}
          validationErrorIsUnexpected={true}
        />

        {surveyQuestions.map((question) => {
          const validationAnswer = answers.find(
            (answer) => answer.questionExternalId === question.externalId
          );
          const hasValidationError =
            !validationAnswer || validationAnswer.invalid ? true : false;
          return (
            <SurveyQuestion
              key={question.externalId}
              prompt={question.prompt}
              externalId={question.externalId}
              answerType={question.answerType}
              answers={question.answers}
              infoUrl={question.infoUrl}
              error={hasValidationError}
              onChange={onAnswerChange}
            />
          );
        })}
      </div>

      <div className="new-survey-submission-form__actions page-with-actions__actions  page-with-actions__actions--inline@l">
        <ButtonWithLoadingIndicator
          type="submit"
          loading={loading}
          data-testid="submit-new-survey-submission"
        >
          <Trans>Submit answers</Trans>
          <Icon svg={PaperPlaneRight} />
        </ButtonWithLoadingIndicator>
      </div>
    </form>
  );
}
