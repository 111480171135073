import { Trans } from "@lingui/macro";
import { useLocation } from "react-router-dom";

import type { Props as ButtonProps } from "../Button";
import Button from "../Button";
import Icon from "../Icon";
import Clipboard from "../icons/20/clipboard.svg?react";
import ClipboardFilled from "../icons/20/clipboard-filled.svg?react";
import Hospital from "../icons/20/hospital.svg?react";
import HospitalFilled from "../icons/20/hospital-filled.svg?react";
import Signpost from "../icons/20/signpost.svg?react";
import SignpostFilled from "../icons/20/signpost-filled.svg?react";

export type Props = {
  buttonColor: ButtonProps["color"];
};

export default function Tabs({ buttonColor }: Props) {
  const { pathname } = useLocation();

  return (
    <>
      <li role="listitem">
        <Button
          color={buttonColor}
          to="/care-path"
          aria-current={pathname.startsWith("/care-path") ? "page" : undefined}
        >
          <Icon
            svg={pathname.startsWith("/care-path") ? SignpostFilled : Signpost}
          />
          <Trans>Care Path</Trans>
        </Button>
      </li>
      <li role="listitem">
        <Button
          color={buttonColor}
          to="/monitoring"
          aria-current={pathname.startsWith("/monitoring") ? "page" : undefined}
        >
          <Icon
            svg={
              pathname.startsWith("/monitoring") ? ClipboardFilled : Clipboard
            }
          />
          <Trans>Symptoms</Trans>
        </Button>
      </li>
      <li role="listitem">
        <Button
          color={buttonColor}
          to="/post-operative"
          aria-current={
            pathname.startsWith("/post-operative") ? "page" : undefined
          }
        >
          <Icon
            svg={
              pathname.startsWith("/post-operative") ? HospitalFilled : Hospital
            }
          />
          <Trans>Symptoms OP</Trans>
        </Button>
      </li>
    </>
  );
}
