import { msg, Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import React, { useEffect, useRef } from "react";

import {
  SurveyType,
  useSurveySubmissionsQuery,
} from "../../graphql/__generated__/globalTypes";
import ErrorMessage from "../../shared/ErrorMessage";
import Layout from "../../shared/Layout";
import LoadingIndicator from "../../shared/LoadingIndicator";
import SurveyPrompt from "../../shared/SurveyPrompt";
import SurveySubmissionList from "../../shared/SurveySubmissionList";

export type Props = {
  surveyType: SurveyType;
};

export default function SurveyPage(props: Props) {
  const { surveyType } = props;
  const { _ } = useLingui();
  const anchor = useRef<HTMLDivElement>(null);
  const { data, loading, error } = useSurveySubmissionsQuery({
    variables: { surveyType },
  });
  const title =
    surveyType === SurveyType.Monitoring
      ? _(msg`Symptoms`)
      : _(msg`Symptoms OP`);

  useEffect(() => {
    if (!loading && !error && data) {
      anchor.current?.scrollIntoView();
    }
  }, [loading, error, data]);

  const surveySubmissions = data?.surveySubmissions || [];

  const pageContent = (
    <>
      <div className="survey-page__submissions">
        <h2 className="survey-page__submissions__heading u-h2">
          <Trans>Past submissions</Trans>
        </h2>
        <SurveySubmissionList
          submissions={surveySubmissions}
          surveyType={surveyType}
        />
        <div ref={anchor}></div>
      </div>
      <div className="survey-page__prompt a-content a-content--m a-content--tight">
        <SurveyPrompt surveyType={surveyType} />
      </div>
    </>
  );

  return (
    <Layout title={title}>
      <div className="survey-page">
        {(loading || error) && (
          <div className="a-content a-content--m a-content--tight">
            {loading && (
              <span className="loading-indicator-wrapper">
                <LoadingIndicator />
              </span>
            )}
            {error && <ErrorMessage error={error} />}
          </div>
        )}
        {!loading && !error && pageContent}
      </div>
    </Layout>
  );
}
