import classNames from "../utils/classNames";

export type Props = {
  attendanceRequired?: boolean;
  confirmed?: boolean;
};

const StepPath = ({ attendanceRequired, confirmed }: Props) => {
  return (
    <svg
      viewBox="0 0 20 20"
      width="20"
      height="20"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames([
        "step-path",
        confirmed && `step-path--confirmed`,
        attendanceRequired && `step-path--attendance-required`,
      ])}
    >
      <path
        d="m10 1.5c4.69 0 8.5 3.81 8.5 8.5 0 4.69-3.81 8.5-8.5 8.5-4.69 0-8.5-3.81-8.5-8.5 0-4.69 3.81-8.5 8.5-8.5z"
        className="step-path__outer-pill"
      />
      <path
        d="m5 10c0-2.76 2.24-5 5-5s5 2.24 5 5-2.24 5-5 5-5-2.24-5-5z"
        className="step-path__inner-pill"
      />
    </svg>
  );
};

export default StepPath;
