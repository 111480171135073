import { useApolloClient } from "@apollo/client";
import { useNavigate } from "react-router-dom";

import { removeFirstFactorAuthToken } from "./auth";
import useCurrentLocation from "./useCurrentLocation";

export type Props = {
  redirect: boolean;
};

function useAfterLogOut(props: Props = { redirect: true }) {
  const currentLocation = useCurrentLocation();
  const navigate = useNavigate();
  const client = useApolloClient();
  const { redirect } = props;

  async function afterLogOut(redirectPath?: string) {
    removeFirstFactorAuthToken();
    await client.clearStore();
    if (redirect) {
      const defaultRedirectPath = `/log-in?redirectPath=${encodeURIComponent(currentLocation)}`;
      navigate(redirectPath || defaultRedirectPath);
    }
  }

  return afterLogOut;
}

export default useAfterLogOut;
