import { msg, Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import React, { useState } from "react";

import { useSendResetPasswordEmailMutation } from "../../graphql/__generated__/globalTypes";
import Layout from "../../shared/Layout";
import SuccessMessage from "../../shared/SuccessMessage";
import type { OnSubmitData } from "./PasswordResetNewPage/PasswordResetNewForm";
import PasswordResetNewForm from "./PasswordResetNewPage/PasswordResetNewForm";

export default function PasswordResetNewPage() {
  const { _ } = useLingui();
  const [successfullyRequested, setSuccessfullyRequested] =
    useState<boolean>(false);
  const [requestEmail, setRequestEmail] = useState<string | null>(null);
  const [sendResetPasswordEmail, { loading, error }] =
    useSendResetPasswordEmailMutation({
      onError: console.error,
    });

  const expectedErrorCodes: string[] = [];

  const onSubmit = async ({ email }: OnSubmitData) => {
    const response = await sendResetPasswordEmail({ variables: { email } });

    if (response?.data?.sendResetPasswordEmail?.status === "ok") {
      setRequestEmail(email);
      setSuccessfullyRequested(true);
    }
  };

  let pageContent;

  if (successfullyRequested) {
    pageContent = (
      <SuccessMessage
        shouldAutofocus={true}
        message={_(
          msg`A password reset link has been sent to ${requestEmail} if an account with this email address exists.`
        )}
      />
    );
  } else {
    pageContent = (
      <>
        <div className="auth-page__title">
          <h1>
            <Trans>Forgot password?</Trans>
          </h1>

          <p className="u-h3">
            <Trans>Request a reset password link.</Trans>
          </p>
        </div>

        <PasswordResetNewForm
          error={error}
          expectedErrorCodes={expectedErrorCodes}
          loading={loading}
          onSubmit={onSubmit}
        />
      </>
    );
  }

  return (
    <Layout title={_(msg`Forgot password?`)} hideTitle={true} hideNav={true}>
      <div className="auth-page a-content a-content--s">{pageContent}</div>
    </Layout>
  );
}
