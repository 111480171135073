import type { GraphQLErrors } from "@apollo/client/errors";

export function findExpectedError(
  errors: GraphQLErrors | undefined,
  codes: string[]
) {
  const relevantErrors = (errors || []).filter((error) => {
    if ("code" in error) {
      const code = error.code as string;
      return codes.includes(code);
    }

    return false;
  });

  if (relevantErrors.length > 0) {
    return relevantErrors[0];
  }
}
