import type { ApolloClient } from "@apollo/client";
import { ApolloProvider } from "@apollo/client";
import React from "react";

export type Props = React.PropsWithChildren<{ client: ApolloClient<unknown> }>;

function Provider({ client, children, ...props }: Props): React.ReactElement {
  return (
    <ApolloProvider {...props} client={client}>
      {children}
    </ApolloProvider>
  );
}

export default Provider;
