import type { MessageDescriptor } from "@lingui/core";
import { msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import React from "react";

import Button from "./Button";
import SuccessMessage from "./SuccessMessage";

export type Props = {
  message: string;
  to?: string;
  buttonLabel?: MessageDescriptor;
  shouldAutofocus?: boolean;
};

export default function AuthSuccessMessage(props: Props) {
  const { _ } = useLingui();
  const { message } = props;
  const to = props.to || "/log-in";
  const buttonLabel = props.buttonLabel || msg`Log in`;
  const shouldAutofocus =
    typeof props.shouldAutofocus === "undefined" ? true : props.shouldAutofocus;

  return (
    <>
      <div className="auth-page__title">
        <SuccessMessage shouldAutofocus={shouldAutofocus} message={message} />
      </div>
      <div className="auth-page__form page-with-actions">
        <div className="page-with-actions__actions">
          <Button to={to} color="primary">
            {_(buttonLabel)}
          </Button>
        </div>
      </div>
    </>
  );
}
