import { msg, Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";

import {
  type Submission,
  SurveyType,
} from "../graphql/__generated__/globalTypes";
import {
  formatDateInDefaultTimezone,
  formatTimeInDefaultTimezone,
  parseDatetimeInDefaultTimezone,
} from "../utils/date-time";
import Button from "./Button";
import GroupByMonth from "./GroupByMonth";
import Icon from "./Icon";
import CaretRight from "./icons/20/caret-right.svg?react";

export type Props = {
  submissions: Submission[];
  surveyType: SurveyType;
};

function SurveySubmissionListItem({
  submission,
  surveyType,
}: {
  submission: Submission;
  surveyType: SurveyType;
}) {
  const { i18n, _ } = useLingui();
  const dateObject = parseDatetimeInDefaultTimezone(submission.insertedAt);

  let formattedDate = formatDateInDefaultTimezone(i18n.locale, dateObject);
  if (formattedDate === formatDateInDefaultTimezone(i18n.locale, new Date())) {
    formattedDate = _(msg`Today`);
  }

  const URLPrefix =
    surveyType === SurveyType.Monitoring ? "monitoring" : "post-operative";

  return (
    <div className="survey-submission u-h3">
      <dl className="survey-submission__info inline-dl">
        <div className="inline-dl__item inline-dl__item--light-weight">
          <dt className="u-sr-only">
            <Trans>Date</Trans>
          </dt>
          <dd>{formattedDate}</dd>
        </div>
        <div className="inline-dl__item inline-dl__item--light-weight">
          <dt className="u-sr-only">
            <Trans>Time</Trans>
          </dt>
          <dd>{formatTimeInDefaultTimezone(dateObject)}</dd>
        </div>
      </dl>
      <Button
        color="transparent"
        shape="small"
        to={`/${URLPrefix}/${submission.id}`}
      >
        <Trans>View submission</Trans>
        <Icon svg={CaretRight} size="medium" />
      </Button>
    </div>
  );
}

export default function SurveySubmissionList(props: Props) {
  const { submissions, surveyType } = props;

  if (submissions.length === 0) {
    return (
      <p className="survey-submission-empty-list a-content a-content--m">
        <Trans>No survey submissions yet.</Trans>
      </p>
    );
  }

  return (
    <GroupByMonth
      items={submissions.map((submission) => ({
        date: submission.insertedAt.split("T")[0],
        time: submission.insertedAt.split("T")[1],
        content: (
          <SurveySubmissionListItem
            submission={submission}
            surveyType={surveyType}
          />
        ),
      }))}
      className="survey-submission-list"
      monthListProps={{ className: "survey-submission-month-list" }}
      data-testid="survey-submission-list"
    />
  );
}
