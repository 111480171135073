import { useEffect } from "react";

import { firstFactorTokenExpiredEvent } from "../utils/auth";
import useAfterLogOut from "../utils/useAfterLogOut";

export function AuthEventHandler() {
  const afterLogOut = useAfterLogOut();

  function redirectOnTokenExpired() {
    afterLogOut();
  }

  useEffect(() => {
    document.addEventListener(
      firstFactorTokenExpiredEvent,
      redirectOnTokenExpired
    );

    return () => {
      document.removeEventListener(
        firstFactorTokenExpiredEvent,
        redirectOnTokenExpired
      );
    };
  });

  return <></>;
}
