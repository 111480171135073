import type { ApolloError } from "@apollo/client";
import { Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { useEffect, useRef, useState } from "react";

import type { Step } from "../../../graphql/__generated__/globalTypes";
import Button from "../../../shared/Button";
import ButtonWithLoadingIndicator from "../../../shared/ButtonWithLoadingIndicator";
import FormError from "../../../shared/FormError";
import Icon from "../../../shared/Icon";
import CalendarBlank from "../../../shared/icons/16/calendar-blank.svg?react";
import Clock from "../../../shared/icons/16/clock.svg?react";
import {
  formatDateInDefaultTimezone,
  formatTimeInDefaultTimezone,
} from "../../../utils/date-time";

export type OnSubmitData = {
  date?: string | null;
  time?: string | null;
  appointmentDetails?: string | null;
};

export type Props = {
  step: Step;
  onSubmit: (data: OnSubmitData) => void;
  error?: ApolloError;
  loading: boolean;
  cancelPath: string;
};

export default function AppointmentDetailsEditForm(props: Props) {
  const { step, cancelPath, error, loading } = props;
  const { i18n } = useLingui();
  const dateInput = useRef<HTMLInputElement>(null);
  const timeInput = useRef<HTMLInputElement>(null);

  let initialDate = "";
  let initialTime = "";

  if (step.appointmentAt) {
    const newDate = new Date(step.appointmentAt);
    initialDate = formatDateInDefaultTimezone(
      i18n.locale,
      newDate,
      "yyyy-MM-dd"
    );
    initialTime = formatTimeInDefaultTimezone(newDate);
  }

  const [appointmentDate, setAppointmentDate] = useState<string>(initialDate);
  const [appointmentTime, setAppointmentTime] = useState<string>(initialTime);
  const [appointmentDetails, setAppointmentDetails] = useState<string>(
    step.appointmentDetails || ""
  );

  const onDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAppointmentDate(e.target.value);
  };
  const onTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAppointmentTime(e.target.value);
  };
  const onAppointmentDetailsChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setAppointmentDetails(e.target.value);
  };

  // yes, this effect is meant to run every time anything changes
  // see https://github.com/facebook/react/issues/8938
  useEffect(() => {
    if (dateInput.current) {
      dateInput.current.defaultValue = "";
    }

    if (timeInput.current) {
      timeInput.current.defaultValue = "";
    }
  });

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    props.onSubmit({
      date: appointmentDate,
      time: appointmentTime,
      appointmentDetails,
    });
  };

  return (
    <form action="#" className="page-with-actions" onSubmit={onSubmit}>
      <div className="form-item">
        <fieldset className="form-item">
          <legend className="u-sr-only">
            <Trans>Appointment details</Trans>
          </legend>
          <FormError error={error} />
          <div className="form-item form-item--2-col">
            <div className="form-item form-item--inputs">
              <label htmlFor="date-input">
                <Trans>Date</Trans>
              </label>
              <div className="input-date-time">
                <input
                  ref={dateInput}
                  id="date-input"
                  name="date"
                  type="date"
                  value={appointmentDate ? appointmentDate : ""}
                  onChange={onDateChange}
                  required={appointmentTime ? true : false}
                />
                <Icon svg={CalendarBlank} className="input-date-time__icon" />
              </div>
            </div>
            <div className="form-item form-item--inputs">
              <label htmlFor="time-input">Time</label>
              <div className="input-date-time">
                <input
                  ref={timeInput}
                  id="time-input"
                  name="time"
                  type="time"
                  value={appointmentTime ? appointmentTime : ""}
                  onChange={onTimeChange}
                  required={appointmentDate ? true : false}
                />
                <Icon svg={Clock} className="input-date-time__icon" />
              </div>
            </div>
          </div>
          <div className="form-item form-item--inputs">
            <label htmlFor="details">
              <Trans>Details</Trans>
            </label>
            <textarea
              id="details"
              name="details"
              rows={9}
              value={appointmentDetails ? appointmentDetails : ""}
              onChange={onAppointmentDetailsChange}
            ></textarea>
          </div>
        </fieldset>
      </div>
      <div className="page-with-actions__actions  page-with-actions__actions--inline@l">
        <Button to={cancelPath} color="secondary">
          <Trans>Cancel</Trans>
        </Button>
        <ButtonWithLoadingIndicator type="submit" loading={loading}>
          <Trans>Save details</Trans>
        </ButtonWithLoadingIndicator>
      </div>
    </form>
  );
}
