import type { ApolloError } from "@apollo/client";
import { Trans } from "@lingui/macro";
import React, { useState } from "react";

import Button from "../../shared/Button";
import ButtonWithLoadingIndicator from "../../shared/ButtonWithLoadingIndicator";
import FormError from "../../shared/FormError";

export type OnSubmitData = { email: string; password: string };

export type Props = {
  loading: boolean;
  error?: ApolloError;
  expectedErrorCodes?: string[];
  onSubmit: (data: OnSubmitData) => void;
};

export default function LogInForm(props: Props) {
  const { loading, expectedErrorCodes, error } = props;
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const onEmailChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setEmail(e.target.value);

  const onPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setPassword(e.target.value);

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    props.onSubmit({ email, password });
  };

  return (
    <form className="auth-page__form page-with-actions" onSubmit={onSubmit}>
      <div className="form-item">
        <FormError error={error} expectedErrorCodes={expectedErrorCodes} />
        <div className="form-item form-item--inputs">
          <label htmlFor="email">
            <Trans>Email</Trans>
          </label>
          <input
            id="email"
            type="email"
            required
            value={email}
            onChange={onEmailChange}
            autoComplete="email"
          />
        </div>

        <div className="form-item form-item--inputs">
          <label htmlFor="password">
            <Trans>Password</Trans>
          </label>
          <input
            id="password"
            type="password"
            required
            value={password}
            onChange={onPasswordChange}
            autoComplete="current-password"
          />
        </div>
      </div>

      <div className="page-with-actions__actions">
        <Button to="/password-reset/new" color="transparent">
          <Trans>Forgot your password?</Trans>
        </Button>
        <ButtonWithLoadingIndicator
          type="submit"
          loading={loading}
          data-testid="log-in-button"
        >
          <Trans>Log in</Trans>
        </ButtonWithLoadingIndicator>
      </div>
    </form>
  );
}
