import { msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import type { Phase } from "../../graphql/__generated__/globalTypes";
import ErrorMessage from "../../shared/ErrorMessage";
import Layout from "../../shared/Layout";
import { stepAppointmentStateElementId } from "../../shared/Layout/Banner";
import LoadingIndicator from "../../shared/LoadingIndicator";
import StepAppointment from "../../shared/StepAppointment";
import StepAppointmentState from "../../shared/StepAppointmentState";
import StepPrivateNotes from "../../shared/StepPrivateNotes";
import useCarePath from "../../utils/useCarePath";

export default function StepDetailsPage() {
  const { _ } = useLingui();
  const { carepath, loading, error } = useCarePath();
  const { phaseId, stepId } = useParams();
  const phases: Phase[] = (carepath?.phases as Phase[]) || [];
  const finder = (p: Phase) => p.id === phaseId;
  const phase = phases.find(finder);
  const step = phase?.steps?.find((step) => step.id === stepId);
  const navigate = useNavigate();
  const backPath = `/care-path/${phaseId}`;

  useEffect(() => {
    if (!loading && !error && !step) {
      navigate(backPath);
    }
  });

  const appointmentNotYetOccurred =
    step && step.appointmentAt && new Date(step.appointmentAt) >= new Date()
      ? true
      : false;

  return (
    <Layout
      title={_(msg`Step details`)}
      backPath={backPath}
      backLabel={_(msg`Back to phase`)}
    >
      <div className="step-details-page a-content a-content--m a-content--tight">
        {loading && (
          <span className="loading-indicator-wrapper">
            <LoadingIndicator />
          </span>
        )}
        {error && <ErrorMessage error={error} />}
        {!loading && !error && step && (
          <>
            <StepAppointment step={step} phase={phase!} />
            {step.attendanceRequired ? (
              <StepAppointmentState
                stepId={step.id}
                appointmentState={step.appointmentState}
                disabled={appointmentNotYetOccurred}
                id={stepAppointmentStateElementId}
              />
            ) : null}
            <StepPrivateNotes step={step} phase={phase!} />
          </>
        )}
      </div>
    </Layout>
  );
}
