msgid ""
msgstr ""
"POT-Creation-Date: 2024-07-05 11:02+0200\n"
"MIME-Version: 1.0\n"
"Content-Type: text/plain; charset=utf-8\n"
"Content-Transfer-Encoding: 8bit\n"
"X-Generator: @lingui/cli\n"
"Language: en\n"
"Project-Id-Version: \n"
"Report-Msgid-Bugs-To: \n"
"PO-Revision-Date: \n"
"Last-Translator: \n"
"Language-Team: \n"
"Plural-Forms: \n"

msgid "Edit notes"
msgstr "Edit notes"

msgid "1 number"
msgstr "1 number"

msgid "Early"
msgstr "Early"

msgid "This question may require your attention"
msgstr "This question may require your attention"

msgid "You can only mark appointments as attended or not when they have passed."
msgstr "You can only mark appointments as attended or not when they have passed."

msgid "New password"
msgstr "New password"

msgid "{dismissedCount, plural, one {{dismissedCount} notification dismissed} other {{dismissedCount} notifications dismissed}}"
msgstr "{dismissedCount, plural, one {{dismissedCount} notification dismissed} other {{dismissedCount} notifications dismissed}}"

msgid "Dismiss"
msgstr "Dismiss"

msgid "No"
msgstr "No"

msgid "Upcoming appointment"
msgstr "Upcoming appointment"

msgid "Edit email"
msgstr "Edit email"

msgid "Go to survey"
msgstr "Go to survey"

msgid "Imprint"
msgstr "Imprint"

msgid "Care Path"
msgstr "Care Path"

msgid "Password confirmation"
msgstr "Password confirmation"

msgid "When you have agreed a replacement, please edit the appointment above."
msgstr "When you have agreed a replacement, please edit the appointment above."

msgid "Password"
msgstr "Password"

msgid "Symptoms OP • {dateString}"
msgstr "Symptoms OP • {dateString}"

msgid "Mid"
msgstr "Mid"

msgid "Request change"
msgstr "Request change"

msgid "Request link"
msgstr "Request link"

msgid "Phase details"
msgstr "Phase details"

msgid "Back to care path"
msgstr "Back to care path"

msgid "This field is required"
msgstr "This field is required"

msgid "Attendance status"
msgstr "Attendance status"

msgid "1 special character"
msgstr "1 special character"

msgid "Log out"
msgstr "Log out"

msgid "Clinic information"
msgstr "Clinic information"

msgid "Change password"
msgstr "Change password"

msgid "Log in to IDA"
msgstr "Log in to IDA"

msgid "Private notes"
msgstr "Private notes"

msgid "{translatedDuration, plural, one {{translatedDuration} week} other {{translatedDuration} weeks}}"
msgstr "{translatedDuration, plural, one {{translatedDuration} week} other {{translatedDuration} weeks}}"

msgid "{translatedDuration, plural, one {{translatedDuration} day} other {{translatedDuration} days}}"
msgstr "{translatedDuration, plural, one {{translatedDuration} day} other {{translatedDuration} days}}"

msgid "Skip to content"
msgstr "Skip to content"

msgid "Optional"
msgstr "May be omitted, pending findings"

msgid "Symptoms OP"
msgstr "Symptoms OP"

msgid "Time"
msgstr "Time"

msgid "Attended"
msgstr "Attended"

msgid "Read about the {0} phase"
msgstr "Read about the {0} phase"

msgid "Your account has been confirmed. You can now log in."
msgstr "Your account has been confirmed. You can now log in."

msgid "Email"
msgstr "Email"

msgid "According to your last symptom survey submission, you are suffering symptoms."
msgstr "According to your last symptom survey submission, you are suffering symptoms."

msgid "Please try again later."
msgstr "Please try again later."

msgid "Current password"
msgstr "Current password"

msgid "Loading…"
msgstr "Loading…"

msgid "Symptoms • {dateString}"
msgstr "Symptoms • {dateString}"

msgid "A password reset link has been sent to {requestEmail} if an account with this email address exists."
msgstr "A password reset link has been sent to {requestEmail} if an account with this email address exists."

msgid "A carepath does not exist yet"
msgstr "A carepath does not exist yet"

msgid "See details on {0}"
msgstr "See details on {0}"

msgid "Privacy policy"
msgstr "Privacy policy"

msgid "https://dha.bihealth.org/project/ida/"
msgstr "https://dha.bihealth.org/project/ida/"

msgid "Confirm new email"
msgstr "Confirm new email"

msgid "Past submissions"
msgstr "Past submissions"

msgid "Your new email has been confirmed."
msgstr "Your new email has been confirmed."

msgid "View submission"
msgstr "View submission"

msgid "Forgot password?"
msgstr "Forgot password?"

msgid "Details"
msgstr "Details"

msgid "Welcome to IDA"
msgstr "Welcome to IDA"

msgid "Did you attend the appointment?"
msgstr "Did you attend the appointment?"

msgid "Choose your language"
msgstr "Choose your language"

msgid "An unexpected error occurred."
msgstr "An unexpected error occurred."

msgid "Start date"
msgstr "Start date"

msgid "Step details"
msgstr "Step details"

msgid "Save details"
msgstr "Save details"

msgid "Confirm your account by setting a password."
msgstr "Confirm your account by setting a password."

msgid "Read about this phase"
msgstr "Read about this phase"

msgid "What can you do? Click here."
msgstr "What can you do? Click here."

msgid "Please mark your attendance for this appointment"
msgstr "Please mark your attendance for this appointment"

msgid "New password confirmation"
msgstr "New password confirmation"

msgid "Cancel"
msgstr "Cancel"

msgid "Back to step"
msgstr "Back to step"

msgid "By setting a password and confirming your account you agree to the <0>IDA privacy policy</0>."
msgstr "By setting a password and confirming your account you agree to the <0>IDA privacy policy</0>."

msgid "In order to change your email, you must provide your current password."
msgstr "In order to change your email, you must provide your current password."

msgid "Today"
msgstr "Today"

msgid "Attendance not needed"
msgstr "Attendance not needed"

msgid "Change email"
msgstr "Change email"

msgid "These are your private notes. Only you can see them."
msgstr "These are your private notes. Only you can see them."

msgid "Your care team are responsible for creating a carepath for you."
msgstr "Your care team are responsible for creating a carepath for you."

msgid "Forgot your password?"
msgstr "Forgot your password?"

msgid "Your Care Path"
msgstr "Your Care Path"

msgid "Home"
msgstr "Home"

msgid "Notifications"
msgstr "Notifications"

msgid "Back"
msgstr "Back"

msgid "1 uppercase letter"
msgstr "1 uppercase letter"

msgid "Next appointment — attendance not needed"
msgstr "Next appointment — attendance not needed"

msgid "An error occurred while processing your request."
msgstr "An error occurred while processing your request."

msgid "Choose a new password"
msgstr "Choose a new password"

msgid "Notifications could not be loaded."
msgstr "Notifications could not be loaded."

msgid "Yes"
msgstr "Yes"

msgid "Make sure to include at least:"
msgstr "Make sure to include at least:"

msgid "Duration"
msgstr "Duration"

msgid "Submit answers"
msgstr "Submit answers"

msgid "Date"
msgstr "Date"

msgid "Something went wrong"
msgstr "Something went wrong"

msgid "Your password has been changed. You must now log in again."
msgstr "Your password has been changed. You must now log in again."

msgid "Symptoms"
msgstr "Symptoms"

msgid "Enter your symptoms for today"
msgstr "Enter your symptoms for today"

msgid "No survey submissions yet."
msgstr "No survey submissions yet."

msgid "No notes yet."
msgstr "No notes yet."

msgid "{translatedDuration, plural, one {{translatedDuration} month} other {{translatedDuration} months}}"
msgstr "{translatedDuration, plural, one {{translatedDuration} month} other {{translatedDuration} months}}"

msgid "Did not attend"
msgstr "Did not attend"

msgid "Late"
msgstr "Late"

msgid "{translatedDuration, plural, one {{translatedDuration} year} other {{translatedDuration} years}}"
msgstr "{translatedDuration, plural, one {{translatedDuration} year} other {{translatedDuration} years}}"

msgid "An email confirmation link has been sent to {requestEmail}."
msgstr "An email confirmation link has been sent to {requestEmail}."

msgid "Your password has been changed successfully. Please log in again with your new password."
msgstr "Your password has been changed successfully. Please log in again with your new password."

msgid "Appointment details"
msgstr "Appointment details"

msgid "Details of the {0} phase"
msgstr "Details of the {0} phase"

msgid "Alternatives:"
msgstr "Alternatives:"

msgid "Log in"
msgstr "Log in"

msgid "Save"
msgstr "Save"

msgid "Request a reset password link."
msgstr "Request a reset password link."

msgid "The service is temporarily unavailable."
msgstr "The service is temporarily unavailable."

msgid "Support with symptoms"
msgstr "Support with symptoms"

msgid "Active phase"
msgstr "Active phase"

msgid "Next appointment"
msgstr "Next appointment"

msgid "Back to phase"
msgstr "Back to phase"

msgid "Parallel with other treatments"
msgstr "Parallel with other treatments"

msgid "No appointment set."
msgstr "No appointment set."

msgid "Go to start screen"
msgstr "Go to start screen"

msgid "12 characters"
msgstr "12 characters"

msgid "Start time"
msgstr "Start time"

msgid "Continue"
msgstr "Continue"

msgid "Edit appointment"
msgstr "Edit appointment"

msgid "Close"
msgstr "Close"

msgid "Menu"
msgstr "Menu"
