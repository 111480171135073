import { ErrorBoundary as AppsignalErrorBoundary } from "@appsignal/react";
import type { Error } from "@appsignal/types";
import React from "react";

import instance from "./instance";

type Props = {
  fallback: (error?: Error) => React.ReactNode;
  children: React.ReactNode;
};

function ErrorBoundary(props: Props): React.ReactElement {
  return <AppsignalErrorBoundary instance={instance} {...props} />;
}

export default ErrorBoundary;
