import type { Phase, Step } from "../graphql/__generated__/globalTypes";
import { toLowerKebabCase } from "../utils/string";

export type Props = {
  step: Step;
  phase: Phase;
};

export default function StepDetailsHeader({ step, phase }: Props) {
  return (
    <header
      className="step-details-header"
      data-theme={toLowerKebabCase(phase.type)}
    >
      <div className="step-details-header__text">
        <p className="step-details-header__subtitle">{phase.displayName}</p>
        <h2 className="step-details-header__title">{step.name}</h2>
      </div>
    </header>
  );
}
