import { msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import React from "react";

import type { Phase } from "../../graphql/__generated__/globalTypes";
import CarePath from "../../shared/CarePath";
import ErrorMessage from "../../shared/ErrorMessage";
import Layout from "../../shared/Layout";
import LoadingIndicator from "../../shared/LoadingIndicator";
import useCarePath from "../../utils/useCarePath";

export default function CarePathPage() {
  const { _ } = useLingui();
  const { carepath, loading, error } = useCarePath();

  const phases: Phase[] = (carepath?.phases as Phase[]) || [];

  return (
    <Layout title={_(msg`Your Care Path`)}>
      <div className="care-path-page a-content a-content--m a-content--tight">
        {loading && (
          <span className="loading-indicator-wrapper">
            <LoadingIndicator />
          </span>
        )}
        {error && <ErrorMessage error={error} />}
        {!loading && !error && <CarePath phases={phases} />}
      </div>
    </Layout>
  );
}
