import { isApolloError as checkIsApolloError } from "@apollo/client";
import { msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import get from "lodash/get";
import React, { useEffect, useRef } from "react";

import Icon from "./Icon";
import Warning from "./icons/16/warning.svg?react";

export const retryStatusCodes: number[] = [
  408, // Request Timeout
  429, // Too Many Requests
  502, // Bad Gateway
  503, // Service Unavailable
  504, // Gateway Timeout
  507, // Insufficient Storage
];

export type Props = {
  error?: Error;
  message?: string;
  // when this component is rendered in a response to an user-initiated action,
  // autofocus on the error can be used as a way to provide feedback for SR users
  shouldAutofocus?: boolean;
};

export default function ErrorMessage(props: Props): React.ReactElement {
  const { _ } = useLingui();
  const selfRef = useRef<HTMLDivElement>(null);

  let message;

  if (props.message) {
    message = props.message;
  } else if (props.error) {
    const error = props.error;
    const isApolloError = checkIsApolloError(error);
    const isApolloNetworkError =
      isApolloError && !!get(error, ["networkError", "response"]);
    const response: Response = get(error, ["networkError", "response"]);
    const networkErrorStatus = isApolloNetworkError && response.status;

    if (
      networkErrorStatus &&
      networkErrorStatus >= 500 &&
      networkErrorStatus < 600
    ) {
      message = _(msg`The service is temporarily unavailable.`);
    } else if (
      networkErrorStatus &&
      networkErrorStatus >= 400 &&
      networkErrorStatus < 500
    ) {
      message = _(msg`An error occurred while processing your request.`);
    } else if (isApolloError) {
      message = _(msg`An error occurred while processing your request.`);
    } else {
      message = _(msg`An unexpected error occurred.`);
    }

    const hint =
      networkErrorStatus && retryStatusCodes.includes(networkErrorStatus)
        ? _(msg`Please try again later.`)
        : "";

    message = `${message} ${hint}`;
  }

  useEffect(() => {
    props.shouldAutofocus && selfRef.current?.focus();
  }, [props.shouldAutofocus]);

  return (
    <div
      className="error-message card u-h3"
      data-theme="highlight"
      tabIndex={-1}
      ref={selfRef}
    >
      <div className="error-message__icon">
        <Icon svg={Warning} size="small" />
      </div>
      <p>{message}</p>
    </div>
  );
}
