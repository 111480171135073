import {
  ErrorCode,
  useCarepathQuery,
} from "../graphql/__generated__/globalTypes";
import { findExpectedError } from "../graphql/expectedErrors";

export default function useCarePath() {
  const { data, loading, error } = useCarepathQuery();
  const noCarePathError = findExpectedError(error?.graphQLErrors, [
    ErrorCode.CarepathNotFound,
  ]);
  const hasUnexpectedError = error && !noCarePathError;

  if (hasUnexpectedError) {
    return { carepath: null, error, loading };
  } else {
    return { carepath: data?.carepath || null, error: null, loading };
  }
}
