import type { ApolloError } from "@apollo/client";
import { msg, Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import React, { useState } from "react";

import { getValidationErrorForField } from "../../graphql/validationErrors";
import Button from "../../shared/Button";
import ButtonWithLoadingIndicator from "../../shared/ButtonWithLoadingIndicator";
import FormError from "../../shared/FormError";
import InputError from "../../shared/InputError";
import { defaultRedirectPath } from "../../utils/useRedirectPathSearchParam";

export type OnSubmitData = { password: string; passwordConfirmation: string };

export type Props = {
  newAccount?: boolean;
  loading: boolean;
  error?: ApolloError;
  expectedErrorCodes?: string[];
  onSubmit: (data: OnSubmitData) => void;
};

export default function SetPasswordForm(props: Props) {
  const { _ } = useLingui();
  const { loading, expectedErrorCodes, error, newAccount } = props;
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");

  const passwordError = getValidationErrorForField(error, "password");
  const passwordConfirmationError = getValidationErrorForField(
    error,
    "password_confirmation"
  );

  const onPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const onPasswordConfirmationChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPasswordConfirmation(e.target.value);
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    props.onSubmit({ password, passwordConfirmation });
  };

  return (
    <form className="auth-page__form page-with-actions" onSubmit={onSubmit}>
      <div className="form-item">
        <FormError error={error} expectedErrorCodes={expectedErrorCodes} />
        <div className="form-item form-item--inputs">
          <label htmlFor="password">
            <Trans>New password</Trans>
          </label>
          <input
            id="password"
            type="password"
            required
            value={password}
            onChange={onPasswordChange}
            autoComplete="off"
            aria-describedby="password-hint"
            aria-invalid={!!passwordError}
            aria-errormessage={passwordError ? "password-error" : undefined}
          />
          {passwordError && (
            <InputError id="password-error">{passwordError}</InputError>
          )}
          <div id="password-hint" className="form-item__hint">
            <p>
              <Trans>Make sure to include at least:</Trans>
            </p>
            <ul className="list">
              <li>
                <Trans>12 characters</Trans>
              </li>
              <li>
                <Trans>1 special character</Trans>
              </li>
              <li>
                <Trans>1 uppercase letter</Trans>
              </li>
              <li>
                <Trans>1 number</Trans>
              </li>
            </ul>
          </div>
        </div>

        <div className="form-item form-item--inputs">
          <label htmlFor="password-confirmation">
            <Trans>Password confirmation</Trans>
          </label>
          <input
            id="password-confirmation"
            type="password"
            required
            value={passwordConfirmation}
            onChange={onPasswordConfirmationChange}
            autoComplete="off"
            aria-invalid={!!passwordConfirmationError}
            aria-errormessage={
              passwordConfirmationError
                ? "password-confirmation-error"
                : undefined
            }
          />
          {passwordConfirmationError && (
            <InputError id="password-confirmation-error">
              {passwordConfirmationError}
            </InputError>
          )}
        </div>
      </div>

      {newAccount ? (
        <p className="u-h3">
          {/* Fix in IDA-171 */}
          <Trans>
            By setting a password and confirming your account you agree to the{" "}
            <a
              href={_(msg`https://dha.bihealth.org/project/ida/`)}
              target="_blank"
              rel="noopener noreferrer"
            >
              IDA privacy policy
            </a>
            .
          </Trans>
        </p>
      ) : null}

      <div className="page-with-actions__actions">
        <Button to={defaultRedirectPath} color="transparent">
          <Trans>Cancel</Trans>
        </Button>
        <ButtonWithLoadingIndicator
          type="submit"
          loading={loading}
          data-testid="change-password-button"
        >
          <Trans>Change password</Trans>
        </ButtonWithLoadingIndicator>
      </div>
    </form>
  );
}
