import classNames from "../utils/classNames";

export type Props = {
  confirmed?: boolean;
};

const PhasePath = ({ confirmed }: Props) => {
  return (
    <svg
      viewBox="0 0 20 56"
      width="20"
      height="56"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames([
        "phase-path",
        confirmed && `phase-path--confirmed`,
      ])}
    >
      <path
        d="m10 1c4.97 0 9 4.03 9 9v36c0 4.97-4.03 9-9 9-4.97 0-9-4.03-9-9v-36c0-4.97 4.03-9 9-9z"
        className="phase-path__outer-pill"
      />
      <path
        d="m10 5.5c2.49 0 4.5 2.01 4.5 4.5v36c0 2.49-2.01 4.5-4.5 4.5-2.49 0-4.5-2.01-4.5-4.5v-36c0-2.49 2.01-4.5 4.5-4.5z"
        className="phase-path__inner-pill"
      />
    </svg>
  );
};

export default PhasePath;
