import { Trans } from "@lingui/macro";
import React, { useEffect, useRef, useState } from "react";

import Button from "../Button";
import Icon from "../Icon";
import Close from "../icons/20/close.svg?react";
import DotsThreeOutline from "../icons/20/dots-three-outline.svg?react";

export type Props = React.PropsWithChildren;

export default function Modal(props: Props) {
  const { children } = props;
  const dialogRef = useRef<HTMLDialogElement>(null);
  const [expanded, setExpanded] = useState<boolean>(false);

  useEffect(() => {
    dialogRef.current?.addEventListener("close", () => setExpanded(false));
  }, []);

  return (
    <>
      <Button
        color="tab"
        onClick={() => {
          dialogRef.current?.showModal();
          setExpanded(true);
        }}
        aria-controls="menu-modal"
        aria-expanded={expanded}
      >
        <Icon svg={DotsThreeOutline} size="medium" />
        <span className="u-sr-only">
          <Trans>Menu</Trans>
        </span>
      </Button>
      <dialog ref={dialogRef} className="modal" id="menu-modal">
        <div className="modal__content">
          {children}

          <div className="modal__close">
            <Button
              onClick={() => dialogRef.current?.close()}
              color="sidebarNav"
            >
              <Icon svg={Close} />
              <Trans>Close</Trans>
            </Button>
          </div>
        </div>
      </dialog>
    </>
  );
}
