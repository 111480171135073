msgid ""
msgstr ""
"POT-Creation-Date: 2024-07-05 11:02+0200\n"
"MIME-Version: 1.0\n"
"Content-Type: text/plain; charset=utf-8\n"
"Content-Transfer-Encoding: 8bit\n"
"X-Generator: @lingui/cli\n"
"Language: de\n"
"Project-Id-Version: \n"
"Report-Msgid-Bugs-To: \n"
"PO-Revision-Date: \n"
"Last-Translator: \n"
"Language-Team: \n"
"Plural-Forms: \n"

msgid "Edit notes"
msgstr "Notizen bearbeiten"

msgid "1 number"
msgstr "1 Nummer"

msgid "Early"
msgstr "Anfang"

msgid "This question may require your attention"
msgstr "Diese Frage könnte Ihre Aufmerksamkeit erfordern"

msgid "You can only mark appointments as attended or not when they have passed."
msgstr "Sie können Termine erst dann als teilgenommen oder nicht teilgenommen markieren, wenn sie in der Vergangenheit liegen."

msgid "New password"
msgstr "Neues Passwort"

msgid "{dismissedCount, plural, one {{dismissedCount} notification dismissed} other {{dismissedCount} notifications dismissed}}"
msgstr "{dismissedCount, plural, one {{dismissedCount} Benachrichtigung geschlossen} other {{dismissedCount} Benachrichtigungen geschlossen}}"

msgid "Dismiss"
msgstr "Schließen"

msgid "No"
msgstr "Nein"

msgid "Upcoming appointment"
msgstr "Nächster Termin"

msgid "Edit email"
msgstr "E-Mail-Adresse bearbeiten"

msgid "Go to survey"
msgstr "Zur Befragung"

msgid "Imprint"
msgstr "Impressum"

msgid "Care Path"
msgstr "Behandlungspfad"

msgid "Password confirmation"
msgstr "Passwort bestätigen"

msgid "When you have agreed a replacement, please edit the appointment above."
msgstr "Wenn Sie einen anderen Termin gefunden haben, bearbeiten Sie diesen bitte oben."

msgid "Password"
msgstr "Passwort"

msgid "Symptoms OP • {dateString}"
msgstr "Symptome OP • {dateString}"

msgid "Mid"
msgstr "Mitte"

msgid "Request change"
msgstr "Änderung anfordern"

msgid "Request link"
msgstr "Link anfordern"

msgid "Phase details"
msgstr "Phasendetails"

msgid "Back to care path"
msgstr "Zurück zum Behandlungspfad"

msgid "This field is required"
msgstr "Dies ist ein Pflichtfeld"

msgid "Attendance status"
msgstr "Teilnahmestatus"

msgid "1 special character"
msgstr "1 Sonderzeichen"

msgid "Log out"
msgstr "Ausloggen"

msgid "Clinic information"
msgstr "Informationen zur Klinik"

msgid "Change password"
msgstr "Passwort ändern"

msgid "Log in to IDA"
msgstr "Bei IDA anmelden"

msgid "Private notes"
msgstr "Private Notizen"

msgid "{translatedDuration, plural, one {{translatedDuration} week} other {{translatedDuration} weeks}}"
msgstr "{translatedDuration, plural, one {{translatedDuration} Woche} other {{translatedDuration} Wochen}}"

msgid "{translatedDuration, plural, one {{translatedDuration} day} other {{translatedDuration} days}}"
msgstr "{translatedDuration, plural, one {{translatedDuration} Tag} other {{translatedDuration} Tage}}"

msgid "Skip to content"
msgstr "Zum Inhalt springen"

msgid "Optional"
msgstr "Entfällt ggf. (Befunde ausstehend)"

msgid "Symptoms OP"
msgstr "Symptome OP"

msgid "Time"
msgstr "Uhrzeit"

msgid "Attended"
msgstr "Teilgenommen"

msgid "Read about the {0} phase"
msgstr "Lesen Sie über die {0}-Phase"

msgid "Your account has been confirmed. You can now log in."
msgstr "Ihr Konto wurde bestätigt. Sie können sich jetzt anmelden."

msgid "Email"
msgstr "E-Mail"

msgid "According to your last symptom survey submission, you are suffering symptoms."
msgstr "Laut Ihrer letzten Befragung leiden Sie unter Symptomen."

msgid "Please try again later."
msgstr "Bitte versuchen Sie es später noch einmal."

msgid "Current password"
msgstr "Bisheriges Passwort"

msgid "Loading…"
msgstr "Lädt…"

msgid "Symptoms • {dateString}"
msgstr "Symptome • {dateString}"

msgid "A password reset link has been sent to {requestEmail} if an account with this email address exists."
msgstr "Ein Link zum Zurücksetzen des Passworts wurde an {requestEmail} gesendet, sofern ein Konto mit dieser E-Mail-Adresse vorhanden ist."

msgid "A carepath does not exist yet"
msgstr "Ein Behandlungspfad ist noch nicht vorhanden"

msgid "See details on {0}"
msgstr "Details zu {0} anzeigen"

msgid "Privacy policy"
msgstr "Datenschutzrichtlinie"

msgid "https://dha.bihealth.org/project/ida/"
msgstr "https://dha.bihealth.org/project/ida/"

msgid "Confirm new email"
msgstr "Neue E-Mail-Adresse bestätigen"

msgid "Past submissions"
msgstr "Bisherige Einreichungen"

msgid "Your new email has been confirmed."
msgstr "Ihre neue E-Mail-Adresse wurde bestätigt."

msgid "View submission"
msgstr "Siehe Einreichung"

msgid "Forgot password?"
msgstr "Passwort vergessen?"

msgid "Details"
msgstr "Details"

msgid "Welcome to IDA"
msgstr "Willkommen bei IDA"

msgid "Did you attend the appointment?"
msgstr "Haben Sie an dem Termin teilgenommen?"

msgid "Choose your language"
msgstr "Wählen Sie Ihre Sprache"

msgid "An unexpected error occurred."
msgstr "Ein unerwarteter Fehler ist aufgetreten."

msgid "Start date"
msgstr "Startdatum"

msgid "Step details"
msgstr "Schrittdetails"

msgid "Save details"
msgstr "Details speichern"

msgid "Confirm your account by setting a password."
msgstr "Bestätigen Sie Ihr Konto, indem Sie ein Passwort festlegen."

msgid "Read about this phase"
msgstr "Lesen Sie über diese Phase"

msgid "What can you do? Click here."
msgstr "Was können Sie dagegen tun? Klicken Sie hier"

msgid "Please mark your attendance for this appointment"
msgstr "Bitte markieren Sie, ob Sie an diesem Termin teilgenommen haben"

msgid "New password confirmation"
msgstr "Neues Passwort Bestätigung"

msgid "Cancel"
msgstr "Abbrechen"

msgid "Back to step"
msgstr "Zurück zu Schritt"

msgid "By setting a password and confirming your account you agree to the <0>IDA privacy policy</0>."
msgstr "Indem Sie ein Passwort festlegen und Ihr Konto bestätigen, stimmen Sie der <0>Datenschutzrichtlinie von IDA</0> zu."

msgid "In order to change your email, you must provide your current password."
msgstr "Um Ihre E-Mail-Adresse zu ändern, müssen Sie Ihr bisheriges Passwort eingeben."

msgid "Today"
msgstr "Heute"

msgid "Attendance not needed"
msgstr "Teilnahme Patient:in nicht erforderlich"

msgid "Change email"
msgstr "E-Mail-Adresse ändern"

msgid "These are your private notes. Only you can see them."
msgstr "Dies sind Ihre privaten Notizen. Nur Sie können diese sehen."

msgid "Your care team are responsible for creating a carepath for you."
msgstr "Ihr Pflegeteam ist dafür verantwortlich, einen Behandlungspfad für Sie zu erstellen."

msgid "Forgot your password?"
msgstr "Passwort vergessen?"

msgid "Your Care Path"
msgstr "Ihr Behandlungspfad"

msgid "Home"
msgstr "Startseite"

msgid "Notifications"
msgstr "Benachrichtigungen"

msgid "Back"
msgstr "Zurück"

msgid "1 uppercase letter"
msgstr "1 Großbuchstabe"

msgid "Next appointment — attendance not needed"
msgstr "Nächster Termin — Teilnahme Patient:in nicht erforderlich"

msgid "An error occurred while processing your request."
msgstr "Während Ihrer Anfrage ist ein Fehler aufgetreten."

msgid "Choose a new password"
msgstr "Wählen Sie ein neues Passwort"

msgid "Notifications could not be loaded."
msgstr "Benachrichtigungen konnten nicht geladen werden."

msgid "Yes"
msgstr "Ja"

msgid "Make sure to include at least:"
msgstr "Ihr Passwort sollte bestehen aus:"

msgid "Duration"
msgstr "Dauer"

msgid "Submit answers"
msgstr "Antworten einreichen"

msgid "Date"
msgstr "Datum"

msgid "Something went wrong"
msgstr "Es ist ein Fehler aufgetreten."

msgid "Your password has been changed. You must now log in again."
msgstr "Ihr Passwort wurde geändert. Sie müssen sich nun erneut anmelden."

msgid "Symptoms"
msgstr "Symptome"

msgid "Enter your symptoms for today"
msgstr "Tragen Sie Ihre Symptome für heute ein"

msgid "No survey submissions yet."
msgstr "Noch keine Befragung eingereicht."

msgid "No notes yet."
msgstr "Noch keine Notizen."

msgid "{translatedDuration, plural, one {{translatedDuration} month} other {{translatedDuration} months}}"
msgstr "{translatedDuration, plural, one {{translatedDuration} Monat} other {{translatedDuration} Monate}}"

msgid "Did not attend"
msgstr "Nicht teilgenommen"

msgid "Late"
msgstr "Ende"

msgid "{translatedDuration, plural, one {{translatedDuration} year} other {{translatedDuration} years}}"
msgstr "{translatedDuration, plural, one {{translatedDuration} Jahr} other {{translatedDuration} Jahre}}"

msgid "An email confirmation link has been sent to {requestEmail}."
msgstr "Ein E-Mail-Bestätigungslink wurde an {requestEmail} gesendet."

msgid "Your password has been changed successfully. Please log in again with your new password."
msgstr "Ihr Passwort wurde erfolgreich geändert. Bitte loggen Sie sich nun mit Ihrem neuen Passwort ein."

msgid "Appointment details"
msgstr "Termindetails"

msgid "Details of the {0} phase"
msgstr "Details der {0}-Phase"

msgid "Alternatives:"
msgstr "Alternativen:"

msgid "Log in"
msgstr "Anmelden"

msgid "Save"
msgstr "Speichern"

msgid "Request a reset password link."
msgstr "Fordern Sie einen Link zum Zurücksetzen des Passworts an."

msgid "The service is temporarily unavailable."
msgstr "Der Dienst steht vorrübergehend nicht zur Verfügung."

msgid "Support with symptoms"
msgstr "Unterstützung bei Symptomen"

msgid "Active phase"
msgstr "Aktive Phase"

msgid "Next appointment"
msgstr "Nächster Termin"

msgid "Back to phase"
msgstr "Zurück zur Phase"

msgid "Parallel with other treatments"
msgstr "Parallel zu anderen Behandlungen"

msgid "No appointment set."
msgstr "Kein Termin vereinbart."

msgid "Go to start screen"
msgstr "Zur Startseite"

msgid "12 characters"
msgstr "12 Zeichen"

msgid "Start time"
msgstr "Startzeit"

msgid "Continue"
msgstr "Weiter"

msgid "Edit appointment"
msgstr "Termin bearbeiten"

msgid "Close"
msgstr "Schließen"

msgid "Menu"
msgstr "Menu"
