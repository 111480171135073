import type { ApolloError } from "@apollo/client";
import { Trans } from "@lingui/macro";
import React, { useState } from "react";

import { getValidationErrorForField } from "../../../graphql/validationErrors";
import Button from "../../../shared/Button";
import ButtonWithLoadingIndicator from "../../../shared/ButtonWithLoadingIndicator";
import FormError from "../../../shared/FormError";
import InputError from "../../../shared/InputError";
import { defaultRedirectPath } from "../../../utils/useRedirectPathSearchParam";

export type OnSubmitData = { email: string; password: string };

export type Props = {
  currentEmail?: string | null;
  loading: boolean;
  error?: ApolloError;
  expectedErrorCodes?: string[];
  onSubmit: (data: OnSubmitData) => void;
};

export default function EmailEditForm(props: Props) {
  const { loading, expectedErrorCodes, error, currentEmail } = props;
  const [email, setEmail] = useState(currentEmail || "");
  const [password, setPassword] = useState("");

  const emailError = getValidationErrorForField(error, "email");
  const passwordError = getValidationErrorForField(error, "current_password");

  const onEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const onPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    props.onSubmit({ email, password });
  };

  return (
    <form className="auth-page__form page-with-actions" onSubmit={onSubmit}>
      <div className="form-item">
        <FormError error={error} expectedErrorCodes={expectedErrorCodes} />
        <div className="form-item form-item--inputs">
          <label htmlFor="email">
            <Trans>Email</Trans>
          </label>
          <input
            id="email"
            type="email"
            required
            value={email}
            onChange={onEmailChange}
            autoComplete="email"
            aria-invalid={!!emailError}
            aria-errormessage={emailError ? "email-error" : undefined}
          />
          {emailError && <InputError id="email-error">{emailError}</InputError>}
        </div>

        <div className="form-item form-item--inputs">
          <label htmlFor="password">
            <Trans>Password</Trans>
          </label>
          <input
            id="password"
            type="password"
            required
            value={password}
            onChange={onPasswordChange}
            aria-describedby="password-hint"
            aria-invalid={!!passwordError}
            aria-errormessage={passwordError ? "password-error" : undefined}
          />
          {passwordError && (
            <InputError id="password-error">{passwordError}</InputError>
          )}
          <p className="form-item__hint" id="password-hint">
            <Trans>
              In order to change your email, you must provide your current
              password.
            </Trans>
          </p>
        </div>
      </div>

      <div className="page-with-actions__actions">
        <Button to={defaultRedirectPath} color="transparent">
          <Trans>Cancel</Trans>
        </Button>
        <ButtonWithLoadingIndicator
          type="submit"
          loading={loading}
          data-testid="request-password-reset-button"
        >
          <Trans>Request change</Trans>
        </ButtonWithLoadingIndicator>
      </div>
    </form>
  );
}
