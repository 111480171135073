import { msg, Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import React, { useState } from "react";

import {
  useMeQuery,
  useSendConfirmUpdatedPatientEmailAddressEmailMutation,
} from "../../graphql/__generated__/globalTypes";
import ErrorMessage from "../../shared/ErrorMessage";
import Layout from "../../shared/Layout";
import LoadingIndicator from "../../shared/LoadingIndicator";
import SuccessMessage from "../../shared/SuccessMessage";
import { defaultRedirectPath } from "../../utils/useRedirectPathSearchParam";
import type { OnSubmitData } from "./EmailEditPage/EmailEditForm";
import EmailEditForm from "./EmailEditPage/EmailEditForm";

export default function EmailEditPage() {
  const { _ } = useLingui();
  const { data, loading: meLoading, error: meError } = useMeQuery();
  const [successfullyRequested, setSuccessfullyRequested] =
    useState<boolean>(false);
  const [requestEmail, setRequestEmail] = useState<string | null>(null);
  const [
    sendConfirmUpdatedPatientEmailAddressEmail,
    { loading: sendEmailLoading, error: sendEmailError },
  ] = useSendConfirmUpdatedPatientEmailAddressEmailMutation({
    onError: console.error,
  });

  const expectedErrorCodes: string[] = [];

  const onSubmit = async ({ email, password }: OnSubmitData) => {
    const response = await sendConfirmUpdatedPatientEmailAddressEmail({
      variables: { email, currentPassword: password },
    });

    if (
      response?.data?.sendConfirmUpdatedPatientEmailAddressEmail?.status ===
      "ok"
    ) {
      setRequestEmail(email);
      setSuccessfullyRequested(true);
    }
  };

  let pageContent;

  if (successfullyRequested) {
    pageContent = (
      <SuccessMessage
        shouldAutofocus={true}
        message={_(
          msg`An email confirmation link has been sent to ${requestEmail}.`
        )}
      />
    );
  } else {
    pageContent = (
      <>
        <div className="auth-page__title">
          <h1 tabIndex={-1}>
            <Trans>Edit email</Trans>
          </h1>
        </div>

        <EmailEditForm
          currentEmail={data?.me?.email}
          error={sendEmailError}
          expectedErrorCodes={expectedErrorCodes}
          loading={sendEmailLoading}
          onSubmit={onSubmit}
        />
      </>
    );
  }

  return (
    <Layout
      title={_(msg`Edit email`)}
      hideTitle={true}
      hideNav={true}
      backPath={defaultRedirectPath}
    >
      <div className="auth-page a-content a-content--s">
        {meLoading && (
          <span className="loading-indicator-wrapper">
            <LoadingIndicator />
          </span>
        )}
        {meError && <ErrorMessage error={meError} />}
        {!meLoading && !meError && pageContent}
      </div>
    </Layout>
  );
}
