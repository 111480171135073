import { gql } from "@apollo/client";

const query = gql`
  query carepath {
    carepath {
      id
      phases {
        id
        type
        startDate
        optional
        displayName
        duration
        alternativePhases
        alternativeText
        active
        hasParallelPhases
        daysOffset
        infoUrl
        steps {
          id
          startDate
          optional
          name
          appointmentAt
          attendanceRequired
          appointmentState
          active
          alternativeSteps
          alternativeText
          daysOffset
          appointmentDetails
          privateNotes
        }
      }
    }
  }
`;
export default query;
