import { msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import {
  ErrorCode,
  useUpdatePatientEmailMutation,
} from "../../graphql/__generated__/globalTypes";
import { findExpectedError } from "../../graphql/expectedErrors";
import me from "../../graphql/queries/me";
import AuthSuccessMessage from "../../shared/AuthSuccessMessage";
import ErrorMessage from "../../shared/ErrorMessage";
import Layout from "../../shared/Layout";
import LoadingIndicator from "../../shared/LoadingIndicator";
import { defaultRedirectPath } from "../../utils/useRedirectPathSearchParam";

export default function EmailConfirmPage() {
  const { _ } = useLingui();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const token = searchParams.get("token");

  useEffect(() => {
    if (!token) {
      navigate(defaultRedirectPath);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [successfullyConfirmed, setSuccessfullyConfirmed] =
    useState<boolean>(false);
  const [updatePatientEmail, { loading, error }] =
    useUpdatePatientEmailMutation({
      onError: console.error,
      refetchQueries: [{ query: me }],
    });

  const expectedErrorCodes: string[] = [ErrorCode.TokenInvalidOrExpired];

  const onSubmit = async () => {
    const response = await updatePatientEmail({
      variables: { token: token! },
    });

    if (response?.data?.updatePatientEmail?.status === "ok") {
      setSuccessfullyConfirmed(true);
    }
  };

  // note: this will be flaky in dev because react strict mode renders the page twice,
  // using up the token and leading to errors on second run
  useEffect(() => {
    if (token) {
      onSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const expectedError =
    expectedErrorCodes &&
    findExpectedError(error?.graphQLErrors, expectedErrorCodes);

  let pageContent;

  if (successfullyConfirmed) {
    pageContent = (
      <AuthSuccessMessage
        message={_(msg`Your new email has been confirmed.`)}
        to={"/care-path"}
        buttonLabel={msg`Continue`}
        shouldAutofocus={false}
      />
    );
  }

  if (expectedError) {
    pageContent = (
      <ErrorMessage shouldAutofocus={false} message={expectedError.message} />
    );
  } else if (error) {
    pageContent = <ErrorMessage shouldAutofocus={false} error={error} />;
  }

  return (
    <Layout
      title={_(msg`Confirm new email`)}
      hideTitle={true}
      hideNav={true}
      backPath={defaultRedirectPath}
    >
      <div className="auth-page a-content a-content--s">
        {loading && (
          <span className="loading-indicator-wrapper">
            <LoadingIndicator />
          </span>
        )}
        {!loading && pageContent}
      </div>
    </Layout>
  );
}
