import type { ApolloError } from "@apollo/client";
import { Trans } from "@lingui/macro";
import React, { useState } from "react";

import { getValidationErrorForField } from "../../../graphql/validationErrors";
import Button from "../../../shared/Button";
import ButtonWithLoadingIndicator from "../../../shared/ButtonWithLoadingIndicator";
import FormError from "../../../shared/FormError";
import InputError from "../../../shared/InputError";
import { defaultRedirectPath } from "../../../utils/useRedirectPathSearchParam";

export type OnSubmitData = {
  currentPassword: string;
  password: string;
  passwordConfirmation: string;
};

export type Props = {
  loading: boolean;
  error?: ApolloError;
  expectedErrorCodes?: string[];
  onSubmit: (data: OnSubmitData) => void;
};

export default function EditPasswordForm(props: Props): React.ReactElement {
  const { loading, expectedErrorCodes, error } = props;
  const [currentPassword, setCurrentPassword] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");

  const currentPasswordError = getValidationErrorForField(
    error,
    "current_password"
  );
  const passwordError = getValidationErrorForField(error, "password");
  const passwordConfirmationError = getValidationErrorForField(
    error,
    "password_confirmation"
  );

  const onCurrentPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentPassword(e.target.value);
  };

  const onPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const onPasswordConfirmationChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPasswordConfirmation(e.target.value);
  };

  const onFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    props.onSubmit({ currentPassword, password, passwordConfirmation });
  };

  return (
    <form className="auth-page__form page-with-actions" onSubmit={onFormSubmit}>
      <div className="form-item">
        <FormError error={error} expectedErrorCodes={expectedErrorCodes} />
      </div>

      <div className="form-item form-item--inputs">
        <label htmlFor="current-password">
          <Trans>Current password</Trans>
        </label>
        <input
          id="current-password"
          type="password"
          required
          value={currentPassword}
          onChange={onCurrentPasswordChange}
          autoComplete="off"
          aria-invalid={!!currentPasswordError}
          aria-errormessage={
            currentPasswordError ? "current-password-error" : undefined
          }
        />
        {currentPasswordError && (
          <InputError id="current-password-error">
            {currentPasswordError}
          </InputError>
        )}
      </div>

      <div className="form-item form-item--inputs">
        <label htmlFor="password">
          <Trans>New password</Trans>
        </label>
        <input
          id="password"
          type="password"
          required
          value={password}
          onChange={onPasswordChange}
          autoComplete="off"
          aria-describedby="password-hint"
          aria-invalid={!!passwordError}
          aria-errormessage={passwordError ? "password-error" : undefined}
        />
        {passwordError && (
          <InputError id="password-error">{passwordError}</InputError>
        )}
        <div id="password-hint" className="form-item__hint">
          <p>
            <Trans>Make sure to include at least:</Trans>
          </p>
          <ul className="list">
            <li>
              <Trans>12 characters</Trans>
            </li>
            <li>
              <Trans>1 special character</Trans>
            </li>
            <li>
              <Trans>1 uppercase letter</Trans>
            </li>
            <li>
              <Trans>1 number</Trans>
            </li>
          </ul>
        </div>
      </div>

      <div className="form-item form-item--inputs">
        <label htmlFor="password-confirmation">
          <Trans>New password confirmation</Trans>
        </label>
        <input
          id="password-confirmation"
          type="password"
          required
          value={passwordConfirmation}
          onChange={onPasswordConfirmationChange}
          autoComplete="off"
          aria-invalid={!!passwordConfirmationError}
          aria-errormessage={
            passwordConfirmationError
              ? "password-confirmation-error"
              : undefined
          }
        />
        {passwordConfirmationError && (
          <InputError id="password-confirmation-error">
            {passwordConfirmationError}
          </InputError>
        )}
      </div>

      <div className="page-with-actions__actions">
        <Button to={defaultRedirectPath} color="transparent">
          <Trans>Cancel</Trans>
        </Button>
        <ButtonWithLoadingIndicator
          type="submit"
          loading={loading}
          data-testid="change-password-button"
        >
          <Trans>Change password</Trans>
        </ButtonWithLoadingIndicator>
      </div>
    </form>
  );
}
