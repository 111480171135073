import type { ApolloLink, NormalizedCacheObject } from "@apollo/client";
import { ApolloClient, concat, HttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { i18n } from "@lingui/core";

import APP_CONFIG from "../app.config";
import {
  firstFactorTokenExpiredEvent,
  getFirstFactorAuthToken,
} from "../utils/auth";
import { ErrorCode } from "./__generated__/globalTypes";
import type { GraphQLIdaError } from "./IdaError";

const hostUrl = APP_CONFIG.VITE_API_URL;

function compose(...links: ApolloLink[]): ApolloLink {
  return links.reduce((link, next) => concat(link, next));
}
class Client extends ApolloClient<NormalizedCacheObject> {
  constructor() {
    const http = new HttpLink({
      uri: `${hostUrl}/api/v1/graphql`,
    });

    const headers = setContext(async () => {
      const authToken = getFirstFactorAuthToken();
      const authHeader = authToken
        ? { authorization: `Bearer ${authToken}` }
        : {};
      const localeHeader = { "accept-language": i18n.locale };

      return { headers: { ...authHeader, ...localeHeader } };
    });

    const errorLink = onError((x) => {
      const { graphQLErrors } = x;
      if (graphQLErrors) {
        graphQLErrors.forEach((graphqlError) => {
          if ("code" in graphqlError) {
            const error = graphqlError as GraphQLIdaError;
            if (error.code === ErrorCode.FirstFactorTokenRequired) {
              // using a custom event here means assuming that
              // a listener was already registered before the app starts its first apollo request
              document.dispatchEvent(
                new CustomEvent(firstFactorTokenExpiredEvent)
              );
            }
          }
        });
      }
    });

    const link = compose(headers, errorLink, http);
    const cache = new InMemoryCache();

    super({
      cache,
      link,
      connectToDevTools: process.env.VITE_APOLLO_DEV_TOOLS === "dev",
    });
  }
}

export default Client;
