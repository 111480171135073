import { Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";

import type { Phase, Step } from "../graphql/__generated__/globalTypes";
import {
  formatDateInDefaultTimezone,
  formatTimeInDefaultTimezone,
} from "../utils/date-time";
import Button from "./Button";
import Icon from "./Icon";
import Clock from "./icons/16/clock.svg?react";
import NotePencil from "./icons/20/note-pencil.svg?react";
import StepDetailsHeader from "./StepDetailsHeader";

export type Props = {
  step: Step;
  phase: Phase;
};
export default function StepAppointment(props: Props) {
  const { i18n } = useLingui();
  const { step, phase } = props;
  const appointmentAtDate = step.appointmentAt
    ? new Date(step.appointmentAt)
    : null;

  return (
    <div className="card step-appointment">
      <div className="step-appointment__text">
        <div className="step-appointment__header">
          <StepDetailsHeader step={step} phase={phase} />
        </div>

        {appointmentAtDate ? (
          <>
            <dl className="inline-dl inline-dl--with-icons">
              <div className="inline-dl__item inline-dl__item--light-weight">
                <dt className="u-sr-only">
                  <Trans>Start date</Trans>
                </dt>
                <dd>
                  {formatDateInDefaultTimezone(i18n.locale, appointmentAtDate)}
                </dd>
              </div>
              <div className="inline-dl__item inline-dl__item--light-weight icon-label">
                <dt>
                  <Icon svg={Clock} size={"small"} />
                  <span className="u-sr-only">
                    <Trans>Start time</Trans>
                  </span>
                </dt>
                <dd>{formatTimeInDefaultTimezone(appointmentAtDate)}</dd>
              </div>
            </dl>
            {step.appointmentDetails ? (
              <div className="user-content">{step.appointmentDetails}</div>
            ) : null}
          </>
        ) : (
          <p className="step-appointment__no-appointment">
            <Trans>No appointment set.</Trans>
          </p>
        )}
      </div>

      <div className="step-appointment__link">
        <Button
          to={`/care-path/${phase.id}/steps/${step.id}/appointment`}
          color="secondary"
        >
          <Icon svg={NotePencil} size="medium" />
          <Trans>Edit appointment</Trans>
        </Button>
      </div>
    </div>
  );
}
