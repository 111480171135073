import Modal from "./Modal";
import Sidebar from "./Sidebar";
import Tabs from "./Tabs";

function NavBar() {
  return (
    <nav className="nav-bar" aria-label="main">
      <ul
        className="a-content a-content--m a-content--tight nav-bar__items u-list-none"
        role="list"
      >
        <li className="nav-bar__minor" role="listitem">
          <Modal>
            <Sidebar />
          </Modal>
        </li>
        <Tabs buttonColor={"tab"} />
      </ul>
    </nav>
  );
}

export default NavBar;
