import "./index.scss";
import "./i18n/setup";

import React from "react";
import ReactDOM from "react-dom/client";

import App from "./App";
import Client from "./graphql/Client";

const client = new Client();

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <App client={client} />
  </React.StrictMode>
);
