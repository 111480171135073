import { Trans } from "@lingui/macro";

import IdaChariteLogo from "../assets/ida-charite-logo.svg?react";
import Icon from "../shared/Icon";
import Warning from "../shared/icons/illustrative/warning.svg?react";

export default function ErrorPage() {
  const href =
    process.env.VITE_PUBLIC_URL === "" ? "/" : process.env.VITE_PUBLIC_URL;
  return (
    <div className="layout">
      <div className="layout__top-bar">
        <header
          role="banner"
          className="top-bar a-content a-content--full top-bar--logo-only@l"
        >
          <a className="top-bar__logo" href="/">
            <IdaChariteLogo />
            <span className="u-sr-only">Startseite</span>
          </a>
        </header>
      </div>

      <main className="layout__main">
        <div className="a-content error-page">
          <div className="big-message">
            <Icon svg={Warning} size="illustrative" />
            <h1>
              <Trans>Something went wrong</Trans>
            </h1>
          </div>

          <div className="error-page__link">
            <a href={href} className="a-button">
              <Trans>Go to start screen</Trans>
            </a>
          </div>
        </div>
      </main>
    </div>
  );
}
