import { useApolloClient } from "@apollo/client";
import { useNavigate } from "react-router-dom";

import useRedirectPathSearchParam from "./useRedirectPathSearchParam";

function useAfterLogIn() {
  const client = useApolloClient();
  const navigate = useNavigate();
  const redirectPath = useRedirectPathSearchParam();

  async function afterLogIn() {
    await client.cache.reset();
    navigate(redirectPath);
  }

  return afterLogIn;
}

export default useAfterLogIn;
